<template>
  <div v-reportView:pay="{ ext: 1 }" class="buy_dialog_box">
    <div class="dialog_box">
      <img
        v-if="buyDialogActivityType" :src="banner" class="activity_top_img"
        alt="pay img">
      <div class="buy-body">
        <LeftInfo />
        <div class="price_box">
          <!-- 用户信息 -->
          <div class="buy_user_box">
            <img
              v-if="userInfo.avatar_url" v-lazy="userInfo.avatar_url" class="user_avatar"
              alt="avatar">
            <span v-else class="user_avatar icon" />
            <span class="user_account">账户名称 ：{{ userInfo.username }}</span>
            <span>身份：{{ userInfo.privliegeName }}</span>
            <span class="reload" @click="reloadBuyDialog">刷新</span>
            <IconClose class="close_btn" @click.native="close" />
          </div>
          <!-- 购买列表 -->
          <ul class="buy_list">
            <li
              v-for="(item, index) in buyList" :key="item.id" class="buy_item"
              :class="{ active: activeIndex === index }" @click="changeIndex(index)"
            >
              <div v-if="index === active && countDownShow" class="count_down_bg">
                <div class="count_down_title">{{ countDownShow }}</div>
                <div class="count_down_time">
                  倒计时:
                  <span>{{ h }}:{{ m }}:{{ s }}</span>
                </div>
              </div>

              <!--  -->
              <span v-if="item.tips && ((buyDialogActivityType && index !== active) || !buyDialogActivityType)" class="iconFont">{{ item.tips }}</span>

              <h4 class="title">{{ item.name }}</h4>

              <h1 class="price" :class="{'price_move': item.real_price}">{{ computedPrice(item.real_price) }}元</h1>

              <h1 v-if="item.price && item.price !== item.real_price" class="line_real_price">原价{{ computedPrice(item.price) }}元</h1>

              <p v-if="item.detail" class="desc ellipsis_one">{{ item.detail }}</p>
            </li>
          </ul>
          <!-- 实际支付 -->
          <h3 class="pay">实际支付价格：{{ buyList[activeIndex] ? computedPrice(buyList[activeIndex].real_price) : '' }}元</h3>
          <div class="agreement_box">
            <p class="agreement_desc">如需发票，请在付款一个月内联系客服开取</p>
          </div>
          <!-- qrcode -->
          <div class="qrcode_box">
            <div class="qrcode_item wechat">
              <qrcode-vue
                v-show="wechatQr" class="qr" :value="wechatQr" level="H"
                :size="88"
              />
              <div class="btn">微信扫码支付</div>
            </div>
            <div class="qrcode_item zfb">
              <qrcode-vue
                v-show="aliQr" class="qr" :value="aliQr" level="H"
                :size="88"
              />
              <div class="btn">支付宝扫码支付</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import IconClose from '@/components/Icons/IconClose.vue'

import LeftInfo from './LeftInfo.vue'

import { floatMul } from '@shared/utils/base/src/number.js'
import { CacheSetUserInfo, getSource } from '@/common/cache.js'
import { ClientSetUserInfo } from '@/common/clientFunc.js'
import { reportServerId } from '@/common/business.js'
import { buyPackages } from '@/api/bussinessCode.js'
import api from '@/api'
import { report } from '@/report/index.js'

const payapp = 'loveoffice'
const payStatus = {
  success: 'paid',
  wait: 'paying',
  refunding: 'refunding',
  refund: 'refund',
  fail: 'fail'
}

export default {
  name: 'BuyDialog',

  components: { LeftInfo, IconClose, QrcodeVue },

  data () {
    return {
      buyList: [],
      active: 0,
      activePrepareId: null, // 后台统一服务，获取套餐的预支付product_id
      activeIndex: 0,
      wechatQr: '',
      aliQr: '',
      banner: '',
      miniActivityWords: '',
      h: '',
      m: '',
      s: '',
      countdownEndtime: 0,
      serviceTime: 0,
      confBanner: '', // 活动请求banner
      confPackage: '', // 活动请求套餐
      countDownShow: false, // 倒计时文案
      payResultExt: 0, // 支付上报区分
      countdownType: '', // 倒计时类型
      payShowExt: '' // 支付窗展示上报
    }
  },

  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    buyDialogActivityType () {
      return this.$store.state.buyDialogType
    },
    buyPackages () { // 支付窗活动套餐状态
      return buyPackages
    },
  },

  mounted () {
    this.initActivityType()
    this.reportShow()
    this.getCountDown() // 获取倒计时文案
    this.getPackages()
    this.initActivity()
  },

  destroyed () {
    this.clearTimer()
  },

  methods: {
    initActivityType () { // 根据活动状态调整活动参数
      switch (this.$store.state.buyDialogType) {
        case buyPackages.actDubaMember: // 毒霸会员活动
          this.confBanner = 'vipBuyBanner'
          this.confPackage = buyPackages.actDubaMember
          this.payResultExt = 1
          this.countdownType = 'vipBuyCountDown'
          this.payShowExt = 7
          break

        case buyPackages.actDubaCoin: // 毒霸积分活动
          this.confPackage = buyPackages.actDubaCoin
          this.payResultExt = 5
          this.payShowExt = 1
          break

        default: // 默认普通用户活动
          this.confBanner = 'buyBanner'
          this.confPackage = 'client'
          this.payResultExt = 3
          this.countdownType = 'buyCountDown'
          this.payShowExt = 1
          break
      }
    },

    reportShow () {
      report('pay', { actiontype: 1, ext: this.payShowExt })
    },

    getCountDown () {
      api.common.config({ data: { conf_code: this.countdownType } }).then(res => { // 查询倒计时文案
        const config = res?.data?.data?.content
        if (config) {
          this.countDownShow = config
        }
      })
    },

    getPackages (defaultPackage = '') {
      const setPackage = defaultPackage === '' ? this.confPackage : defaultPackage
      api.member.packages({ data: { chan: setPackage } }).then(res => {
        const packages = res?.data?.pri_packages || []
        if (defaultPackage === '') { // 默认套餐
          this.buyList = packages.slice(0, 3)
        } else {
          this.buyList.splice(0, 1, packages[this.active]) // 未勾选活动红包
        }
        this.startPay()
      })
    },

    startPay () {
      this.getPrepare(1) // 微信
      this.getPrepare(2) // 支付宝
    },

    getPrepare (type) {
      const source = getSource()

      // 需要根据套餐获取产品id
      api.member.prepare({ data: { biztype: 1, paytype: type, id: this.buyList[this.activeIndex].id, source } }).then(res => {
        const id = res?.data?.product_id

        if (!id) {
          this.$toast({ desc: '获取套餐信息失败，请刷新重试或联系客服' })
          return
        }

        type === 1 ? this.getQrcode(id, type) : this.getAliQrcode(id, type)
      })
    },

    getQrcode (id, type) {
      api.member.wxPay({ data: { payapp, product_id: id } }).then(res => {
        const data = res.data

        if (!data || !data.order_id || !data.wx_resp || !data.wx_resp.code_url) {
          this.$toast({ desc: '获取支付二维码失败，请刷新重试或联系客服' })
          return
        }

        this.wechatQr = data.wx_resp.code_url

        // 执行结果轮询
        this.getPayResult(data.order_id, type)
      }).catch(() => {
        this.$toast({ desc: '获取微信二维码失败，请刷新重试或联系客服' })
      })
    },
    getAliQrcode (id, type) {
      api.member.aliPay({ data: { payapp, product_id: id } }).then(res => {
        const data = res.data

        this.aliQr = data.ali_resp.code_url

        // 执行结果轮询
        this.getPayResult(data.order_id, type)
      }).catch(() => {
        this.$toast({ desc: '获取支付宝二维码失败，请刷新重试或联系客服' })
      })
    },

    payFail () {
      this.$toast({ desc: '支付失败，请刷新重试或联系客服' })
      this.clearTimer()
    },

    clearTimer () {
      clearInterval(this.Timer1)
      clearInterval(this.Timer2)
    },

    getPayResult (id, type) {
      clearInterval(this[`Timer${type}`])
      this[`Timer${type}`] = setInterval(() => {
        api.member.payQuery({ data: { payapp, order_id: id } }).then(res => {
          const data = res.data

          if (!data || !data.order || !data.order.status) {
            this.payFail()
            this.clearTimer()
            return
          }

          if (data.order.status === payStatus.fail) {
            this.payFail()
            this.clearTimer()
            return
          }

          // 其他状态暂不处理
          if (data.order.status === payStatus.success) {
            const order = data.order
            report('payresult', { order_id: id, order_time: order.pay_time, order_num: order.fee, order_package: this.buyList[this.activeIndex].name, order_paytype: type === 1 ? 'weixin' : 'alipay', ext: this.payResultExt })
            reportServerId()
            this.resetUserInfo()
          }
        })
      }, 3000)
    },

    resetUserInfo () {
      api.user.privliege().then(res => {
        const data = res.data
        if (!data.name || !data.end_date) {
          this.$toast({ desc: '支付已经成功，请刷新页面' })
        }
        const lastUserInfo = { ...this.userInfo, isVip: true, privliegeName: data.name, privliegeEndTime: data.end_date }

        this.$store.commit('setUserInfo', lastUserInfo)
        CacheSetUserInfo(lastUserInfo)
        ClientSetUserInfo(lastUserInfo)
        this.$store.dispatch('initBuyBtn') // 支付后终身会员隐藏成为会员按钮
        this.close()
      }).catch(() => {
        this.$toast({ desc: '支付已经成功，请刷新页面或者退出重新登录' })
      }).finally(() => {
        this.clearTimer()
      })
    },

    computedPrice (price) {
      return floatMul(price, 0.01)
    },

    close () {
      report('pay', { ext: 2 })
      this.$store.commit('changeBuyDialogVisible', false)
      this.clearTimer()
    },

    changeIndex (index) {
      this.activeIndex = index
      this.startPay()
    },

    reloadBuyDialog () {
      this.clearTimer()
      this.startPay()
    },

    initActivity () {
      if (this.buyDialogActivityType) {
        this.getBanner()
        this.getActivity()
        this.initCountdownTime()
      }
    },

    getBanner () {
      api.common.config({ data: { conf_code: this.confBanner } }).then(res => {
        const config = res?.data?.data?.content
        this.banner = config
      }).catch(() => {
        this.$toast({ desc: '获取banner失败，请刷新重试' })
      })
    },

    getActivity () {
      api.common.config({ data: { conf_code: 'minihuodongInfo' } }).then(res => {
        const config = res?.data?.data?.content
        if (config) {
          this.miniActivityWords = config
        }
      }).catch(() => {
        this.$toast({ desc: '获取活动信息失败，请刷新重试' })
      })
    },

    initCountdownTime () {
      api.common.getServiceTime().then(res => {
        const nowTime = res?.data?.timestamp
        if (!nowTime) {
          this.$toast({ desc: '倒计时加载失败，请刷新重试' })
          return
        }
        this.serviceTime = nowTime * 1000
        const endDate = new Date(this.serviceTime).setHours(23, 59, 59, 999)
        this.countdownEndtime = endDate
        this.countdown()
      })
    },

    countdown () {
      const now = this.serviceTime
      this.serviceTime += 1000
      const leftTime = this.countdownEndtime - now
      if (leftTime >= 0) {
        let hour = ''
        let minute = ''
        let second = ''
        const a = leftTime / 1000
        hour = Math.floor(a / 60 / 60)
        minute = Math.floor(a / 60 % 60)
        second = Math.floor(a % 60)
        this.h = hour < 10 ? '0' + hour : hour
        this.m = minute < 10 ? '0' + minute : minute
        this.s = second < 10 ? '0' + second : second
        setTimeout(this.countdown, 1000)
      } else {
        // 倒计时截止重新获取结束时间
        this.initCountdownTime()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$that_primary: #4a96e8;

.buy_dialog_box { position: fixed; top: 0; z-index: 14; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.4);
  .dialog_box { position: relative; top: 50%; left: 50%; width: 766px; height: 454px; margin-top: -227px; margin-left: -383px; border-radius: 8px; }
  .buy-body { width: inherit; height: inherit; overflow: hidden; }
  .price_box { position: absolute; top: 0; right: 0; box-sizing: border-box; width: 532px; height: 100%; padding: 14px 14px 0 20px; background-color: #fff; border-radius: 8px; }
  .buy_user_box { position: relative; height: 32px; font-size: 12px; font-weight: 500; line-height: 32px; color: #666; }
  .user_avatar { display: inline-block; width: 24px; height: 24px; margin: -2px 0 0 4px; vertical-align: middle; background-size: cover; border-radius: 50%;
    &.icon { background-image: url(~@/assets/images/icon_avatar_gray.png); }
  }
  .user_account { margin: 0 38px 0 22px; }
  .close_btn { position: absolute; top: 0; right: 0; z-index: 18; }

  .buy_list { position: relative; z-index: 18; display: flex; justify-content: space-between; padding-right: 6px; margin-top: 42px; }
  .buy_item { position: relative; box-sizing: border-box; width: 152px; height: 110px; text-align: center; cursor: pointer; border: 1px solid #c7c7c7; border-radius: 4px; box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    &:last-child { margin-right: 0; }
    &:nth-child(3) { .title {  &::before {  display: inline-block; background-image: url(~@/assets/images/icon_member_year.png); }}}
    &:nth-child(4) { margin-right: 0; .title { &::before { display: inline-block; background-image: url(~@/assets/images/icon_member_life.png); }}}

    &.active { border-color: $that_primary; border-width: 2px;
      .iconFont { right: -2px; }
      .title { color: $that_primary; }
      .price { color: $that_primary; }
    }

    .iconFont { position: absolute; top: -6px; right: -1px; height: 18px; padding: 0 4px; font-size: 12px; line-height: 18px; color: #fff; background: linear-gradient(90deg, #f46e1b, #f4be20); border-radius: 0 4px 0 4px; }
    .title { margin-top: 15px; font-size: 12px; font-weight: 400; line-height: 20px; color: #666;
      &::before { display: none; width: 20px; height: 20px; margin-right: 4px; vertical-align: top; content: ''; background-size: cover; }
    }
    .price { margin-top: 9px; font-size: 24px; font-weight: 500; line-height: 35px; color: #333; }
    .desc { padding: 0 5px; margin-top: 6px; font-size: 12px; }
  }
  .pay { margin-top: 19px; font-size: 18px; font-weight: bold; line-height: 26px; color: #fa8b2b; }
  .agreement_box { margin: 5px 0 25px 0; overflow: hidden; }
  .agreement_desc { float: left; font-size: 12px; font-weight: 400; line-height: 22px; color: #666; }
  .agreement_btn { float: right; height: 22px; margin-right: 7px; font-size: 12px; color: #fff; cursor: pointer; background-color: #4a96e8; border: none; border-radius: 3px; outline: none;
    &:hover { background-color: #6cb3ff; }
  }

  .qrcode_box { text-align: center;
    .qrcode_item { display: inline-block; }
    .wechat {  margin-right: 80px; }
    .qr { box-sizing: border-box; width: 100px; height: 100px; padding: 4px; margin-bottom: 8px; background-repeat: no-repeat; background-position: center center; background-size: 88px 88px; border: 2px solid #68c978; border-radius: 4px; }
    .btn { width: 100px; height: 28px; font-size: 12px; font-weight: 500; line-height: 28px; color: #fff; background-color: #68c978; border-radius: 4px; }
    .zfb {
      .qr { border-color: #00a0e9; }
      .btn { background-color: #00a0e9; }
    }
  }
  .activity_top_img{ position: absolute; top: -100px; left: 0px; z-index: 14; width: inherit; }
  .reload { position: absolute; z-index:18; margin-left: 30px; color: #4a96e8; cursor: pointer; }
  .activity_words{ position: absolute; margin-top: 3px; margin-left: 15px; font-size: 12px; color: red; }
  .count_down_bg{ position: absolute; top: -26px; left: -6px; width: 129px; height: 42px; font-weight: bold; color: #fff; background: url(~@/assets/images/bg_dialog_countdown.png) no-repeat;  background-size: 100%;
    .count_down_title { margin-top: 2px; font-size: 13px; }
    .count_down_time { margin-top: 2px; font-size: 12px;
      span { letter-spacing:1px; }
    }
  }
  .count_down_title_style { letter-spacing:3px; }
  .price_move { margin-top: -2px !important; }
  .line_real_price { font-size: 12px; color: #666; text-decoration: line-through; opacity: .6; }
  .duba_red_packet { position: absolute; right: 55px; font-size: 14px; font-weight: 400; color: #5B0902;
    .checkbox { width: 12px; height: 12px; background-image: none; border: 1px solid #5B0902; border-radius: 4px; opacity: 0.8; }
    .active { margin-top: -2px; background-image: url(~@/assets/images/icon_activity_checkbox_hover.png); background-size: 100%; }
  }
}
</style>
