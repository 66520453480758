<template>
  <div class="dialog_box" :class="`${ color || '' }`">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DialogBox',

  props: {
    color: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog_box { position: relative; min-width: 57px; min-height: 30px; background-color: #fff; border: 1px solid #D9D9D9; box-shadow: $boxShadow;
  &::before { position:absolute; top: -7px; right: 20px; z-index: -1; display: inline-block; width: 13px; height: 13px; content: ''; background-color: #fff; border-top: 1px solid #D9D9D9; border-left: 1px solid #D9D9D9; transform: rotate(45deg) translateX(0%); }
  &.blue { background-color: $primary_color;
    &::before { background-color: $primary_color; }
  }
}
</style>
