// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/bg_dialog_buy.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".rights_bg_box[data-v-fd008d34]{width:287px;height:100%;background:linear-gradient(198deg,#90cafc,#4589e5);border-radius:8px}.rights_bg_box .content_box[data-v-fd008d34]{box-sizing:border-box;width:100%;height:100%;padding:19px 53px 27px 23px;color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.rights_bg_box .title[data-v-fd008d34]{margin:30px 0 34px 0;font-size:24px;font-weight:700;line-height:35px}.rights_bg_box .rights_item[data-v-fd008d34]{position:relative;padding-left:20px;margin-bottom:16px;font-size:14px;font-weight:500;line-height:20px}.rights_bg_box .rights_item[data-v-fd008d34]:before{position:absolute;top:-10px;left:5px;font-size:30px;content:\".\"}", ""]);
// Exports
module.exports = exports;
