<template>
  <div>
    <HeaderContent @setting="dialogSetting = true" @about="dialogAbout = true" />

    <DialogC v-model="dialogSetting" :title="'设置'" :size="dialogSettingSize">
      <div class="setting_content">
        <div class="down_url">
          <label class="label">下载路径:</label>
          <input class="text" type="text" :value="downloadUrl">
          <ComButton class="btn" @click="openDownloadDir">打开</ComButton>
          <ComButton class="btn" @click="changeDownloadDir">更改</ComButton>
        </div>
        <div class="cache_setting">
          <label class="label">缓存大小:</label>
          <p class="cache_size">{{ cacheSize }}</p>
          <ComButton @click="cleanCache">清除缓存</ComButton>
        </div>
        <div class="fast_door">
          <div>
            <h3 class="label">右键设置</h3>
            <div class="input_container">
              <Checkbox :status="menuCloseState" @select="setMenuState" />
              <label class="label_btn">关闭右键新增快捷入口</label>
            </div>
          </div>

          <template v-if="showSetOfficeAddIn">
            <div>
              <h3 class="label">侧边栏设置</h3>
              <div class="input_container">
                <Checkbox :status="switchValue[switchKey.full]" :disable="switchValue.fullDisable" @select="setSwitchState" />
                <label class="label_btn">关闭启动Office时自动展开侧边栏</label>
              </div>

              <div class="input_container">
                <Checkbox :status="switchValue[switchKey.sidebar]" :disable="switchValue.sidebarDisable" @select="setCloseSwitchState" />
                <label class="label_btn">永久关闭侧边栏</label>
              </div>
            </div>

            <div>
              <h3 class="label">Office插件设置</h3>
              <div class="input_container">
                <Checkbox :status="switchValue[switchKey.addin]" @select="setCloseOfficeSwitchState" />
                <label class="label_btn">永久关闭办公模板Office插件</label>
              </div>
            </div>
          </template>
        </div>
      </div>
    </DialogC>

    <DialogC v-model="dialogAbout" size="mini" :title="'关于完美办公'">
      <div class="about_content">
        <p class="official"><label class="label">官方网站:</label><span class="url" @click="openWebsite(officialWebsite)">{{ officialWebsite }}</span></p>
        <p class="protocol"><ComButton @click="openWebsite(userProtocol)">用户协议</ComButton></p>
      </div>
    </DialogC>
  </div>
</template>

<script>
import HeaderContent from '@/components/Header/HeaderContent.vue'
import DialogC from '@/components/DialogC/index.vue'
import Checkbox from '@/components/Checkbox/index.vue'

import { bytesToSize } from '@shared/utils/base'
import {
  ClientGetDownloadDir, ClientOpenDownloadDir, ClientChangeDownloadDir, ClientGetCacheSize,
  ClientCleanCache, ClientGetNewMenuState, ClientSetNewMenuState, ClientOpenWebsiteUrl,
  ClientGetOfficeAddIn, ClientSetOfficeAddIn
} from '@/common/clientFunc.js'
import { CacheSetDownloadUrl, CacheGetDownloadUrl } from '@/common/cache.js'
import { switchKey } from '@/common/const.js'
import { officialWebsite, userProtocol } from '@/common/resources.js'

export default {
  name: 'Header',

  components: { HeaderContent, DialogC, Checkbox },

  data () {
    return {
      dialogSetting: false,
      downloadUrl: '',
      cacheSize: '',
      menuCloseState: false,

      dialogAbout: false,
      officialWebsite,
      userProtocol,
      showSetOfficeAddIn: true,
      switchKey,
      switchValue: {
        [switchKey.full]: false,
        fullDisable: false,
        [switchKey.sidebar]: false,
        sidebarDisable: false,
        [switchKey.addin]: false,
      },
    }
  },

  computed: {
    dialogSettingSize () {
      return this.showSetOfficeAddIn ? 'bigger' : 'middle'
    }
  },

  watch: {
    dialogSetting (newState) {
      if (newState) {
        this.getDownloadDir()
        this.getCacheSize()
        this.getNewMenuState()
        this.getSwitchState()
      }
    }
  },

  methods: {
    getDownloadDir () {
      const url = CacheGetDownloadUrl()
      if (url) {
        this.downloadUrl = url
      }
      this.getDownloadUrl()
    },

    changeFullStatus (flag) {
      this.switchValue[switchKey.full] = flag
      ClientSetOfficeAddIn(switchKey.full, flag)
    },

    changeSidebarStatus (flag) {
      this.switchValue[switchKey.sidebar] = flag
      ClientSetOfficeAddIn(switchKey.sidebar, flag)
    },

    changeAddinStatus (flag) {
      this.switchValue[switchKey.addin] = flag
      ClientSetOfficeAddIn(switchKey.addin, flag)
    },

    setMenuState (flag) {
      this.menuCloseState = flag
      ClientSetNewMenuState(flag ? 0 : 1)
    },

    setSwitchState (flag) {
      this.changeFullStatus(flag)
    },

    setCloseSwitchState (flag) {
      this.changeSidebarStatus(flag)

      if (flag) {
        // 侧边栏关闭，自动展开侧边栏关闭
        this.changeFullStatus(flag)
      }

      this.updateSwitchDisable()
    },

    setCloseOfficeSwitchState (flag) {
      this.changeAddinStatus(flag)

      if (flag) {
        this.changeFullStatus(flag)
        // office关闭，侧边栏关闭
        this.changeSidebarStatus(flag)
      }

      this.updateSwitchDisable()
    },

    updateSwitchDisable () {
      this.switchValue.fullDisable = false
      this.switchValue.sidebarDisable = false

      if (this.switchValue[switchKey.addin]) {
        // office关闭，侧边栏和自动展开侧边栏置灰
        this.switchValue.fullDisable = true
        this.switchValue.sidebarDisable = true
      } else if (this.switchValue[switchKey.sidebar]) {
        // 侧边栏关闭，自动展开侧边栏置灰
        this.switchValue.fullDisable = true
      }
    },

    getDownloadUrl () {
      ClientGetDownloadDir((res) => {
        const newUrl = res.path || ''
        this.downloadUrl = newUrl
        CacheSetDownloadUrl(newUrl)
      })
    },

    openDownloadDir () {
      ClientOpenDownloadDir()
    },

    changeDownloadDir () {
      ClientChangeDownloadDir(() => {
        this.getDownloadUrl()
      })
    },

    getCacheSize () {
      ClientGetCacheSize((res) => {
        const size = res?.size || 0
        const sizeObj = bytesToSize(size)
        this.cacheSize = sizeObj.size + sizeObj.unit
      })
    },

    cleanCache () {
      ClientCleanCache(() => {
        this.getCacheSize()
        this.$store.commit('clearDownedList')
      })
    },

    getNewMenuState () {
      ClientGetNewMenuState((res) => {
        this.menuCloseState = !res.result
      })
    },

    getSwitchState () {
      ClientGetOfficeAddIn(
        (res) => {
          if (!res) {
            this.showSetOfficeAddIn = false
            return
          }

          this.switchValue[switchKey.full] = res[switchKey.full]
          this.switchValue[switchKey.sidebar] = res[switchKey.sidebar]
          this.switchValue[switchKey.addin] = res[switchKey.addin]
          this.updateSwitchDisable()
        },
        () => {
          this.showSetOfficeAddIn = false
        }
      )
    },

    openWebsite (url) {
      ClientOpenWebsiteUrl(url) // --------------- api返回false
    }
  },
}
</script>

<style lang="scss" scoped>
.setting_content { padding: 36px 30px 0 30px;
  .down_url { display: flex; align-items: center; }
  .label { display: block; margin-right: 7px; font-size: 14px; font-weight: 400; }
  .text { box-sizing: border-box; width: 190px; height: 22px; padding: 0 5px; margin-right: 8px; border: 1px solid $primary_color; border-radius: 4px; outline: none; }
  .btn { margin-left: 8px; }

  .cache_setting { display: flex; align-items: center; margin-top: 19px; }
  .cache_size { margin-right: 20px; font-size: 14px; font-weight: 400; opacity: 0.6; }

  .fast_door { display: flex; flex-direction: column; align-items: flex-start; margin-top: 23px;
    .label { font-weight: 600; }
    .input_container { display: flex; align-items: center; margin: 5px 5px 5px 0px;
    input { margin: 0; }
    }
  }
  .label_btn { margin-left: 5px; font-size: 12px; font-weight: 400; cursor: pointer; }
}
.about_content { padding: 20px 83px 0 83px;
  .label { margin-right: 7px; font-size: 14px; font-weight: 400; }
  .official { margin-top: 10px; }
  .protocol { margin-top: 20px; text-align: center; }
  .url { color: $primary_color; cursor: pointer; }
}
</style>
