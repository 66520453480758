<template>
  <DialogC
    v-model="connectRes.connectResDialog" size="middle" class="res_dialog"
    @close="closeConnectDialog">
    <div class="connect_dialog">
      <h2 class="connect_title">账号关联{{ connectRes.isBind ? '成功' : '失败' }}</h2>
      <p v-if="connectRes.isBind && userInfo.privliegeEndTime" class="connect_text">您的会员到期时间日：<span class="text_active">{{ userInfo.privliegeEndTime }}</span></p>
      <p v-else-if="connectRes.isBind && !userInfo.privliegeEndTime" class="connect_text">您的爱办公会员<span class="text_active">已到期</span></p>
      <p v-else class="connect_text">请联系<span>客服</span>人工处理</p>
      <div>
        <ComButton
          size="big" type="hover" class="first_btn"
          @click="closeConnectDialog">关闭</ComButton>
        <ComButton size="big" @click="customerHandle">联系客服</ComButton>
      </div>
    </div>
  </DialogC>
</template>

<script>
import DialogC from '@/components/DialogC/index.vue'

import { customerUrl } from '@/common/resources.js'
import { ClientOpenWebsiteUrl } from '@/common/clientFunc.js'

export default {
  name: 'ConnectTipDialog',

  components: { DialogC },

  computed: {
    connectRes () {
      return this.$store.state.tipsDialog.connectRes
    },

    userInfo () {
      return this.$store.state.userInfo
    },
  },

  methods: {
    closeConnectDialog () {
      this.$store.commit('tipsDialog/changeConnectRes', { connectResDialog: false })
      this.$store.dispatch('getActivityWindow') // 关联用户成功后点击关闭查询是否弹出终身会员活动弹窗
    },

    customerHandle () {
      ClientOpenWebsiteUrl(customerUrl)
    },
  }
}
</script>

<style lang="scss" scoped>
.res_dialog { z-index: 15; }
.connect_dialog { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; color: #666;
    .connect_title { font-size: 18px; font-weight: bold; }
    .connect_text { margin: 14px 0 36px 0; font-size: 14px; font-weight: 400;
      .text_active { color: $primary_color; }
    }
    .first_btn { width: 82px; margin-right: 50px; }
  }
</style>
