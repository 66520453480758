<template>
  <div v-if="domRenderer" id="app">
    <router-view />
    <Login1 v-if="abTestLoginValue !== abTestLoginDefaultValue && loginDialogVisible" /> <!-- 须是if，因为有展示上报 -->
    <Login2 v-if="abTestLoginValue === abTestLoginDefaultValue && loginDialogVisible" /> <!-- 须是if，因为有展示上报 -->

    <ConnectDialog v-if="connectDialogVisible" />
    <template v-if="dialogPayAbTestType === abTestType.a">
      <BuyDialogOld v-if="buyDialogVisible" />
      <VipActivityDialog v-if="vipActivityDialogVisible" />
    </template>
    <template v-else>
      <BuyDialog v-if="buyDialogVisible" :dialog-type="payDialogType.pay" />
      <BuyDialog v-if="vipActivityDialogVisible" :dialog-type="payDialogType.activity" />
    </template>
    <!--    <VipActivityDialog v-if="vipActivityDialogVisible" />-->
    <!-- 通用活动弹框 -->
    <ActivityDialog v-if="activityDialogVisible" />

    <!-- 下载提示升级会员 -->
    <UpgradeMemberByDownTipDialog />
    <!-- 登录关联结果 -->
    <ConnectTipDialog />
    <!-- 领取三日会员 -->
    <ThreeDayMemberTipDialog />
    <!-- 领取会员提示 -->
    <ReceiveMemberTipDialog />
    <!-- 终身活动提示 -->
    <AllLifeTipDialog />
  </div>
</template>

<script>
import BuyDialogOld from '@/views/common/BuyDialog/index.vue'
import BuyDialog from '@/views/common/BuyDialogNew/index.vue'
import Login1 from '@/views/common/LoginDialog/Login1.vue'
import Login2 from '@/views/common/LoginDialog/Login2.vue'
import ConnectDialog from '@/views/common/ConnectDialog.vue'
import VipActivityDialog from '@/views/common/VipActivityDialog/index.vue'
import ActivityDialog from '@/views/common/ActivityDialog.vue'

import AllLifeTipDialog from '@/views/common/TipsDialog/AllLifeTipDialog.vue'
import ReceiveMemberTipDialog from '@/views/common/TipsDialog/ReceiveMemberTipDialog.vue'
import ThreeDayMemberTipDialog from '@/views/common/TipsDialog/ThreeDayMemberTipDialog.vue'
import ConnectTipDialog from '@/views/common/TipsDialog/ConnectTipDialog.vue'
import UpgradeMemberByDownTipDialog from '@/views/common/TipsDialog/UpgradeMemberByDownTipDialog.vue'

import { getParamsByUrl } from '@shared/utils/base/index.js'

import {
  CacheGetUserInfo, CacheSetUserInfo, setSource,
  setChannel, CacheIsNewUser, abTestLoginDefaultValue,
  CacheGetAbTestLogin, CacheGetIsShowActivity, CacheSetIsShowActivity,
  CacheGetDialogPayAbTestType, CacheSetDialogPayAbTestType
} from '@/common/cache.js'
import { redirectKey, payDialogType, abTestType } from '@/common/const.js'
import { ClientGetSvrId, ClientIsVip, ClientUserInfo, ClientGetUserInfo } from '@/common/clientFunc.js'
import { registerNotifyDownloadFinishFunc } from '@/api/clientApi.js'
import { report } from '@/report/index.js'
import { buyPackages } from '@/api/bussinessCode.js'
import api from '@/api'

const downedCode = 0
const needBindCode = 1
const activityCoin = 250 // 毒霸积分终身会员活动

export default {
  name: 'App',

  components: {
    Login1, Login2, ConnectDialog, BuyDialogOld, BuyDialog, ActivityDialog, VipActivityDialog,
    AllLifeTipDialog, ReceiveMemberTipDialog, ThreeDayMemberTipDialog, ConnectTipDialog,
    UpgradeMemberByDownTipDialog
  },

  data () {
    return {
      abTestLoginDefaultValue,
      abTestLoginValue: CacheGetAbTestLogin(),
      domRenderer: false, // 页面基于分类，所以分类得在页面渲染前获取
      dubaVip: false, // 毒霸会员
      isShowActivityDialog: false, // 是否展示活动支付弹框
      payDialogType,
      abTestType
    }
  },

  computed: {
    isShowCusEntry () {
      return this.$store.state.isShowCusEntry
    },

    loginDialogVisible () {
      return this.$store.state.loginDialogVisible
    },

    connectDialogVisible () {
      return this.$store.state.connectDialogVisible
    },

    buyDialogVisible () {
      return this.$store.state.buyDialogVisible
    },

    isLoginedIn () {
      return this.$store.getters.isLoginedIn
    },

    userInfo () {
      return this.$store.state.userInfo
    },

    vipActivityDialogVisible () {
      return this.$store.state.vipActivityDialogVisible
    },

    activityDialogVisible () { // 会员活动红包框
      return this.$store.state.activityDialogVisible
    },

    dialogPayAbTestType () { // 支付窗abTest类型
      return this.$store.state.dialogPayAbTestType
    },

    buyPackages () { // 支付窗活动套餐
      return buyPackages
    }
  },

  beforeCreate () {
    // source 上报属性优先于其他功能，得与create分割开，以免后期不小心写了优先级更高代码导致上报出错
    const query = getParamsByUrl(location.search)
    if (query.source) {
      setSource(query.source)
    }
    if (query.channel) {
      setChannel(query.channel) // 渠道功能，目前涉及：首页副标题
    }
  },

  async created () {
    await this.initUserInfo() // 新项目上线，没有新旧用户本地账号信息冲突的问题
    this.judgeNeedBind()
    this.needLogin()

    this.updateDialogPayAbTestType()

    await this.$store.dispatch('operational/getCategory')
    this.domRenderer = true

    this.initShowEntry()
    // 展示上报
    this.reportOpen()

    // 注册客户端下载结果事件
    this.registerNotifyDowned()
    // 初始化成为会员
    this.$store.dispatch('initBuyBtn') // 初始化成为会员按钮
    this.dubaVipActivity() // 活动相关流程
  },

  methods: {
    judgeNeedBind () {
      ClientGetSvrId((res) => {
        const svrId = res?.svrid

        if (!svrId) { return }

        this.needBind(svrId)
      })
    },

    needBind (svrId) {
      api.user.needBind({ data: { srvid: svrId, openid: '' } }).then(res => {
        if (res?.data?.status === needBindCode) {
          this.$store.commit('changeConnectDialogVisible', true)
          return
        }
        this.isShowActivityDialog = true // 不需要绑定用户则活动支付窗可弹
      })
    },

    async initUserInfo () {
      let userInfo
      try {
        userInfo = await ClientGetUserInfo()
      } catch (err) {
        console.error('ClientGetUserInfo:', err)
      } finally {
        if (!userInfo) {
          userInfo = CacheGetUserInfo()
        }

        if (userInfo) {
          CacheSetUserInfo(userInfo)
          this.$store.commit('setUserInfo', userInfo)
          this.$store.dispatch('getUserCollectIdList')
        }
      }
    },

    needLogin () {
      if (!this.isLoginedIn && getParamsByUrl(location.search)[redirectKey]) {
        this.$store.commit('changeLoginDialogVisible', true)
      }
    },

    reportOpen () {
      ClientIsVip((res) => {
        const dubaVip = res?.result || false

        report('open', { page: '1', loadtime: '', exy: '', isnew: CacheIsNewUser() ? '1' : '2', isdbvip: dubaVip ? '1' : '2', strategyid: this.isShowCusEntry ? '3' : '4', ver: '' })
      })
    },

    registerNotifyDowned () {
      registerNotifyDownloadFinishFunc(
        (res) => {
          const id = res?.data?.id || ''
          if (res.code !== downedCode || !res?.data?.result) {
            this.$toast({ desc: '下载失败，请重试！' })
            report('download', { goodsid: '', goodstype: '', free: '', classid: '', ext: '4' })
            this.$store.commit('delDowningListById', id)
            return
          }
          // 成功结果： {id: "1296", result: true}
          this.$store.commit('pushDownedList', id)
        }
      )
        .then(res => {
          // console.info('下载结果注册事件成功：', res)
        }).catch(err => {
          this.$toast({ desc: err })
        })
    },

    dubaVipActivity () { // 毒霸会员活动
      api.common.config({ data: { conf_code: 'miniDubaVipActivity' } }).then(res => { // 查询活动是否开启
        const config = res?.data?.data?.content
        if (Number(config)) {
          this.isDubaVip()
          return
        }
        this.dubaVipPointsActivity()
      })
    },

    isDubaVip () { // 是否是毒霸会员
      ClientIsVip((res) => {
        const dubaVip = res?.result || false
        if (dubaVip) {
          this.$store.commit('changeBuyDialogType', buyPackages.actDubaMember)
          this.showRedPacket()
          return
        }
        this.dubaVipPointsActivity()
      })
    },

    dubaVipPointsActivity () { // 毒霸会员积分活动
      /** 屏蔽毒霸积分入口 */
      // api.common.config({ data: { conf_code: 'miniDubaPointsActivity' } }).then(res => { // 查询活动是否开启
      //   const config = res?.data?.data?.content
      //   if (Number(config)) {
      //     this.getDubaUserInfo()
      //     return
      //   }
      this.vipActivity()
      // })
    },

    getDubaUserInfo () { // 毒霸openid
      ClientUserInfo((res) => {
        const data = res?.data || false
        if (data) {
          const openid = JSON.parse(data).open_id
          this.getDubaCoin(openid)
          return
        }
        this.vipActivity()
      })
    },

    getDubaCoin (openid) { // 查询毒霸积分
      api.duba.coin({ data: { openid: openid } }).then(res => {
        const coin = res?.data?.coin
        if (coin >= activityCoin) {
          this.dubaCoin = coin
          this.$store.commit('changeBuyDialogType', buyPackages.actDubaCoin)
          this.showRedPacket()
          return
        }
        this.vipActivity()
      })
    },

    vipActivity () { // 普通终身VIP活动
      api.common.config({ data: { conf_code: 'viphd' } }).then(res => { // 查询活动是否开启
        const config = res?.data?.data?.content
        if (Number(config)) {
          this.$store.commit('changeBuyDialogType', buyPackages.open)
          this.showRedPacket()
        }
      })
    },

    showActivityDialog () { // 展示活动支付框
      const searchObj = getParamsByUrl(location.search) // url是否带activity参数
      const buyDialogType = this.$store.state.buyDialogType // 活动状态
      if (!searchObj.activity && buyDialogType && this.isShowActivityDialog && this.isLoginedIn) { // 登录且处于活动可弹的状态下
        this.$store.dispatch('getActivityWindow') // 红包弹出逻辑
      }
    },

    showRedPacket () { // 展示活动红包框
      this.$store.commit('changeActivityNeedShow', false) // 还原点击标记
      const isShowActivity = CacheGetIsShowActivity() // 查看之前是否弹出过红包框
      const userDate = this.$store.getters.initUserDate // 格式化用户到期时间
      const searchObj = getParamsByUrl(location.search) // url是否带activity参数
      const buyDialogType = this.$store.state.buyDialogType // 活动状态
      if (!searchObj.activity && buyDialogType && !isShowActivity && userDate < this.$store.getters.lifeTime) { // 三日内未弹出过且不为终身会员则弹出
        this.$store.commit('changeActivityDialogVisible', true)
        CacheSetIsShowActivity(true)
      }
    },

    initShowEntry () {
      // ppt定制页收益不合符预期，产品要求将ppt定制页显示概率调整为0
      const isShow = Math.ceil(Math.random() * 10) < 0
      this.$store.commit('changeShowCusEntry', isShow)
    },

    updateDialogPayAbTestType () {
      const cache = CacheGetDialogPayAbTestType()
      if (cache) {
        this.$store.commit('changeDialogPayAbTestType', cache.type)
      } else {
        this.setDialogPayAbTestType()
      }
    },

    setDialogPayAbTestType () {
      const isLessThanHalf = Math.ceil(Math.random() * 10) <= 5
      const type = isLessThanHalf ? abTestType.a : abTestType.b
      CacheSetDialogPayAbTestType(type)
      this.$store.commit('changeDialogPayAbTestType', type)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/global.scss';
#app { box-sizing: border-box; width: 100vw; height: 100vh; font-family: 思源黑体, 思源宋体, sans-serif; color: $color_4; -moz-user-select: none; -webkit-user-select: none; user-select: none; background-color: $bg;}
</style>
