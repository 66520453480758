<template>
  <iframe
    :src="url" title="二维码" :scrolling="scrolling"
    :width="`${width}px`" :height="`${height}px`" />
</template>

<script>
import { ClientHideCover } from '@/common/clientFunc.js'

export default {
  name: 'QrIframe',

  props: {
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 469
    },
    url: {
      type: String,
      require: true,
      default: ''
    },
    scrolling: {
      type: String,
      default: 'yes'
    }
  },

  mounted () {
    setTimeout(() => {
      ClientHideCover() // 客户端onLoad事件过久，使用timeout处理
    }, 500)
  }
}
</script>
