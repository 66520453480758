<template>
  <TransitionAm>
    <div v-show="visible" class="common_toast">
      <div class="toast_dialog">
        <div class="icon_box"><IconClose :size="16" @click.native="close" /></div>
        <p v-show="desc" class="content">{{ desc }}</p>
      </div>
    </div>
  </TransitionAm>
</template>

<script>
import TransitionAm from '@/components/TransitionAm/index.vue'
import IconClose from '@/components/Icons/IconClose.vue'

export default {
  name: 'ToastCom',

  components: { TransitionAm, IconClose },

  data () {
    return {
      visible: false,
      desc: '',
    }
  },

  methods: {
    close () {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.common_toast { position: fixed; top: 0; z-index: 99; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);
  .toast_dialog { position: absolute; top: 20%; left: 50%; width: 300px; min-height: 70px; padding: 11px; background-color: #fff; border-radius: 8px; transform: translateX(-150px); }
  .icon_box { text-align: right; }
  .content { width: 240px; margin: 0 auto 20px auto; font-size: 14px; font-weight: bold; line-height: 24px; color: #333; text-align: center; }
}
</style>
