<template>
  <div class="buy_dialog">
    <img :src="banner" alt="" class="vip_activity_top_img">
    <div class="buy_dialog_left">
      <UserInfo />
      <Privilege v-if="packageActive" :name="packageActive.name" />
    </div>
    <div class="buy_dialog_right">
      <div v-if="dialogType === payDialogType.activity" class="flex-space">
        <PriceBox
          v-if="packageActive"
          :name="packageActive.name" :price="packageActive.priceOriginal"
          :discountPrice="packageActive.priceAfterDiscount" :tipMiddle="packageActive.tipMiddle"
          :tipsFooter="packageActive.tipsFooter" :isSelected="true"
        />
        <Tips />
      </div>
      <div v-else class="flex-space">
        <PriceBox
          v-for="(item, index) in packageList" :key="index"
          :name="item.name" :price="item.priceOriginal"
          :discountPrice="item.priceAfterDiscount" :tipMiddle="item.tipMiddle"
          :tipsFooter="item.tipsFooter" size="size_small" :isSelected="packageIndexActive === index"
          class="pointer" @click.native="clickPriceBox(index)"
        />
      </div>
      <DiscountLine
        v-if="packageActive" :detail="discountLineDetail(packageActive)" :canOpen="false"
        :class="{ noHasCoupon: !hasCoupon }" />
      <DiscountLine v-if="canGetCoupon && hasCoupon" :detail="discountLineCouponDetail" :detailListOpened="discountLineCouponOpened" />
      <PayBox
        v-if="packageActive"
        :price="packageActive.priceReal" :priceMinus="packageActive.priceMinus" :hasCoupon="hasCoupon" :canGetCoupon="canGetCoupon"
        :packageId="packageActive.id" :class="{ mg: packageIndexActive !== 0 || !hasCoupon }" :packageName="packageActive.name"
        :payResultExt="payResultExt" :extReport="extReport"
        @reload="loadPackages" @close="close" />
      <IconClose class="close" @click.native="clickClose" />
    </div>
    <PayStay
      v-if="showPayStay" class="pay_stay"
      @click-close="clickPayStayClose" @click-use="clickPayStayUse" />
  </div>
</template>

<script>
import UserInfo from './UserInfo'
import Privilege from './Privilege'
import PriceBox from './PriceBox'
import Tips from './Tips'
import DiscountLine from './DiscountLine'
import PayBox from './PayBox/index'
import PayStay from './PayStay'

import api from '@/api'
import { ClientIsVip } from '@/common/clientFunc'
import { payDialogType } from '@/common/const.js'
import IconClose from '@/components/Icons/IconClose.vue'
import { buyPackages } from '@/api/bussinessCode'
import { report } from '@/report'

const chanType = {
  withCoupon: 'summer_activity',
  noCoupon: 'normal'
}

const couponPriceList = [5.5, 3.3]

export default {
  name: 'BuyDialogNew',

  components: { UserInfo, Privilege, PriceBox, Tips, DiscountLine, PayBox, IconClose, PayStay },

  props: {
    dialogType: {
      type: Number,

      default: payDialogType.pay
    },
  },

  data () {
    return {
      isDuBaVip: false,
      showPayStay: false,
      packageList: [],
      payDialogType,
      packageIndexActive: 0,
      banner: '',
      extReport: ''
    }
  },

  computed: {
    discountLineCouponOpened () {
      return [
        {
          title: '专属优惠劵：',
          text: `-￥${couponPriceList[0]}`
        },
        {
          title: '专属优惠劵：',
          text: `-￥${couponPriceList[1]}`
        }
      ]
    },
    discountLineCouponDetail () {
      return {
        title: '优惠劵合计抵扣：',
        text: `-￥${couponPriceList[0] + couponPriceList[1]}`,
        validate: ''
      }
    },
    packageActive () {
      return this.packageList[this.packageIndexActive]
    },
    isPackageForever () {
      return this.packageIndexActive === 0
    },
    canGetCoupon () {
      return this.isPackageForever
    },
    hasCoupon () {
      return this.$store.state.hasCoupon
    }
  },

  created () {
    this.updateDubaVip(() => {
      this.extReport = this.getReportExt()
      this.reportDialogShow()
      this.loadPackages()
    })
    this.initBanner()
    this.getBanner()
  },

  methods: {
    /** 点击-关闭弹窗 **/
    clickClose () {
      this.reportClickBuyClose()
      if (this.hasCoupon) {
        this.close()
      } else {
        this.showPayStay = true
      }
    },

    /** 点击-切换套餐 **/
    clickPriceBox (index) {
      this.packageIndexActive = index
    },

    /** 点击-关闭挽留窗 **/
    clickPayStayClose () {
      this.reportPayStayClose()
      this.closePayStay()
      this.close()
    },

    /** 点击-点击挽留窗立即使用，触发挽留功能 **/
    clickPayStayUse () {
      this.reportPayStayUse()
      this.closePayStay()
    },

    closePayStay () {
      this.showPayStay = false
    },

    initBanner () { // 根据活动状态调整活动参数
      switch (this.$store.state.buyDialogType) {
        case buyPackages.actDubaMember: // 毒霸会员活动
          this.confBanner = 'vipActivityBuyBanner'
          this.confPackage = buyPackages.actDubaMember
          this.closePayExt = 6
          this.payResultExt = 2
          this.openState = 'vipActivityBuyState'
          break

        case buyPackages.actDubaCoin: // 毒霸积分活动
          this.confBanner = 'miniDubaPointsBanner'
          this.confPackage = buyPackages.actDubaCoin
          this.closePayExt = 10
          this.payResultExt = 6
          break

        default: // 普通用户活动
          this.confBanner = 'activityBuyBanner'
          this.confPackage = 'client'
          this.closePayExt = 8
          this.payResultExt = 4
          this.openState = 'activityBuyState'
          break
      }
    },

    getBanner () {
      api.common.config({ data: { conf_code: this.confBanner } }).then(res => {
        const config = res?.data?.data?.content
        if (config) {
          this.banner = config
        }
      }).catch(() => {
        this.$toast({ desc: '获取banner失败，请刷新重试' })
      })
    },

    close () {
      this.$store.commit('changeBuyDialogVisible', false)
      this.$store.commit('changeVipActivityDialogVisible', false)
    },

    reportClickBuyClose () {
      // actiontype-2: 点击，clickid-2: 关闭支付页/活动页
      report('pay', { actiontype: 2, clickid: 2, ext: this.extReport })
    },

    reportPayStayClose () {
      // actiontype-2: 点击，clickid-4: 关闭支付挽留窗
      report('pay', { actiontype: 2, clickid: 4, ext: this.extReport })
    },

    reportPayStayUse () {
      // actiontype-2: 点击，clickid-3: 点击支付挽留窗立即使用
      report('pay', { actiontype: 2, clickid: 3, ext: this.extReport })
    },

    reportDialogShow () {
      // actiontype-1 表示展示支付或者活动弹窗
      report('pay', { actiontype: 1, ext: this.extReport })
    },

    updateDubaVip (callback) { // 是否是毒霸会员
      ClientIsVip((res) => {
        this.isDuBaVip = !!res?.result
        callback()
      })
    },

    async loadPackages () {
      // 每次加载前需要更新优惠券状态
      await this.$store.dispatch('updateCouponState')

      // 根据优惠劵状态进行套餐chan选择
      let chan
      if (this.hasCoupon) {
        chan = chanType.withCoupon
      } else {
        chan = chanType.noCoupon
      }

      this.getPackages(chan).then(packages => {
        // 特殊处理-过滤套餐：后端约定，数组第一位为毒霸会员-终身会员套餐，第二位为非毒霸会员-终身会员套餐
        if (this.isDuBaVip) {
          packages.splice(1, 1)
        } else {
          packages.splice(0, 1)
        }

        const packagesClean = packages.map(item => this.clean(item))
        this.packageList = packagesClean
      })
    },

    clean (packageItem) {
      const item = {
        id: packageItem.id,
        name: packageItem.name,
        priceOriginal: this.toPriceYuan(packageItem.price),
        priceDiscount: this.toPriceYuan(packageItem.discount_price), // 折扣减去的价格
        priceReal: this.toPriceYuan(packageItem.real_price),
        tipMiddle: packageItem.detail,
        tipsFooter: packageItem.tips.split(',')
      }
      return this.setNewData(item)
    },

    setNewData (origin) {
      // 设置新数据
      const dataNew = {
        priceAfterDiscount: this.calcPriceAfterDiscount(origin),
      }
      if (this.hasCoupon && origin.name === '终身VIP') {
        dataNew.priceCouponMinus = couponPriceList[0] + couponPriceList[1]
      } else {
        dataNew.priceCouponMinus = 0
      }
      dataNew.priceMinus = (dataNew.priceCouponMinus + origin.priceDiscount).toFixed(2)

      // 复制
      const o = Object.assign({}, origin, dataNew)

      // 折率记录需要dataNew和origin数据，单独计算
      o.priceDiscountPercentage = this.calcPriceDiscountPercentage(o)

      return o
    },

    calcPriceAfterDiscount (item) {
      let s = (item.priceOriginal - item.priceDiscount).toFixed(1)
      if (s.endsWith('.0')) {
        s = s.slice(0, -2)
      }
      return s
    },

    calcPriceDiscountPercentage (item) {
      const percentage = item.priceAfterDiscount / item.priceOriginal * 10
      // 保留1位小数，四舍五入
      return Math.round(percentage * 10) / 10
    },

    // 处理为单位元
    toPriceYuan (priceMinute) {
      return priceMinute / 100
    },

    getPackages (chan) {
      return api.member.packages({ data: { chan } }).then(item => item.data.pri_packages)
    },

    getServiceTime () {
      return api.common.getServiceTime().then(res => res?.data?.timestamp * 1000)
    },

    discountLineDetail (packageClean) {
      return {
        title: '折扣优惠：',
        text: `-￥${packageClean.priceDiscount}（${packageClean.priceDiscountPercentage}折）`,
        validate: packageClean.priceDiscountExpire
      }
    },

    getReportExt () {
      // 新版毒霸会员普通支付页
      if (this.isDuBaVip) {
        // 15-新版毒霸会员普通支付页, 16-新版毒霸会员活动支付页
        return this.dialogType === payDialogType.pay ? 15 : 16
      }
      // 11-新版普通会员普通支付页, 12-新版普通会员活动支付页
      return this.dialogType === payDialogType.pay ? 11 : 12
    }
  },
}
</script>

<style lang="scss" scoped>
  .buy_dialog {
    position: fixed;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 15;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 766px;
    height: 454px;
    padding: 24px 16px;
    margin: -200px auto 0 auto;
    background: #F5F6FA;
    border-radius: 8px;
    .buy_dialog_left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .buy_dialog_right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 536px;
    }
    .pay_stay {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .buy_dialog_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mg {
    margin-bottom: 40px;
  }
  .flex-space {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .noHasCoupon {
    margin-top: 17px;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .vip_activity_top_img {
    position: absolute;
    top: -108px;
    left: -8px;
    z-index: -1;
  }
</style>
