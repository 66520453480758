<template>
  <ul class="aside_component">
    <li
      v-for="(item, index) in asideArr" v-show="isShowAsideItem(item)"
      :key="item.id" v-report:mainclick="{ area: 'leftbar', pos: index + '', ext: '' }" class="aside_item_box" :class="{ active: index === categoryActive }"
      @click="changeCategoryActive(index, item)">
      <div class="aside_item" :class="`icon_left_${index}`" />
    </li>
  </ul>
</template>

<script>
import { ClientOpenWebsiteUrl } from '@/common/clientFunc.js'

const IdCustomer = 'customer' // "客服"入口
const IdMy = 'my' // "我的"入口

const typeOpenRouter = 'openRouter'
const typeOpenBrowser = 'openBrowser'

export default {
  name: 'Aside',

  data () {
    return {
      active: 0
    }
  },

  computed: {
    asideArr () {
      const extend = [
        { id: IdCustomer, type: typeOpenBrowser, isNeedLogin: false, href: 'https://wpa1.qq.com/HizsmTzR?_type=wpa&qidian=true' },
        { id: IdMy, type: typeOpenRouter, isNeedLogin: true, href: '/my' }
      ]
      const categoryArr = this.$store.state.operational.categoryArr
      return [...categoryArr, ...extend]
    },

    categoryActive () {
      return this.$store.state.categoryActive
    },

    isLoginedIn () {
      return this.$store.getters.isLoginedIn
    }
  },

  methods: {
    changeCategoryActive (index, item) {
      if (index === this.categoryActive && this.$route.path === '/classify') { return }

      if (item.isNeedLogin && !this.isLoginedIn) {
        this.$store.commit('changeLoginDialogVisible', true)
        return
      }

      if (item.type === typeOpenRouter) {
        this.$store.commit('changeCategoryActive', index)
        this.$router.push(item.href)
        return
      }

      if (item.type === typeOpenBrowser) {
        ClientOpenWebsiteUrl(item.href)
        return
      }

      this.$store.commit('changeCategoryActive', index)
      this.$router.push(`/classify?cat1=${item.id}`) // cat1无作用，跳转同一路由报错
    },

    isShowAsideItem (item) {
      // 登录了才显示客服入口
      if (item.id === IdCustomer && !this.isLoginedIn) {
        return false
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.aside_component { text-align: center; background: #fff;
  .aside_item_box { cursor: pointer;
    &:hover { .aside_item { background-position: center center; }}
    &.active { .aside_item { background-position: right center; }}
  }
  .aside_item { display: inline-block; width: 48px; height: 54px; margin: 14px 0; background-repeat: no-repeat; background-position: left center; background-size: auto 100%;
    &.icon_left_0 { background-image: url(~@/assets/images/icon_left_0.png); }
    &.icon_left_1 { background-image: url(~@/assets/images/icon_left_1.png); }
    &.icon_left_2 { background-image: url(~@/assets/images/icon_left_2.png); }
    &.icon_left_3 { background-image: url(~@/assets/images/icon_left_3.png); }
    &.icon_left_4 { background-image: url(~@/assets/images/icon_left_4.png); }
    &.icon_left_5 { background-image: url(~@/assets/images/icon_left_5.png); }
    &.icon_left_6 { background-image: url(~@/assets/images/icon_left_6.png); }
  }
}
</style>
