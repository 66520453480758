<template>
  <div class="classify_box">
    <!-- 顶部分类 -->
    <div class="classify_header">
      <ul class="classify_level">
        <li
          v-for="(item, index) in level2List" :key="item.key" class="level_item"
          :class="{ active: level2Index === index }" @click="clickLevel2(index, item.name)">{{ item.name }}</li>
      </ul>
      <ul v-show="level2Index !== 0" class="classify_level2">
        <li
          v-for="(item, index) in level3List" :key="item.key" class="level2_item"
          :class="{ active: level3Index === index }" @click="clickLevel3(index, item.name)">{{ item.name }}</li>
      </ul>
    </div>
    <!-- 内容 -->
    <div class="classify_content" :class="{ 'level2': level2Index !== 0 }">
      <vueScroll v-show="lastMatList.length" ref="vs" @handle-scroll="handleScroll">
        <div>
          <template v-for="(item, index) in lastMatList">
            <CustomizationEntry
              v-if="item.isCustomizationEntry" :key="index"
              v-report:mainclick="{ area: 'customize' }"
              url="/customization?from=classify" class="model_item" />
            <ModelItem
              v-else :key="index" ref="modelItem" from="classify"
              :listId="listId" class="model_item" :tabid="level1Index" :item="modelItem(item)" />
          </template>
        </div>
      </vueScroll>
    </div>
    <!-- 分类 -->
    <div class="bottom_pagination">
      <Pagination :total="matTotalCount" :defaultActive="matListParams[Page]" @selectIndex="selectIndex" />
    </div>
  </div>
</template>

<script>
import ModelItem from '@/components/ModelItem/index.vue'
import CustomizationEntry from '@/components/CustomizationEntry/index.vue'
import Pagination from '@/components/Pagination/index.vue'

import {
  scrollRateFast, isCollect, modelStatus, Cat1, Cat2, Cat3, Sort,
  Page, MatId, pageSize, Scroll, modelItemHeight
} from '@/common/const.js'
import { strictExtend } from '@shared/utils/base/index.js'

import { report } from '@/report/index.js'

const detail = 'detail'
const search = 'search'
const customization = 'customization'
const fromArr = [detail, search, customization]

const level2DefaultId = 0
const level3DefaultId = 0
const level2DefaultIndex = 0
const level3DefaultIndex = 0
const scrollDefault = 0
const pageDefault = 1
/** ver: 新排序接口，排序规则2 */
const params = { [Cat1]: 0, [Cat2]: level2DefaultId, [Cat3]: level3DefaultId, [Sort]: 1, [Page]: pageDefault, size: pageSize, ver: '2' }

export default {
  name: 'Classify',

  components: { ModelItem, CustomizationEntry, Pagination },

  data () {
    return {
      MatId,
      Page,
      matListParams: strictExtend(params)
    }
  },

  computed: {
    isShowCusEntry () {
      return this.$store.state.isShowCusEntry
    },

    classify () {
      return this.$store.state.classify
    },

    page () {
      return this.classify.page
    },

    level1Index () {
      return this.$store.state.categoryActive
    },

    level2Index () {
      return this.classify.level2Index
    },

    level3Index () {
      return this.classify.level3Index
    },

    level1List () {
      return this.$store.state.operational.categoryArr
    },

    level2List () {
      return this.level1List.length && this.level1Index < this.level1List.length ? [{ name: '全部', id: level2DefaultId }, ...this.level1List[this.level1Index].list || []] : []
    },

    level3List () {
      return this.level2List.length ? [{ name: '全部', id: level3DefaultId }, ...this.level2List[this.level2Index].list || []] : []
    },

    downedList () {
      return this.$store.state.downedList
    },

    downingList () {
      return this.$store.state.downingList
    },

    collectIdList () {
      return this.$store.state.collectIdList
    },

    matList () {
      return this.$store.state.matList
    },

    matTotalCount () {
      return this.$store.state.matTotalCount
    },

    lastMatList () {
      return this.matList.map(item => {
        const id = item.id + ''

        let type = this.downedList.includes(id) ? modelStatus.downed : modelStatus.down
        type = this.downingList.includes(id) ? modelStatus.downing : type

        const collectActive = this.collectIdList.includes(item.id)
        return {
          ...item,
          type,
          [isCollect]: collectActive
        }
      })
    },

    listId () {
      return this.matListParams[Cat3] || this.matListParams[Cat2] || this.matListParams[Cat1]
    }
  },

  beforeRouteUpdate (to, from, next) { // 切换一级分类时更新，注意分类页不要有$router.push操作
    if (this.level1Index < this.level1List.length) { // 最后一个是我的，手动插入
      this.matListParams[Cat1] = this.level1List[this.level1Index].id
      this.matListParams[Cat2] = level2DefaultId
      this.matListParams[Cat3] = level3DefaultId
      this.matListParams[Page] = pageDefault

      this.$store.commit('changeClassify', { level2Index: level2DefaultIndex, level3Index: level3DefaultIndex, [Page]: pageDefault, [Scroll]: scrollDefault })
      this.$refs.vs.scrollTo({ y: 0 }, 0)

      this.loadMatList()
    }
    next()
  },

  created () {
    const from = this.$route.query.from
    if (fromArr.includes(from)) { // 详情页返回
      this.initState()
    } else { // url直接进入项目
      this.initParamAndActive()
    }
  },

  methods: {
    initParamAndActive () {
      const query = this.$route.query

      // 同步选中, cat1不能为0
      const level1Index = this.getIndexByKeyList(this.level1List, query[Cat1])
      this.$store.commit('changeCategoryActive', level1Index)

      this.$nextTick(() => { // 根据一级目录展示的二级目录，所以，得nextTick
        const level2Index = this.getIndexByKeyList(this.level2List, query[Cat2])
        const level3Index = this.getIndexByKeyList(this.level3List, query[Cat3])

        this.$store.commit('changeClassify', { level2Index, level3Index, [Page]: pageDefault })
        this.setParamsCat()

        // 同步，初始化接口参数
        this.loadMatList()
      })
    },

    initState () {
      this.matListParams[Page] = this.classify[Page]
      this.setParamsCat()

      this.$nextTick(() => {
        this.$refs.vs.scrollTo({ y: this.classify[Scroll] }, scrollRateFast)
      })
      this.loadMatList()
    },

    setParamsCat () {
      this.matListParams[Cat1] = this.level1List[this.level1Index].id
      this.matListParams[Cat2] = this.level2List[this.level2Index].id
      this.matListParams[Cat3] = this.level3List[this.level3Index].id
    },

    getIndexByKeyList (list, id) {
      let activeIndex = 0

      if (!id) {
        return activeIndex
      }

      list.forEach((item, index) => {
        if (item.id === Number(id)) {
          activeIndex = index
        }
      })

      return activeIndex
    },

    getMatList () {
      const id = this.level3List[this.level3Index].id || this.level2List[this.level2Index].id || this.level1List[this.level1Index].id

      report('listpage', { actiontype: 1, tabid: this.level1Index, showtype: '', clickid: '', goodsid: '', goodstype: '', free: '', classid: id, ext: '' })

      return this.$store.dispatch('getMat', this.matListParams).then(() => {
        // 模板展示上报
        this.$nextTick(() => {
          this._domArr = this.$refs.modelItem
          this.domScrollViewReportHandle()
        })
      })
    },

    modelItem (item) {
      // 组装状态返回， 跳转参数from在详情页左右切换时需要更改store对应的缓存数据
      return {
        id: item.id,
        class_id: item.class_id,
        type: item.type,
        [isCollect]: item[isCollect],
        pay_type: item.pay_type,
        title: item.name,
        img: item.small_img,
        url: `/${detail}?from=classify&${MatId}=${item.id}`
      }
    },

    clickLevel2 (index) {
      this.$store.commit('changeClassify', { level2Index: index, level3Index: level3DefaultIndex, [Page]: pageDefault })
      this.$refs.vs.scrollTo({ y: 0 }, scrollRateFast)

      const level2Id = this.level2List[this.level2Index].id

      this.matListParams[Cat2] = level2Id
      this.matListParams[Cat3] = level3DefaultId
      this.matListParams[Page] = pageDefault

      this.loadMatList()
    },

    clickLevel3 (index) {
      this.$store.commit('changeClassify', { level3Index: index, [Page]: pageDefault })
      this.$refs.vs.scrollTo({ y: 0 }, scrollRateFast)

      const level3Id = this.level3List[this.level3Index].id

      this.matListParams[Cat3] = level3Id
      this.matListParams[Page] = pageDefault

      this.loadMatList()
    },

    selectIndex (index) {
      this.$store.commit('changeClassify', { [Page]: index })
      this.$refs.vs.scrollTo({ y: 0 }, scrollRateFast)

      this.matListParams[Page] = index

      this.loadMatList()
    },

    handleScroll (v) { // 记录滚动的位置, handle-scroll-complete不生效，到时候可以优化
      if (this._timer) { clearTimeout(this._timer) }
      this._timer = setTimeout(() => {
        clearTimeout(this._timer)
        this.$store.commit('changeClassify', { [Scroll]: v.scrollTop })
        this.domScrollViewReportHandle()
      }, 500)
    },

    loadMatList () {
      return this.getMatList().then(() => {
        const isShowEntry = this.isShowCusEntry && this.page === 1 && this.level1Index === 0
        if (isShowEntry) {
          this.setCustomizationEntry()
        }
      })
    },

    setCustomizationEntry () {
      const customization = { isCustomizationEntry: true }
      const matList = [...this.matList]

      // 在第四个位置显示入口
      if (matList.length > 4) {
        matList.pop()
        matList.splice(3, 0, customization)
      } else {
        matList.push(customization)
      }
      this.$store.commit('changeMatList', matList)
    },

    domScrollViewReportHandle () { // 不能使用window.scroll统一处理，使用了第三方组件阻断了scroll事件，只为上报
      if (!this._domArr?.length) { return }
      const scroll = this.classify[Scroll]
      const parentHeight = this.$refs.vs.$el.offsetHeight

      const arr = []
      this._domArr.forEach((dom, index) => {
        const offsetTop = dom.$el.offsetTop
        if (scroll + parentHeight - modelItemHeight / 2 > offsetTop) { // 已滚动高度 + 滚动盒子高度 - 2分之1模板item高度 > 当前模板item的offsetTop
          arr.push(index)
          // 上报
          report('goodsshow', { goodsid: dom.item.id, calssid: dom.item.class_id, goodstype: this.level1Index, ver: '1', ext: '' })
        }
      })

      arr.reverse().forEach(index => {
        this._domArr.splice(index, 1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.classify_box { display: flex; flex-direction: column; height: 100%;
  .classify_header{ border-bottom: 1px solid $border_color; }
  .classify_level { display: flex; height: 40px; padding: 0 16px; font-size: 12px; color: rgba(68, 68, 68, 0.9); background-color: #fff; }
  .level_item { position: relative; display: flex; align-items: center; height: 100%; cursor: pointer;
    &:hover { color: $primary_select; }
    &.active { font-weight: bold; color: $primary_color;
      &::after { position: absolute; bottom: 0; left: 50%; width: 90%; height: 2px; content: ''; background: $primary_color; transform: translateX(-50%); }
    }
    &+.level_item { margin-left: 38px  ; }
  }

  .classify_level2 { display: flex; align-items: center; height: 100%; height: 40px; padding: 0 16px; font-size: 12px; color: rgba(68, 68, 68, 0.6); cursor: pointer }
  .level2_item { padding: 0 12px;
    &:hover { color: $primary_select; }
    &:first-child { padding-left: 0; }
    &+.level2_item { position: relative;
      &::before { position: absolute; left: 0; width: 1px; height: 12px; content: ''; background-color: #DEDEDE; opacity: 0.6; }
    }
    &.active { color: $primary_color; }
  }

  .classify_content { display: flex; flex: 1; flex-wrap: wrap; height: calc(100% - 77px); padding: 4px 4px; padding-bottom: 20px; overflow: hidden;
    &.level2 { height: calc(100% - 117px); }
    .model_item { margin: 8px 10px; }
  }

  .bottom_pagination { display: flex; align-items: center; justify-content: center; height: 36px; }
}
</style>
