/**
 * 获取Url不含参数部分
 * @function
 * @param {*} url
 * @returns {string}
 */
export const getBaseByUrl = (url = '') => {
  if (!url) {
    return ''
  }
  const index = url.indexOf('?')
  const ZERO = 0
  return index < ZERO ? url : url.substring(ZERO, index)
}

/**
 * 获取url参数
 * @function
 * @param {*} url
 * @returns {object}
 */
export const getParamsByUrl = (url) => {
  if (!url) {
    return {}
  }
  const index = url.lastIndexOf('#')
  if (index >= 0) {
    url = url.substring(0, index)
  }

  const obj = {}
  url.replace(/([^=&|?|#]+)=([^&]*)/g, function (m, key, value) {
    let val = decodeURIComponent(value)
    val = val.replace(/\+/g, ' ')

    if (obj[key]) {
      if (Array.isArray(obj[key])) {
        obj[key].push(val)
      } else {
        obj[key] = [obj[key], val]
      }
    } else {
      obj[key] = val
    }
  })
  return obj
}

/**
 * 将对象转换成url参数
 * @function
 * @param {object} obj
 * @returns {string}
 */
export const getParamStrByObj = (obj) => {
  if (!obj) {
    return ''
  }
  let paramStr = ''
  let key
  for (key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const value = encodeURIComponent(obj[key] === null ? '' : obj[key] + '')
      paramStr += `&${key}=${value}`
    }
  }
  const SUBSTRING_INDEX = 1
  return paramStr.substring(SUBSTRING_INDEX)
}

/**
 * 获取地址栏参数
 * @param {string} name 参数名称
 * @param {string} [search=location.search] 检索的字符串
 * @returns {string} 参数值
 */
export const getUrlParam = (name, search = location.search) => {
  const params = getParamsByUrl(search)
  return params[name] || ''
}
