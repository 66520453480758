import Ajax from '@shared/utils/ajax'
import { getMsgByErrCode } from '@shared/utils/errCode'

import { CacheGetUserInfo, CacheRemoveUserInfo } from '@/common/cache.js'
import { ClientRemoveUserInfo } from '@/common/clientFunc.js'
import { redirectKey } from '@/common/const.js'
import { RetCode } from './bussinessCode.js'

const requestDataFn = function (opt) {
  const data = opt.data || {}
  const userInfo = CacheGetUserInfo() || {}
  const common = {
    token: userInfo.token || '',
    uid: userInfo.uid || ''
  }

  opt.data = Object.assign({ common }, data)
  return opt
}

const responseSuccessFn = function (res) {
  const data = res.data || {}

  if (!data.resp_common || data.resp_common.ret !== 0) {
    const ret = data.resp_common.ret

    const arr = [4001002, 4001004]

    if (arr.includes(ret)) {
      // 登录过期
      CacheRemoveUserInfo()
      ClientRemoveUserInfo()
      if (window.app.$store) {
        window.app.$store.commit('changeCategoryActive', 0)
        window.app.$store.commit('changeLoginDialogVisible', true)
      } else {
        window.open(`/?${redirectKey}=` + '1', '_self')
      }
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      ret,
      msg: RetCode[ret] || getMsgByErrCode(data.resp_common.ret) || '网络错误！'
    })
  }

  return {
    ret: data.resp_common.ret,
    data: data
  }
}

const responseFailFn = function (err) {
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject(err)
}

const isDevOrTest = process.env.STAGE === 'test' || process.env.NODE_ENV === 'development'

const ajax = new Ajax({
  default: {
    method: 'POST',
    headers: { 'X-Cf-From': 'duba' },
    retry: {
      times: 0,
      delay: 200
    },
    baseUrl: !isDevOrTest ? 'https://api.6666ppt.com' : 'https://test-loveoffice.zhhainiao.com',
    requestDataFn,
    responseSuccessFn,
    responseFailFn
  }
})

const preHandle = ajax.preHandle.bind(ajax)

// http://apix.kisops.com/project/1065/interface/api/42336
const api = {
  // 运营数据
  operational: {
    getCategory: preHandle({ url: '/api/category/list' })
  },

  common: {
    config: preHandle({ url: '/api/conf/get' }),
    getServiceTime: preHandle({ url: '/api/time', method: 'get' }),
  },

  activity: {
    getFreeVip: preHandle({ url: '/api/activity/join' }),
  },

  material: {
    matListV2: preHandle({ url: '/api/mat/list/v2' }),
    searchMatList: preHandle({ url: '/api/mat/search/v2' }),
    matDetail: preHandle({ url: '/api/mat/detail' }),
    download: preHandle({ url: '/api/mat/download' }),
    customization: preHandle({ url: '/api/mat/customization' })
  },

  user: {
    needBind: preHandle({ url: '/api/bind/query' }),
    login: preHandle({ url: '/api/user/login' }),
    loginBind: preHandle({ url: '/api/user/login_bind' }),
    logout: preHandle({ url: '/api/user/logout' }),
    privliege: preHandle({ url: '/api/user/privliege' }),
    downloads: preHandle({ url: '/api/user/downloads' }),
    favoriteList: preHandle({ url: '/api/user/favorite/list' }),
    favoriteIds: preHandle({ url: '/api/user/favorite/ids' }),
    favoriteAdd: preHandle({ url: '/api/user/favorite/add' }),
    favoriteRemove: preHandle({ url: '/api/user/favorite/remove' }),
    serverIdReport: preHandle({ url: '/api/bind/register' }),
  },

  member: {
    packages: preHandle({ url: '/api/recharge/packages' }),
    prepare: preHandle({ url: '/api/recharge/prepare' }),
    wxPay: preHandle({ url: '/api/payv2/wx/native' }),
    aliPay: preHandle({ url: '/api/payv2/ali/native' }),
    payQuery: preHandle({ url: '/api/payv2/order/query' })
  },

  duba: {
    coin: preHandle({ url: '/api/duba/coin' }),
  }
}

export default api
