// 该store获取所有可以通过运营配置的数据

import api from '@/api'

export default {
  namespaced: true,
  state: () => ({
    categoryArr: []
  }),
  mutations: {
    setClassify (state, arr) {
      state.categoryArr = arr
    }
  },
  actions: {
    getCategory ({ state, commit }) {
      return api.operational.getCategory().then(res => {
        commit('setClassify', res.data.list || [])
      })
    }
  },
  getters: {

  }
}
