<template>
  <DialogC
    v-model="updateVipLevelDialogVisible" v-reportView:toast="{ actiontype: 1, toastid: 1, clickid: 0, ext: '' }" size="mini"
    @close="closeUpdateVip">
    <div class="vip_update">
      <h5 class="title">今日下载次数<strong>已达上限</strong>，不可继续下载</h5>
      <ComButton type="hover" size="big" @click="updateVipLevel">升级会员</ComButton>
    </div>
  </DialogC>
</template>

<script>
import DialogC from '@/components/DialogC/index.vue'

import { report } from '@/report/index.js'

export default {
  name: 'UpgradeMemberByDownTipDialog',

  components: { DialogC },

  computed: {
    updateVipLevelDialogVisible: {
      get: function () {
        return this.$store.state.tipsDialog.updateVipLevelDialogVisible
      },
      set: function () {
        this.$store.commit('tipsDialog/changeUpdateVipLevelDialog', false)
      }
    },
  },

  methods: {
    updateVipLevel () {
      report('toast', { actiontype: 2, toastid: 1, clickid: 1, ext: '' })
      this.$store.commit('changeBuyDialogVisible', true)
      this.$store.commit('tipsDialog/changeUpdateVipLevelDialog', false)
    },

    closeUpdateVip () { // 下载限制弹框关闭上报
      report('toast', { actiontype: 2, toastid: 1, clickid: 2, ext: '' })
    }
  }
}
</script>

<style lang="scss" scoped>
.vip_update { position: absolute; top: 0; right: 0; bottom: 0; left: 0; padding-top: 35px; text-align: center;
  .title { display: flex; justify-content: center; margin-bottom: 25px; line-height: 24px; color: #666; }
  strong { color: $primary_color; }
}
</style>
