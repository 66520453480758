<template>
  <div class="carousel">
    <div class="list" :class="{ animation: animationOpen }" :style="{ transform: `translateY(${moveY}px)` }">
      <div v-for="(text, index) in list" :key="index" class="item">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
const list = [
  '18***12开通了终生VIP',
  '晴*开通了终生VIP',
  '许***你好开通了包年VIP',
  'ab***l开通了三个月VIP',
  '刺激***摩西开通了终生VIP',
  '很好***名字开通了包年VIP',
  '天空***se开通了包年VIP',
  '白衣***ty开通了终生VIP',
  '同一***号开通了终生VIP',
  'el***江山开通了三个月VIP',
  '13***30开通了包年VIP',
  '习惯***失恋开通了包年VIP',
  '春花***in开通了终生VIP',
  '阿星***90开通了包年VIP',
  '冬雨***爷们开通了终生VIP',
  '流星***孤寂开通了三个月VIP',
  '南方***向下开通了终生VIP'
]

export default {
  name: 'Carousel',

  data () {
    return {
      moveY: 0,
      animationOpen: true,
      list,
      interval: null
    }
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  created () {
    this.interval = setInterval(() => {
      if (this.moveY < -438) {
        this.list = this.randomList(this.list)
        this.animationOpen = false
        this.moveY = 60
      } else {
        this.animationOpen = true
        this.moveY -= 20
      }
    }, 1000)
  },

  methods: {
    // 洗牌
    randomList (list) {
      list.forEach((it, index) => {
        const randomIndex = Math.floor(Math.random() * list.length)

        const item = list[index]
        list[index] = list[randomIndex]
        list[randomIndex] = item
      })

      return list
    },
  }
}
</script>

<style lang="scss" scoped>
.carousel {
  height: 60px;
  overflow: hidden;
  .list {
    &.animation {
      transition: all 0.5s linear;
    }
    .item {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      width: fit-content;
      height: 22px;
      padding: 2px 10px;
      margin-bottom: 4px;
      font-size: 12px;
      color: #333333;
      background: #F5F6FA;
      border-radius: 11px;
      opacity: 0.6;
    }
  }
}
</style>
