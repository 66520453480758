<template>
  <transition name="fade">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'TransitionAm'
}
</script>

<style lang="scss" scoped>
// transition 统一动画
// 单体统一动画
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
