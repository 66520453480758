<template>
  <span class="common_close" :style="{'font-size': size + 'px', color}">&#10005;</span>
</template>

<script>
export default {
  name: 'IconClose',

  props: {
    size: {
      type: Number,
      default: 12
    },

    color: {
      type: String,
      default: '#4A96E8'
    }
  }
}
</script>

<style lang="scss" scoped>
  .common_close { display: inline-block; color: #d3d3d3; text-align: center; cursor: pointer; }
</style>
