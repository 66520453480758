<template>
  <div v-if="activityPic" class="activity_dialog_box">
    <div class="activity_packet">
      <div class="red_packet_close" @click="close">
        <img src="~@/assets/images/icon_red_packet_close.png">
      </div>
      <img
        :src="activityPic"
        alt="activity pic"
        class="activity_pic"
        @click="getAvtivity">
    </div>
  </div>
</template>
<script>

import api from '@/api'
import { buyPackages } from '@/api/bussinessCode.js'
// import { report } from '@/report/index.js'

export default {
  name: 'ActivityDialog',
  props: {
    coin: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      activityPic: '',
      activityType: false,
      activityClick: '' // 点击触发类型
    }
  },

  computed: {

    isLoginedIn () {
      return this.$store.getters.isLoginedIn
    },
  },
  mounted () {
    this.initActivityType()
    this.getActivityPic()
  },
  methods: {
    initActivityType () { // 根据活动状态调整活动参数
      switch (this.$store.state.buyDialogType) {
        case buyPackages.actDubaMember: // 毒霸会员活动
          this.activityType = 'vipActivityPic'
          this.activityClick = 'vipActivityPicClickType'
          break

        case buyPackages.actDubaCoin: // 毒霸积分会员活动
          this.dubaCoinVisible = true
          break

        default: // 普通用户活动
          this.activityType = 'activityPic'
          this.activityClick = 'activityPicClickType'
          break
      }
    },

    getActivityPic () {
      api.common.config({ data: { conf_code: this.activityType } }).then(res => { // 查询活动图片url
        const config = res?.data?.data?.content
        if (config) {
          this.activityPic = config
        }
      })
    },

    close () {
      this.$store.commit('changeActivityDialogVisible', false)
    },

    // 点击红包
    getAvtivity () {
      this.$store.commit('changeActivityDialogVisible', false) // 隐藏活动弹窗
      if (this.isLoginedIn) {
        this.showDialog() // 展示弹框
        return
      }
      this.$store.commit('changeActivityNeedShow', true) // 设置点击标记
      this.$store.commit('changeActivityClickType', this.activityClick) // 设置点击触发类型

      this.$store.commit('changeLoginDialogVisible', true) // 展示登录框
    },

    showDialog () {
      api.common.config({ data: { conf_code: this.activityClick } }).then(res => { // 查询点击触发支付框类型
        const config = res?.data?.data?.content
        if (Number(config)) { // 活动支付
          this.$store.dispatch('getActivityWindow') // 查询是否弹出活动支付弹窗
          return
        }
        this.$store.commit('changeBuyDialogVisible', true) // 普通支付框
      })
    }
  },
}
</script>
<style lang="scss">
$that_primary: #4a96e8;
.activity_dialog_box { position: fixed; top: 0; z-index: 14; width: 100%; height: 100%; font-weight: 400; background-color: rgba(0, 0, 0, 0.4);
  .activity_packet { position: relative;
    .activity_pic { position: absolute; top:0; right:0; bottom:0; left:0; z-index: 15; margin:0 auto; cursor: pointer; }
    .red_packet_close { position: absolute; top: 50px; right: 140px; z-index: 20; width: 40px; height: 40px; cursor: pointer;
      img { margin-top: 10px; margin-left: 10px; }
    }
  }
}
</style>
