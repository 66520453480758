// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icon_search.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".search_box[data-v-e2198156]{width:180px;margin-right:10px}.search_box[data-v-e2198156],.search_box .search[data-v-e2198156]{position:relative}.search_box .search .input[data-v-e2198156]{box-sizing:border-box;width:100%;height:24px;padding:0 34px 0 10px;font-size:12px;font-weight:500;color:#888;background-color:#fff;border:1px solid #d9d9d9;border-radius:12px;outline:none}.search_box .search .btn[data-v-e2198156]{position:absolute;top:5px;right:10px;width:14px;height:14px;content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 14px 14px}.search_box .recommend_box[data-v-e2198156]{position:absolute;top:30px;left:10px;z-index:1;width:160px;background-color:#fff;border:1px solid #d9d9d9;border-radius:4px;box-shadow:0 5px 7px 0 rgba(0,0,0,.1)}.search_box .recommend_box .recommend_item[data-v-e2198156]{display:flex;align-items:center;height:28px;padding:0 10px;font-size:12px;font-weight:400;line-height:28px;color:#444}.search_box .recommend_box .recommend_item[data-v-e2198156]:hover{color:#fff;background-color:#4a96e8}.search_box .recommend_box .recommend_item:first-child h3[data-v-e2198156]{background-color:#fa6363}.search_box .recommend_box .recommend_item:nth-child(2) h3[data-v-e2198156]{background-color:#ff9336}.search_box .recommend_box .recommend_item:nth-child(3) h3[data-v-e2198156]{background-color:#fcc957}.search_box .recommend_box .recommend_item h3[data-v-e2198156]{display:flex;align-items:center;justify-content:center;width:16px;height:16px;margin-right:10px;font-size:12px;font-weight:400;color:#fff;background-color:#d7d7d7;border-radius:4px}", ""]);
// Exports
module.exports = exports;
