<template>
  <ul class="tips">
    <li v-for="(tip, index) in tips" :key="index">
      {{ tip }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Tips',

  computed: {
    tips () {
      return ['海量免费素材任用', '5分钟搞定广告设计', '10分钟完成Excel报表', '15分钟改完一份简历', '20分钟完成一份PPT']
    }
  },
}
</script>

<style scoped>
.tips {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 180px;
  height: 146px;
  padding: 16px 0 16px 12px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  list-style: disc inside;
  background: #FFFFFF;
  border-radius: 4px;
  opacity: 0.8;
}
</style>
