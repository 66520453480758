/**
 * 将 queryString 解析为 query 对象
 * @param {string} search queryString
 * @returns {{[key: string]: string | string[]}} query object
 */
export function parseQuery (search) {
  /** @type {{[key: string]: string | string[]}} */
  const query = {} // DISCUSSION: 这里是否该返回 plain object - Object.create(null)
  if (!search || typeof search !== 'string') {
    return query
  }

  const queryString = search.trim().replace(/^(\?|#|&)/, '')

  if (!queryString) {
    return query
  }

  queryString.split('&').forEach(param => {
    const parts = param.replace(/\+/g, ' ').split('=')
    const key = decodeURIComponent(parts.shift())
    const value = parts.length > 0
      ? decodeURIComponent(parts.join('='))
      : null

    if (typeof query[key] === 'undefined') {
      query[key] = value
    } else if (Array.isArray(query[key])) {
      // see https://github.com/microsoft/TypeScript/issues/36839
      // @ts-ignore
      query[key].push(value)
    } else {
      // @ts-ignore
      query[key] = [query[key], value]
    }
  })

  return query
}

/**
 * 将 query 对象转为 queryString
 * @param {{[key: string]: string | number | (string | number)[]}} query query 对象
 * @returns {string} queryString
 */
export function stringifyQuery (query) {
  if (!query) return ''
  const keys = Object.keys(query)
  const pairs = keys.filter(key => typeof query[key] !== 'undefined')
    .map(key => stringifyKeyValuePair(key, query[key]))

  return pairs.join('&')
}

/**
 * 把单个键值对转为 queryString
 * @param {string} key 健
 * @param {string | number | (string | number)[]} value 值
 * @returns {string} queryString
 */
function stringifyKeyValuePair (key, value) {
  if (value === null) {
    return encodeURIComponent(key)
  }

  if (Array.isArray(value)) {
    return value.filter(v => typeof v !== 'undefined')
      .map(v => stringifyKeyValuePair(key, v))
      .join('&')
  }

  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
}
