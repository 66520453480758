<template>
  <div class="common_model_item">
    <div class="am_box">
      <div v-show="item.pay_type === 0" class="pay_lable">{{ item.pay_type === 0 ? '免费' : '付费' }}</div>
      <div
        v-lazy:background-image="{
          src: item.img + smallImgRule,
          error: errorimage,
          loading: loadimage
        }" div class="item_img"
        @click="toDetail(item.url)" />
      <div class="info_box">
        <h5 class="model_title ellipsis_one">{{ item.title }}</h5>
        <div class="btn_box">
          <div class="collect_box" @click="changeCollect(item)">
            <IconCollect :active="item[isCollect]" />
            <p class="collect_font">收藏</p>
          </div>
          <ComButton
            size="mini" :type="item.type === modelStatus.downed ? 'hover' : ''" :theme="btnTheme(item).theme" @click="btnHandle(item)">{{ btnTheme(item).font }}</ComButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCollect from '@/components/Icons/IconCollect.vue'

import { modelStatus, MatId, isCollect } from '@/common/const.js'
import { smallImgRule } from '@/common/resources.js'
import { ClientDownFile, ClientOpenFile } from '@/common/clientFunc.js'
import { updatePrivliege } from '@/common/business.js'
import api from '@/api'
import { RetCode, limitCode, limitCodeByPay } from '@/api/bussinessCode.js'
import { parseURL } from '@/common/utils.js'
import { report } from '@/report/index.js'
import { getSource } from '@/common/cache.js'

const loadimage = require('@/assets/images/bg_loading_small.png')
const errorimage = require('@/assets/images/bg_loaded_small.png')

const Classify = 'classify'
const My = 'my'
const Searchpage = 'searchpage'

export default {
  name: 'ModelItem',

  components: { IconCollect },

  props: {
    item: {
      type: Object,
      required: true
    },

    tabid: { // 上报专用, listpage的tabid，searchpage的defaulttab（cat1）
      type: Number,
      default: 0
    },

    from: { // 上报专用
      type: String,
      default: Classify
    },

    listId: { // 上报专用，特指列表页最后选中的分类id
      type: [Number, String],
      default: ''
    }
  },

  data () {
    return {
      isCollect,
      loadimage,
      errorimage,
      modelStatus,
      smallImgRule
    }
  },

  computed: {
    isLoginedIn () {
      return this.$store.getters.isLoginedIn
    },

    level1Index () {
      return this.$store.state.categoryActive
    },

    level1List () {
      return this.$store.state.operational.categoryArr
    },

    getClassid () {
      if (this.level1Index < this.level1List.length) {
        return this.level1List[this.level1Index].id
      }
      return ''
    }
  },

  methods: {
    btnTheme (item) {
      const modelMap = {
        [modelStatus.down]: { theme: 'yellow', font: '立即下载' },
        [modelStatus.downing]: { theme: 'green', font: '正在下载' },
        [modelStatus.downed]: { theme: '', font: '打开' }
      }

      return modelMap[item.type]
    },

    cutSuffix (url) {
      const lastUrl = parseURL(url).file
      return lastUrl.slice(lastUrl.indexOf('.'))
    },

    reportListPage (clickid) {
      // 搜索不报
      const classid = this.isMy() ? this.getClassid : this.listId

      report('listpage', { actiontype: 2, tabid: this.tabid, showtype: '', clickid, goodsid: this.item.id, goodstype: '', free: this.item.pay_type ? 2 : 1, classid, ext: '' })
    },

    reportSearchPage (pos) {
      const word = this.$store.state.search.searchVal
      report('searchpage', { actiontype: 2, word, defaulttab: this.tabid, amount: '', clickid: pos, free: '', goodesid: this.item.id, goodstype: this.tabid, classid: this.item.class_id || '', ext: '' })
    },

    isSearch () {
      return this.from === Searchpage
    },

    isMy () {
      return this.from === My
    },

    isClassify () {
      return this.from === Classify
    },

    btnHandle (item) {
      if (item.type === modelStatus.downing) { return }

      if (item.type === modelStatus.down) {
        this.isMy() && this.reportListPage(2)
        this.isClassify() && this.reportListPage(2)
        this.isSearch() && this.reportSearchPage(2)

        if (!this.isLoginedIn) {
          this.$store.commit('changeLoginDialogVisible', true)
          return
        }

        updatePrivliege(this.$store).finally(() => {
          this.download(item)
        })

        return
      }

      this.isMy() && this.reportListPage(3)
      this.isClassify() && this.reportListPage(3)
      this.isSearch() && this.reportSearchPage(3)

      ClientOpenFile({ id: item.id + '' }, (res) => {
        if (!res?.result) {
          this.$toast({ desc: '打开文件失败，请联系客服' })
        }
      })
    },

    reportDownload (item, ext = '') {
      report('download', { goodsid: item.id, goodstype: this.level1Index, free: '', classid: this.getClassid, ext })
    },

    download (item) {
      const id = item.id + ''

      api.material.download({ data: { [MatId]: item.id, source: getSource() } }).then(res => {
        const url = res?.data?.url
        if (url) {
          this.$store.commit('pushDowningList', id)

          this.reportDownload(item)
          ClientDownFile({ id, filename: item.title + this.cutSuffix(url), url })
          return
        }
        this.$toast({ desc: '获取链接失败，请联系客服' })
      }).catch(data => {
        const code = String(data?.ret || '')

        if (code === limitCodeByPay) {
          this.reportDownload(this.item, '1')
          this.$store.commit('changeBuyDialogVisible', true)
          return
        }

        if (code === limitCode) {
          this.reportDownload(this.item, '2')
          this.$store.commit('tipsDialog/changeUpdateVipLevelDialog')
          return
        }

        this.reportDownload(this.item, '3')

        if (RetCode?.[code]) {
          this.$toast({ desc: RetCode[data.ret] })
        }
      })
    },

    toDetail (url) {
      this.isMy() && this.reportListPage(1)
      this.isClassify() && this.reportListPage(1)
      this.isSearch() && this.reportSearchPage(1)

      this.$router.push({ path: url })
    },

    changeCollect (item) {
      if (item[isCollect]) { // 该字段外部协定的，非接口定义
        this.reportListPage(5)
        this.$store.dispatch('removeCollectId', item.id)
        return
      }
      this.reportListPage(4)
      this.$store.dispatch('addCollectId', item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.common_model_item { position: relative; float: left; width: 222px; height: 316px; font-size: 0;
  &:hover {
    .am_box { width: 222px; height: 316px; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4); transition: all 0.1s linear; }
  }
  .am_box { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; width: 216px; height: 306px; margin: auto; overflow: hidden; text-decoration: none; vertical-align: middle; background-color: #fff; border-radius: 4px; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2); }

  .pay_lable { position: absolute; top: 0; left: 0; width: 36px; height: 18px; font-size: 12px; line-height: 18px; color: #fff; text-align: center; background-color: $primary_color; border-bottom-right-radius: 4px; }

  .item_img { flex: 1; cursor: pointer; background-repeat: no-repeat; background-size: cover; }
  .item_img[lazy=loading], .item_img[lazy=error]{ background-position: center center; background-size: 200px; }
  .info_box { height: 59px; padding: 0 8px; }
  .model_title { margin-top: 10px; font-size: 12px; font-weight: 400; color: #666; }
  .btn_box { display: flex; justify-content: space-between; margin-top: 8px; }

  .collect_box { display: flex; align-items: center; font-size: 12px; cursor: pointer; }
  .collect_font { margin-left: 3px; }
}

</style>
