<template>
  <div :class="['line']">
    <span>{{ detail.title }}</span>
    <span class="price">{{ detail.text }}</span>
    <span v-if="detail.validate" class="validate_text">{{ validateText }}</span>
    <div :class="['switch_container', { can_open: canOpen }]">
      <i v-if="canOpen" class="switch" />
    </div>
    <div class="list_opened">
      <p v-for="(item, index) in detailListOpened" :key="index" class="item_opened">
        <span>{{ item.title }}</span>
        <span class="price">{{ item.text }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscountLine',

  props: {
    detail: {
      type: Object,
      default: () => ({
        title: '优惠券合计：',
        text: '-￥240（4折）',
        validate: '2021/07/21'
      })
    },
    canOpen: {
      type: Boolean,
      default: true
    },
    detailListOpened: {
      type: Array,
      default: () => [
        {
          title: '专属优惠劵：',
          text: '-￥240（4折）'
        },
        {
          title: '专属优惠劵：',
          text: '-￥240（4折）'
        },
      ]
    }
  },

  computed: {
    validateText () {
      const { validate } = this.detail
      return '有效期：' + validate
    }
  },
}
</script>

<style lang="scss" scoped>
.line {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  background: #FFFFFF;
  border-radius: 4px;
}

.list_opened {
  position: absolute;
  top: 31px;
  left: 0;
  z-index: 15;
  box-sizing: border-box;
  display: none;
  width: 100%;
  padding: 12px 16px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(198, 125, 0, 0.3);
  .item_opened:not(:last-child) {
    margin-bottom: 16px;
  }
}

.price {
  color: #B28339;
}

.switch_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  margin-left: auto;
}

.switch_container.can_open {
  &:hover {
    cursor: pointer;
  }
  &:hover +.list_opened {
    display: block;
  }
  &:hover .switch {
    transform: translateY(3px) rotate(-45deg)
  }
}

.switch {
  width: 11px;
  height: 11px;
  border-top: 1px solid black;
  border-right: 1px solid black;
  transform: translateY(-3px) rotate(135deg);
}
</style>
