/**
 * 数字字母种子
 * @date 2020-10-28
 * @const
 */
const ALPHANUM = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

/**
 * 16进制字符种子.
 * @date 2020-10-28
 * @const
 */
const HEX_DIGITS = '0123456789abcdef'

/**
 * 用英文字母和数字生成指定随机串.
 * @date 2020-10-28
 * @param {number} length 待生成的随机串长度.
 * @returns {string}
 */
export function randomAlphanumString (length) {
  return randomString(ALPHANUM, length)
}

function randomElement (arr) {
  return arr[randomInt(0, arr.length - 1)]
}

/**
 * 生成指定长度的16进制随机串
 * @date 2020-10-28
 * @param {number} length
 * @returns {string}
 */
export function randomHexString (length) {
  return randomString(HEX_DIGITS, length)
}

/**
 * 指定范围生成随机整数[min, max].
 * @date 2020-10-28
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function randomInt (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * 生成指定长度的随机字符串
 * @date 2020-10-28
 * @param {string} characters
 * @param {number} length
 * @returns {string}
 */
export function randomString (characters, length) {
  let result = ''

  for (let i = 0; i < length; ++i) {
    result += randomElement(characters)
  }

  return result
}
