// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/bg_freevip.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/bg_freevip_header.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/images/logo_mini_white.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".freeVip .common_dialog{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.freeVip .header{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat}.freeVip .close_btn,.freeVip p{color:#fff!important}.freeVip .icon_logo{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.freeVip_dialog{position:absolute;top:0;right:0;bottom:0;left:0;display:flex;flex-direction:column;align-items:center;justify-content:center;color:#666}.freeVip_dialog .freeVip_title{position:relative;top:-20px;font-size:18px;font-weight:700}.freeVip_dialog .freeVip_title span{color:#ef422c}.freeVip_dialog .freeVip_title2{position:relative;top:-10px;font-size:14px;letter-spacing:2px;opacity:.8}.freeVip_dialog .first_btn{width:82px;margin-right:50px}.freeVip_dialog .freeVip_btn{position:relative;top:10px}.freeVip_dialog .btn_green{color:#fff;background:#36ca60;border-color:#36ca60}.freeVip_dialog .btn_green:hover{color:#fff;background-color:#3ddc6a}.freeVip_dialog .btn_green.active{background-color:#30b957!important;border-color:#30b957!important}.freeVip_dialog .btn_grey{color:#666;background-color:#fff;border-color:#666;opacity:.4}.freeVip_dialog .btn_grey:hover{opacity:.6}.freeVip_dialog .btn_grey.active{background:#fff!important;border-color:#666!important;opacity:.8}", ""]);
// Exports
module.exports = exports;
