<template>
  <div class="common_font_line">
    <span class="line" :style="`width: ${width}px`" />
    <p class="font"><slot /></p>
    <span class="line" :style="`width: ${width}px`" />
  </div>
</template>

<script>
export default {
  name: 'FontLine',

  props: {
    width: {
      type: Number,
      default: 40
    }
  }
}
</script>

<style lang="scss" scoped>
.common_font_line { display: flex; align-items: center; justify-content: center; height: 17px; font-size: 12px; font-weight: 400; line-height: 17px; color: #999; text-align: center;
  .font { position: relative; padding: 0 16px; }
  .line { display: inline-block; height: 1px; background-color: #999; }
}
</style>
