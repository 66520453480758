<template>
  <div class="countdown">
    优惠倒计时：
    <div class="box">
      {{ h }}
    </div>
    时
    <div class="box">
      {{ m }}
    </div>
    分
    <div class="box">
      {{ s }}
    </div>
    秒
  </div>
</template>

<script>
export default {
  name: 'Countdown',

  props: {
    timeDiff: {
      type: Number,
      default: 0
    },
  },

  data () {
    return {
      h: '',
      m: '',
      s: ''
    }
  },

  watch: {
    timeDiff () {
      this.updateHms()
    }
  },

  methods: {
    updateHms () {
      const date = this.timeDiff
      const hours = parseInt(date / (1000 * 60 * 60))
      const minutes = parseInt((date % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = parseInt((date % (1000 * 60)) / 1000)
      this.h = hours < 10 ? ('0' + hours) : hours
      this.m = minutes < 10 ? ('0' + minutes) : minutes
      this.s = seconds < 10 ? ('0' + seconds) : seconds
    },
  }
}
</script>

<style lang="scss" scoped>
.countdown {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 400;
  .box {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 18px;
    margin: 0 2px;
    border: 1px solid #EECA82;
    border-radius: 4px;
  }
}
</style>
