import ToastCom from './ToastCom.vue'

let ToastDom
let ToastVm

export const Toast = {
  install (Vue) {
    Vue.prototype.$toast = ({ desc = '', duration = 2000 }) => {
      if (typeof window === 'undefined' || (ToastVm && ToastVm.visible)) { return }

      if (!ToastVm) {
        ToastDom = Vue.extend(ToastCom)
        ToastVm = new ToastDom({ el: document.createElement('div') })

        document.body.appendChild(ToastVm.$el)
      }

      ToastVm.desc = desc
      ToastVm.visible = true

      setTimeout(() => {
        ToastVm.visible = false
      }, duration)
    }
  }
}
