// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icon_dialog_wechat.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/icon_dialog_wechat_hover.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/images/icon_dialog_qq.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/images/icon_dialog_qq_hover.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".login_dialog_box[data-v-69ed5673]{position:fixed;top:0;z-index:14;width:100%;height:100%;font-weight:400;background-color:rgba(0,0,0,.4)}.login_dialog_box .dialog_box[data-v-69ed5673]{position:absolute;top:50%;left:50%;width:434px;height:454px;margin:-227px 0 0 -217px;background-color:#fff}.login_dialog_box .close_box[data-v-69ed5673]{display:flex;align-items:center;justify-content:space-between;height:30px;padding:0 10px;font-size:0;text-align:right;border-bottom:1px solid #dedede}.login_dialog_box .title[data-v-69ed5673]{font-size:12px;color:#4a96e8}.login_dialog_box .old_user_connect[data-v-69ed5673]{padding:50px 68px;font-size:14px}.login_dialog_box .login_box[data-v-69ed5673]{display:flex;justify-content:center;padding:40px 80px 70px 80px}.login_dialog_box .login_item[data-v-69ed5673]{position:relative;display:inline-block;width:90px;height:122px;cursor:pointer;background-repeat:no-repeat;background-size:100% auto}.login_dialog_box .login_item[data-v-69ed5673]:first-child{margin-right:54px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.login_dialog_box .login_item[data-v-69ed5673]:first-child:hover{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.login_dialog_box .login_item[data-v-69ed5673]:nth-child(2){background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.login_dialog_box .login_item[data-v-69ed5673]:nth-child(2):hover{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.login_dialog_box .item_name[data-v-69ed5673]{position:absolute;bottom:0;width:100%;font-size:14px;color:#666;text-align:center}.login_dialog_box .remark[data-v-69ed5673]{font-size:12px;color:#666;text-align:center;opacity:.5}", ""]);
// Exports
module.exports = exports;
