<template>
  <div v-show="pageNum > 1" class="common_pagination">
    <span v-show="active > 1" class="arrow" @click="prev" />
    <ul class="page_num">
      <li
        v-for="item in pageArr" :key="item"
        v-report:mainclick="{ area: 'pageturn' }"
        :class="{ 'active': active === item }" class="page_item"
        @click="changeActive(item)">{{ item }}</li>
    </ul>
    <span v-show="active < pageNum" class="arrow next" @click="next" />
  </div>
</template>

<script>
/**
 * 页码以1开始，与接口保持统一
 */

import { pageSize } from '@/common/const.js'

export default {
  name: 'Pagination',

  props: {
    total: {
      type: Number,
      required: true
    },

    size: {
      type: Number,
      default: pageSize
    },

    defaultActive: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      active: this.defaultActive
    }
  },

  computed: {
    pageNum () {
      return Math.ceil(this.total / this.size)
    },

    pageArr () {
      const startIndex = this.active - 3
      const startPos = startIndex <= 0 ? 0 : startIndex

      const endIndex = this.active + 7
      const endPos = this.active < 3 ? (endIndex - startIndex) : endIndex

      return new Array(this.pageNum).fill('').map((item, index) => index + 1).slice(startPos, endPos)
    }
  },

  watch: {
    defaultActive (newVal) {
      this.active = newVal
    }
  },

  methods: {
    prev () {
      this.changeActive(this.active - 1)
    },

    next () {
      this.changeActive(this.active + 1)
    },

    changeActive (index) {
      this.active = index
      this.$emit('selectIndex', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.common_pagination { display: flex; align-items: center; justify-content: center;
  .arrow { display: inline-block; width: 22px; height: 22px; cursor: pointer; background: url(~@/assets/images/icon_arrow.png) center center no-repeat; border-radius: 4px;
    &.next { transform: rotate(180deg); }
    &:hover { background-color: $primary_color; background-image: url(~@/assets/images/icon_arrow_active.png); }
  }

  .page_num { display: flex; padding: 0 10px; font-size: 12px;
    .page_item { display: flex; align-items: center; justify-content: center; width: 22px; height: 22px; text-align: center; cursor: pointer; border-radius: 4px;
      &+.page_item { margin-left: 5px; }
      &.active, &:hover { color: #fff; background-color: $primary_color; }
    }
  }
}
</style>
