export const ERR_CODE = {
  BAD_REQUEST: 4001001, // 参数错误
  AUTH_EXPIRED: 4001002, // 用户登录信息过期
  DEPRECATED_CLIENT: 4001003, // 客户端版本太低
  NEED_LOGIN: 4001004, // 这个接口需要登录状态
  REQUEST_EXPIRED: 4001005, // 请求过期，请检查系统时间或者同步时间
  FORBIDDEN: 4001006, // 禁止访问
  SERVER_ERROR: 5001001, // 服务器错误（未知）
  SERVER_BUSY: 5001002 // 服务器繁忙，请过一段时间再试
}

export const ERR_CODE_TEXT = {
  [ERR_CODE.BAD_REQUEST]: '请求参数异常',
  [ERR_CODE.AUTH_EXPIRED]: '登录已过期，请重新登录',
  [ERR_CODE.DEPRECATED_CLIENT]: '客户端版本太低，请检查',
  [ERR_CODE.NEED_LOGIN]: '请先登录',
  [ERR_CODE.REQUEST_EXPIRED]: '请求过期，请检查系统时间或者同步时间',
  [ERR_CODE.FORBIDDEN]: '禁止访问',
  [ERR_CODE.SERVER_ERROR]: '系统异常，请稍候重试',
  [ERR_CODE.SERVER_BUSY]: '系统繁忙，请稍候重试'
}
