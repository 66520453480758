// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icons_privilege/精品PPT.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/icons_privilege/文档简历.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/images/icons_privilege/Excel报表.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/images/icons_privilege/广告设计.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@/assets/images/icons_privilege/素材元素.png");
var ___CSS_LOADER_URL_IMPORT_5___ = require("@/assets/images/icons_privilege/终身免费.png");
var ___CSS_LOADER_URL_IMPORT_6___ = require("@/assets/images/icons_privilege/无限下载.png");
var ___CSS_LOADER_URL_IMPORT_7___ = require("@/assets/images/icons_privilege/一对一客服.png");
var ___CSS_LOADER_URL_IMPORT_8___ = require("@/assets/images/icons_privilege/下载30次_日.png");
var ___CSS_LOADER_URL_IMPORT_9___ = require("@/assets/images/icons_privilege/下载10次_日.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
exports.push([module.id, ".privilege[data-v-313e75b2]{box-sizing:border-box;display:flex;flex-wrap:wrap;justify-content:space-between;width:182px;height:335px;padding:24px;background:#fff;border-radius:8px}.privilege .icon_box[data-v-313e75b2]{display:flex;flex-direction:column;align-items:center;width:66px;font-size:10px;font-weight:400;color:#333}.privilege .icon[data-v-313e75b2]{width:34px;height:34px;margin-bottom:7px}.privilege .icon.icon_0[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.privilege .icon.icon_1[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat}.privilege .icon.icon_2[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat}.privilege .icon.icon_3[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat}.privilege .icon.icon_4[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat}.privilege .icon.icon_5[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") no-repeat}.privilege .icon.icon_6[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") no-repeat}.privilege .icon.icon_7[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") no-repeat}.privilege .icon.icon_8[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") no-repeat}.privilege .icon.icon_9[data-v-313e75b2]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") no-repeat}", ""]);
// Exports
module.exports = exports;
