/**
 *
 * 上报指令
 */
import Vue from 'vue'

import { report } from '@/report/index.js'

function clickEl () {
  report(this.binding.arg, this.binding.value)
}

function bind (el, binding) {
  el.binding = binding
  el.addEventListener('click', clickEl, true)
}

function unbind (el) {
  el.removeEventListener('click', clickEl, true)
}

Vue.directive('report', {
  bind,
  unbind
})

Vue.directive('reportView', {
  bind: function (el, binding) {
    report(binding.arg, binding.value)
  }
})
