<template>
  <div class="qr_dialog">
    <QrIframe :url="url" :height="height" />
  </div>
</template>

<script>
import QrIframe from '@/views/common/QrDialog/QrIframe.vue'

export default {
  name: 'QrDialog',

  components: { QrIframe },

  props: {
    height: {
      type: Number,
      default: 469
    },
    url: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.qr_dialog { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;}
</style>
