<template>
  <div v-reportView:login="{ step: 1, usertype: '', ext: 2 }" class="login_dialog_box">
    <div class="dialog_box">
      <div class="close_box"><IconClose @click.native="clickClose" /></div>
      <!-- logo -->
      <div class="dialog_logo" />
      <p class="dialog_title">{{ channelStr }}官方合作的办公内容平台</p>

      <!-- 二维码 -->
      <div class="qr_box">
        <h3 class="qr_title">微信登录</h3>
        <div class="qr">
          <QrIframe
            v-show="loginUrlWechat" scrolling="no" :height="148" :width="148"
            :url="loginUrlWechat" />
          <div class="wechat_icon" />
        </div>
        <p class="desc">使用微信扫描二维码<strong>注册/登录</strong></p>
      </div>

      <div class="user_agreement_box">
        <p class="font">登录即同意《<a class="agreement" @click="userProtocol">用户协议</a>》</p>
      </div>
      <div class="login_box">
        <div class="login_item" @click="login()" />
        <p class="login_item_desc">QQ登录</p>
      </div>
    </div>

    <DialogC v-model="loginQrDialog" size="max" :title="'完美办公'">
      <QrDialog :type="loginType.qq" :url="loginUrl" />
    </DialogC>
  </div>
</template>

<script>
import IconClose from '@/components/Icons/IconClose.vue'
import QrDialog from '@/views/common/QrDialog/index.vue'
import QrIframe from '@/views/common/QrDialog/QrIframe.vue'
import DialogC from '@/components/DialogC/index.vue'

import { loginType, postMessageType, queryLoginType } from '@/common/const.js'
import { proxyUrl, userProtocol } from '@/common/resources.js'
import { ClientOpenWebsiteUrl, ClientSetUserInfo } from '@/common/clientFunc.js'
import { reportServerId } from '@/common/business.js'
import { getChannelName } from '@/common/utils.js'
import { CacheSetUserInfo, getSource } from '@/common/cache.js'
import { report } from '@/report/index.js'

import api from '@/api'
import { RetCode, freeVipState } from '@/api/bussinessCode.js'

import WeChatAuth from '@/plugins/auth/wechatAuth.js'
import QQAuth from '@/plugins/auth/qqAuth.js'

export default {
  name: 'LoginDialog',

  components: { IconClose, DialogC, QrDialog, QrIframe },

  data () {
    return {
      loginType: Object.freeze(loginType),
      loginUrl: '',
      loginUrlWechat: '',
      loginQrDialog: false,
      channelStr: getChannelName(),
      wechatClass: new WeChatAuth({ isStyle: true }),
      qqClass: new QQAuth()
    }
  },

  computed: {
    isVip () {
      return this.$store.getters.isVip
    },

    freeVipState () { // 领取会员信息弹框状态
      return freeVipState
    },

    userInfo () {
      return this.$store.state.userInfo
    },
  },

  created () {
    this.loginWechat()
    this.listenMessage()
  },

  destroyed () {
    window.removeEventListener('message', this.listenFunc)
  },

  methods: {
    reportLogin (step, type = '') {
      report('login', { step, usertype: type, ext: 2 })
    },

    clickClose () {
      this.$store.commit('changeLoginDialogVisible', false)
    },

    userProtocol () {
      ClientOpenWebsiteUrl(userProtocol)
    },

    loginWechat () {
      this.loginUrlWechat = this.wechatClass.openAuthPage()
      this.reportLogin(2)
    },

    login () {
      this.loginUrl = this.qqClass.openAuthPage()
      this.loginQrDialog = true
      this.reportLogin(3)
    },

    listenMessage () {
      window.addEventListener('message', this.listenFunc)
    },

    listenFunc (data) {
      if (data.data && typeof data.data === 'string') {
        const newData = JSON.parse(data.data)
        const search = newData.search

        if (!search) { return }

        this.reportLogin(4)

        const searchState = search.state
        const qqState = this.qqClass.getState()
        const wechatState = this.wechatClass.getState()

        if (!searchState || !(searchState === qqState || searchState === wechatState)) { return }

        this.reportLogin(5)

        if (!search.code) { return }
        this.userLogin(search.code, search[queryLoginType]) // LoginType第三方接口传过去的参数
      }
    },

    userLogin (code, type) {
      const params = { login_type: 1, code, channel: getSource() }

      // qq登录参数，默认微信登录
      const qqLogin = type === postMessageType.qq
      if (qqLogin) {
        params.qq_redirect_url = encodeURIComponent(proxyUrl)
        params.login_type = 2
      }

      api.user.login({ data: params }).then(res => {
        const data = res.data

        const userType = data?.user_info?.is_new_user === 0 ? 2 : 1 // 默认是新注册

        this.reportLogin(type === postMessageType.qq ? 7 : 6, userType)

        // 存储userInfo，下一步上报需要
        const userInfo = { token: data.token, ...data.user_info }
        this.$store.commit('setUserInfo', userInfo)
        CacheSetUserInfo(userInfo)

        // 为新注册用户，需要上报serverId
        // 需要保证在CacheSetUserInfo后面上报，因为需要传token值需要从cache获取
        if (userType === 1) {
          reportServerId()
        }

        ClientSetUserInfo(userInfo)
        this.getPrivliege(type, userType) // 获取用户权益
      }).catch(() => {
        this.$toast({ desc: '登录异常，请联系客服或刷新重试' })
        this.$store.commit('changeLoginDialogVisible', false)
      })
    },

    getPrivliege (type, userType) {
      let privliegeName = ''
      let privliegeEndTime = ''
      let isVip = false

      api.user.privliege({ data: { common: { token: this.userInfo.token, uid: this.userInfo.uid } } }).then(pRes => {
        privliegeName = pRes.data.name
        privliegeEndTime = pRes.data.end_date
        isVip = true
      }).catch(data => {
        if (data?.ret && data.ret.toString() in RetCode) {
          privliegeName = RetCode[data.ret]
        }
      }).finally(() => {
        const lastUserInfo = { privliegeName, privliegeEndTime, isVip, ...this.userInfo }

        this.reportLogin(type === postMessageType.qq ? 9 : 8, userType)

        this.$store.commit('setUserInfo', lastUserInfo)
        CacheSetUserInfo(lastUserInfo)
        ClientSetUserInfo(lastUserInfo)
        this.$store.commit('changeLoginDialogVisible', false)
        this.loginQrDialog = false

        this.$store.dispatch('getUserCollectIdList') // 获取用户收藏所有id列表

        this.$store.dispatch('initBuyBtn') // 登录后终身会员隐藏成为会员按钮
        this.showVipActivityDialog() // 登录后判断三日会员与终身活动弹窗
      })
    },

    showVipActivityDialog () { // 活动流程
      const freevipMessage = this.$store.state.tipsDialog.freevipMessage
      if (freevipMessage.freevipMessageState === 1) { // 判断登录前是否点击领取会员
        this.initFreePackage()
        return
      }
      this.getActivityWindow()
    },

    getActivityWindow () { // 终身会员弹窗展示
      const userDate = this.$store.getters.initUserDate
      const needShow = this.$store.state.activityNeedShow
      if (userDate > this.$store.getters.lifeTime && needShow) { // 非终身会员走终身vip活动流程
        this.$store.commit('tipsDialog/changeVipActivityMsgVisible', true) // 展示终身会员活动消息框
        return
      }
      this.showDialog() // 弹出支付框
    },

    showDialog () {
      const activityClickType = this.$store.state.activityClickType
      api.common.config({ data: { conf_code: activityClickType } }).then(res => { // 查询点击触发支付框类型
        const config = res?.data?.data?.content
        if (Number(config)) { // 活动支付
          this.$store.dispatch('getActivityWindow') // 查询是否弹出活动支付弹窗
          return
        }
        this.$store.commit('changeBuyDialogVisible', true) // 普通支付框
      }).catch(() => {
        this.$store.dispatch('getActivityWindow') // 未指定弹出类型则直接弹活动框
      })
    },

    initFreePackage () {
      api.member.packages({ data: { chan: 'act-3d' } }).then(res => {
        const packages = res?.data?.pri_packages
        if (packages) {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          this.$store.commit('tipsDialog/changeFreevipMessage', { freeVipPackage: packages[0] }) // 设置套餐
          this.getFreePackage()
        }
      })
    },

    getFreePackage () { // 领取vip
      const source = getSource()
      const freevipMessage = this.$store.state.tipsDialog.freevipMessage
      this.freeVipDialogVisible = false
      api.activity.getFreeVip({ data: { pri_id: freevipMessage.freeVipPackage.id, source: source } }).then(res => {
        const respCommon = res?.data?.resp_common
        if (!respCommon.ret) {
          this.getFreeVipSuccess()
          this.$store.commit('tipsDialog/changeFreevipMessage', { freevipMessageDialog: true, freevipMessageState: freeVipState.suc })
        }
      }).catch((res) => {
        this.$store.commit('tipsDialog/changeFreevipMessage', { freevipMessageDialog: true, freevipMessageState: res.ret })
      })
    },

    getFreeVipSuccess () { // 领取成功设置会员
      api.user.privliege().then(res => {
        const data = res.data
        if (!data.name || !data.end_date) {
          this.$toast({ desc: '领取成功，请刷新页面' })
        }
        const lastUserInfo = { ...this.userInfo, isVip: true, privliegeName: data.name, privliegeEndTime: data.end_date }
        this.$store.commit('setUserInfo', lastUserInfo)
        CacheSetUserInfo(lastUserInfo)
        ClientSetUserInfo(lastUserInfo)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.login_dialog_box { position: fixed; top: 0; z-index: 99; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.4);
  .dialog_box { position: absolute; top: 50%; left: 50%; width: 434px; height: 454px; margin: -227px 0 0 -217px; background-color: #fff; border-radius: 8px; }
  .close_box { box-sizing: border-box; height: 30px; padding: 10px; overflow: hidden; font-size: 0; text-align: right; }

  .dialog_logo { height: 32px; background-image: url(~@/assets/images/logo.png); background-repeat: no-repeat; background-position: center center; background-size: contain; }
  .dialog_title { margin: 9px auto 26px auto; font-size: 12px; font-weight: 400; line-height: 12px; color: $primary_color; text-align: center; }

  .qr_box { display: flex; flex-direction: column; align-items: center; }
  .qr_title { margin-bottom: 8px; font-size: 16px; font-weight: bold; line-height: 16px; color: #333; }
  .qr { position: relative; display: flex; align-items: center; justify-content: center; width: 148px; height: 148px; font-size: 12px; line-height: 12px; color: #fff;
    .wechat_icon { position: absolute; top: 50%; left: 50%; width: 50px; height: 50px; background-image: url(~@/assets/images/icon_dialog_wechat.png); background-size: cover; transform: translate(-50%, -50%); }
  }
  .desc { margin: 13px auto 20px auto; font-size: 14px; font-weight: 400; line-height: 14px; line-height: 14px; color: #666;
    strong { font-weight: bold; }
  }

  .user_agreement_box { margin-bottom: 20px; text-align: center;
   .font { display: inline-block; font-size: 12px; font-weight: 400; line-height: 12px; color: #666; }
   .agreement { color: $primary_color; text-decoration: none; cursor: pointer; }
  }

  .login_box { display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 0; }
  .login_item { display: inline-block; width: 48px; height: 48px; cursor: pointer; background-image: url(~@/assets/images/icon_dialog_qq.png); background-size: cover;
    &:hover { background-image: url(~@/assets/images/icon_dialog_qq_hover.png); }
  }
  .login_item_desc { margin-top: 7px; font-size: 12px; line-height: 12px; }
}
</style>
