<template>
  <span class="checkbox" :class="{ active: active, disable: disable }" @click="select" />
</template>

<script>
export default {
  name: 'Checkbox',

  props: {
    status: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      active: this.status,
    }
  },

  watch: {
    status (newSate) {
      this.active = newSate
    }
  },

  methods: {
    select () {
      if (this.disable) { return }

      this.active = !this.active
      this.$emit('select', this.active)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox { display: inline-block; width: 18px; height: 18px; margin-right: 6px; vertical-align: middle; cursor: pointer; background-image: url(~@/assets/images/icon_checkbox.png); background-size: cover;
    &.active { background-image: url(~@/assets/images/icon_checkbox_hover.png); }
    &.active.disable { background-image: url(~@/assets/images/icon_checkbox_hover_disable.png); }
  }
</style>
