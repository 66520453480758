import { ERR_CODE_TEXT } from './constant'

/**
 * 获取对应接口错误码的中文提示
 * @function
 * @param {number|string} errCode 后端返回的错误码
 * @returns {string}
 */
export const getMsgByErrCode = (errCode) => {
  if (!errCode) return ''

  return ERR_CODE_TEXT[errCode] || ''
}
