<template>
  <DialogC
    v-model="freeVipDialogVisible" v-reportView:tips="{ tipstype: 1, actiontype: 1, click: '', ext: '' }" size="mini"
    class="freeVip" @close="freeVipCloseReport">
    <div class="freeVip_dialog">
      <h1 class="freeVip_title">恭喜您获得<span>3日免费下载特权！</span></h1>
      <h2 class="freeVip_title2">海量PPT模板，总结汇报、教育教学...</h2>
      <div class="freeVip_btn">
        <ComButton
          size="big" class="first_btn btn_green"
          @click="getFreeVip">立即领取</ComButton>
        <ComButton size="big" class="btn_grey" @click="closeHandle">我不需要</ComButton>
      </div>
    </div>
  </DialogC>
</template>

<script>
import DialogC from '@/components/DialogC/index.vue'

import { getParamsByUrl } from '@shared/utils/base/index.js'

import { getSource, CacheSetUserInfo } from '@/common/cache.js'
import { ClientCloseWindow, ClientSetUserInfo } from '@/common/clientFunc.js'
import { report } from '@/report/index.js'
import { freeVipState } from '@/api/bussinessCode.js'
import api from '@/api'

export default {
  name: 'ThreeDayMemberTipDialog',

  components: { DialogC },

  data () {
    return {
      freeVipDialogVisible: false, // 3日会员弹框
    }
  },

  computed: {
    isLoginedIn () {
      return this.$store.getters.isLoginedIn
    }
  },

  created () {
    const searchObj = getParamsByUrl(location.search)
    if (searchObj.activity) { // 判断3日免费会员弹窗
      this.freeVipDialogVisible = true // 展示领取活动弹窗
    }
  },

  methods: {
    freeVipCloseReport (click = 3) {
      report('tips', { tipstype: 1, actiontype: 2, click, ext: '' })
    },

    closeHandle () { // 我不需要
      this.freeVipCloseReport(2)
      ClientCloseWindow()
    },

    getFreeVip () { // 立即领取
      this.freeVipDialogVisible = false

      this.freeVipCloseReport(1)

      if (!this.isLoginedIn) {
        this.$store.commit('changeLoginDialogVisible', true)
        // eslint-disable-next-line sonarjs/no-duplicate-string
        this.$store.commit('tipsDialog/changeFreevipMessage', { freevipMessageState: freeVipState.ckickBtn })
        return
      }

      this.initFreePackage()
    },

    initFreePackage () { // 获取套餐
      api.member.packages({ data: { chan: 'act-3d' } }).then(res => {
        const packages = res?.data?.pri_packages

        if (packages) {
          this.$store.commit('tipsDialog/changeFreevipMessage', { freeVipPackage: packages[0] }) // 设置套餐
          this.getFreePackage()
        }
      })
    },

    getFreePackage () { // 领取vip
      const source = getSource()
      const freevipMessage = this.$store.state.tipsDialog.freevipMessage
      this.freeVipDialogVisible = false

      api.activity.getFreeVip({ data: { pri_id: freevipMessage.freeVipPackage.id, source: source } }).then(res => {
        const respCommon = res?.data?.resp_common

        if (!respCommon.ret) {
          this.getFreeVipSuccess()
          this.$store.commit('tipsDialog/changeFreevipMessage', { freevipMessageDialog: true, freevipMessageState: freeVipState.suc })
        }
      }).catch((res) => {
        this.$store.commit('tipsDialog/changeFreevipMessage', { freevipMessageDialog: true, freevipMessageState: res.ret })
      })
    },

    getFreeVipSuccess () { // 领取成功
      api.user.privliege().then(res => {
        const data = res.data

        if (!data.name || !data.end_date) {
          this.$toast({ desc: '领取成功，请刷新页面' })
        }

        const lastUserInfo = { ...this.userInfo, isVip: true, privliegeName: data.name, privliegeEndTime: data.end_date }
        this.$store.commit('setUserInfo', lastUserInfo)
        CacheSetUserInfo(lastUserInfo)
        ClientSetUserInfo(lastUserInfo)
      })
    },
  }
}
</script>

<style lang="scss">
.freeVip{
  .common_dialog { background: url(~@/assets/images/bg_freevip.png) no-repeat;}
  .header { background: url(~@/assets/images/bg_freevip_header.png) no-repeat;}
  .close_btn,p { color: #fff !important;}
  .icon_logo { background: url(~@/assets/images/logo_mini_white.png);}
}
.freeVip_dialog { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; color: #666;
  .freeVip_title { position: relative; top: -20px; font-size: 18px; font-weight: bold;
    span { color: #EF422C;}
  }
  .freeVip_title2 { position: relative; top: -10px; font-size: 14px; letter-spacing:2px; opacity: 0.8; }
  .first_btn { width: 82px; margin-right: 50px; }
  .freeVip_btn { position: relative; top: 10px;}
  .btn_green { color: #fff; background: #36CA60; border-color: #36CA60;
    &:hover { color: #fff; background-color: #3DDC6A;}
    &.active { background-color: #30B957 !important; border-color: #30B957 !important; }
  }
  .btn_grey{ color: #666; background-color: #fff; border-color: #666; opacity: 0.4;
    &:hover{ opacity: 0.6;}
    &.active { background: #fff !important; border-color: #666 !important; opacity: 0.8; }
  }
}
</style>
