export const RetCode = {
  50013: '普通用户',
  50010: '当前用户无权限',
  50030: '找不到该素材',
  50032: '当前下载次数超额',
  5902005: '找不到用户',
  5902006: '此用户已绑定，请更换原系统用户',
}

export const limitCode = '50032' // 下载次数超出限制
export const limitCodeByPay = '50010' // 非免费模板

export const freeVipState = {
  ckickBtn: 1, // 未登录点击领取会员
  suc: 2, // 领取成功
  isvip: 60002, // 已是会员
  closed: 60001, // 活动关闭
  getSuc: 2, // 领取成功弹框
  getFail: 3 // 领取失败弹框
}

export const buyPackages = { // 支付窗活动套餐
  close: false, // 默认关闭
  open: 'client', // 默认普通终身会员活动套餐开启
  actYuanjia: 'act-yuanjia', // 终身原价套餐
  actDubaMember: 'act-duba-member', // 毒霸终身会员活动套餐
  actDubaCoin: 'act-duba-coin', // 毒霸积分终身活动套餐
}
