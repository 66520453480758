const isSuportLocalStorage = () => typeof localStorage !== 'undefined'

const EXPIRES = 'EXPIRES'
const DATA = 'DATA'

/**
 * chrome最新版，same-site不允许iframe写入cookies， 所以使用localstorage模式cookie
 */
export default {
  // cookies.get(key)
  /** 获取时，要判断是否过期，过期则自动删除，并返回null */
  get: (key) => {
    if (!isSuportLocalStorage()) return null
    const val = localStorage.getItem(key)

    if (!val) return null

    const parseVal = JSON.parse(val)

    if (parseVal[EXPIRES]) {
      if (Number(parseVal[EXPIRES]) > Date.now()) {
        return parseVal[DATA]
      }
      localStorage.removeItem(key)
      return null
    }

    return parseVal[DATA]
  },

  // cookies.set(key, val, { expires: 365 }), 单位：天
  /** 第三参数：{expires:1}，整数，最小1 */
  set: (key, val, expand = {}) => {
    if (!isSuportLocalStorage() || !key) return
    const expiresVal = { [DATA]: val }

    if (expand.expires) {
      expiresVal[EXPIRES] = 24 * 60 * 60 * 1000 * expand.expires + Date.now()
    }

    localStorage.setItem(key, JSON.stringify(expiresVal))
  },

  // cookies.remove(key)
  remove: (key) => {
    if (!isSuportLocalStorage()) {
      throw new TypeError('not support localStorage')
    }
    localStorage.removeItem(key)
  }
}
