<template>
  <DialogC
    v-model="freevipMessage.freevipMessageDialog" size="mini" class="freeVip_message"
    @close="freeVipMessageClose(freevipMessage.freevipMessageState)">
    <div class="freeVip_message_dialog">
      <h5 v-if="freevipMessage.freevipMessageState === freeVipState.isvip" v-reportView:tips="{ tipstype: 3, actiontype: 1, click: '', ext: '' }" class="freeVip_message_title">您已是会员，不可参加该活动</h5>
      <h5 v-else-if="freevipMessage.freevipMessageState === freeVipState.suc" v-reportView:tips="{ tipstype: 2, actiontype: 1, click: '', ext: '' }" class="freeVip_message_title">3日体验会员领取成功！</h5>
      <h5 v-else class="freeVip_message_title">领取失败</h5>

      <ComButton
        v-if="freevipMessage.freevipMessageState === freeVipState.suc"
        size="big" type="hover" class="freeVip_message_btn"
        @click="freeVipMessageClose(freeVipState.suc)">立即体验</ComButton>
      <ComButton
        v-else
        size="big" type="hover" class="freeVip_message_btn"
        @click="freeVipMessageClose">关闭</ComButton>
    </div>
  </DialogC>
</template>

<script>
import DialogC from '@/components/DialogC/index.vue'

import { report } from '@/report/index.js'
import { freeVipState } from '@/api/bussinessCode.js'

export default {
  name: 'ReceiveMemberTipDialog',

  components: { DialogC },

  computed: {
    freeVipState () { // 领取会员信息弹框状态
      return freeVipState
    },

    freevipMessage () { // 领取信息弹框状态
      return this.$store.state.tipsDialog.freevipMessage
    },
  },

  methods: {
    freeVipMessageClose (state) { // 关闭领取消息窗口
      const clickType = state === freeVipState.suc ? freeVipState.getSuc : freeVipState.getFail
      report('tips', { tipstype: clickType, actiontype: 2, click: 3, ext: '' })
      this.$store.commit('tipsDialog/changeFreevipMessage', { freevipMessageDialog: false })
    },
  }
}
</script>

<style lang="scss" scoped>
.freeVip_message_dialog { position: absolute; top: 0; right: 0; bottom: 0; left: 0; padding-top: 35px; text-align: center;
    .freeVip_message_title { position: relative; top: 0px; font-size: 12px; color: $primary_color; text-align: center; }
    .freeVip_message_btn { position: relative; top: 30px; }
  }
</style>
