
import { getChannel } from '@/common/cache.js'
import { channelMap } from '@/common/const.js'
// 解析url

export const parseURL = (url) => {
  const a = document.createElement('a')
  a.href = url
  return {
    source: url,
    protocol: a.protocol.replace(':', ''),
    host: a.hostname,
    port: a.port,
    query: a.search,
    params: (function () {
      const ret = {}
      const seg = a.search.replace(/^\?/, '').split('&')
      const len = seg.length; let i = 0; let s
      for (;i < len; i++) {
        if (!seg[i]) { continue }
        s = seg[i].split('=')
        ret[s[0]] = s[1]
      }
      return ret
    })(),
    file: (a.pathname.match(/([^/?#]+)$/i) || ['', ''])[1],
    hash: a.hash.replace('#', ''),
    path: a.pathname.replace(/^([^/])/, '/$1'),
    segments: a.pathname.replace(/^\//, '').split('/')
  }
}

/** get 渠道名称，只有毒霸和驱动精灵 */
export const getChannelName = () => {
  const type = getChannel()
  return channelMap?.[type] || ''
}
