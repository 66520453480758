<template>
  <div class="main_layout_box">
    <Header class="header_box" />
    <div class="content_box">
      <Aside class="aside_box" />
      <router-view class="router_content" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/index.vue'
import Aside from '@/components/Aside/index.vue'

export default {
  name: 'MainLayout',

  components: { Header, Aside }
}
</script>

<style lang="scss">
.main_layout_box { display: flex; flex-direction: column; height: 100%; overflow: hidden;
  .header_box { box-sizing: border-box; height: 50px; background-color: #fff; border-bottom: 1px solid $border_color; }

  .content_box { position: relative; display: flex; flex: 1; overflow: hidden; }
  .aside_box { box-sizing: border-box; width: 70px; border-right: 1px solid $border_color; }
  .router_content { position: absolute; top: 0; right: 0; bottom: 0; left: 70px; flex: 1; overflow: auto; }
}
</style>
