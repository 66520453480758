<template>
  <span class="common_collect" :class="{ active }" :style="{'height': `${height}px`, 'width': `${width}px`}" />
</template>

<script>
export default {
  name: 'IconClose',

  props: {
    width: {
      type: Number,
      default: 14
    },

    height: {
      type: Number,
      default: 12
    },

    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .common_collect { display: inline-block; cursor: pointer; background-image: url(~@/assets/images/icon_collect.png); background-size: 100% 100%;
    &.active { background-image: url(~@/assets/images/icon_collect_active.png); }
  }
</style>
