<template>
  <div class="user_info">
    <img :src="userInfo.avatar_url" class="avatar">
    <div class="text_container">
      <p class="name">账户名称：{{ userInfo.username }}</p>
      <p class="privliege">身份：{{ userInfo.privliegeName }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',

  computed: {
    userInfo () {
      return this.$store.state.userInfo
    }
  },
}
</script>

<style lang="scss" scoped>
.user_info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 182px;
  height: 61px;
  background: #FFFFFF;
  border-radius: 8px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.text_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 8px;
  font-size: 12px;
  color: #333333;
  .name {
    margin-bottom: 6px;
    font-weight: bold;
  }
}
</style>
