<template>
  <div v-if="isOpen" class="vip_activity_dialog_box">
    <div class="dialog_box">
      <IconClose class="close_btn" @click.native="close" />
      <img :src="banner" alt="" class="vip_activity_top_img">
      <div class="vip_activity_top_word">
        <span class="top_word">官方认证</span>
        <span class="top_word">每日上新</span>
        <span class="top_word">办公模板免费下载</span>
      </div>
      <!-- 倒计时 -->
      <div class="vip_activity-countdown">
        <div class="vip-countdown">
          距离今天活动结束：
        </div>
        <div class="vip-activity-time hour">{{ h }}</div>
        <div class="vip-activity-time">
          时
        </div>
        <div class="vip-activity-time m">{{ m }}</div>
        <div class="vip-activity-time">
          分
        </div>
        <div class="vip-activity-time s">{{ s }}</div>
        <div class="vip-activity-time">
          秒
        </div>
        <div class="vip-activity-time ms">{{ ms }}</div>
        <div class="vip-activity-time">
          毫秒
        </div>
      </div>
      <!-- 活动信息展示 -->
      <div class="vip-activity-pay-box">
        <div class="vip-activity-pay-img1">
          <img src="~@/assets/images/icon_activity2.png" class="vip-activity-pay-img2" alt="activity img">
        </div>
        <div class="vip-activity-pay-price">
          原价：￥{{ buyList[0] ? computedPrice(buyList[0].price) : '' }}
          <img src="~@/assets/images/icon_activity3.png" class="vip-activity-pay-price-img" alt="activity img">
        </div>
        <div class="vip-activity-pay-realprice-box">
          <div class="vip-activity-pay-realprice">
            <span class="vip-activity-realprice">{{ buyList[0] ? computedPrice(buyList[0].real_price) : '' }}</span><span class="vip-activity-realprice-words">元/终身</span>
            <img src="~@/assets/images/icon_activity4.png" class="vip-activity-pay-realprice-img" alt="activity img">
          </div>
          <span class="vip-activity-info">无限下载办公模板</span>
        </div>
        <div class="vip-activity-pay-payprice">
          应付金额<span>{{ buyList[0] ? computedPrice(buyList[0].real_price) : '' }}</span>元
        </div>
      </div>
      <!-- qrcode -->
      <div class="vip-activity-qrcode-box">
        <div class="vip-activity-qrcode">
          <div class="qrcode_item wechat">
            <qrcode-vue
              v-show="wechatQr" class="qr" :value="wechatQr" level="H"
              :size="100"
            />
            <div class="qrWord wechatWord">微信支付</div>
          </div>
          <div class="qrcode_item zfb">
            <qrcode-vue
              v-show="aliQr" class="qr" :value="aliQr" level="H"
              :size="100"
            />
            <div class="qrWord aliWord">支付宝支付</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import IconClose from '@/components/Icons/IconClose.vue'

import { floatMul } from '@shared/utils/base/src/number.js'
import { ClientSetUserInfo } from '@/common/clientFunc.js'
import { reportServerId } from '@/common/business.js'
import { CacheSetUserInfo, getSource } from '@/common/cache.js'
import { buyPackages } from '@/api/bussinessCode.js'
import api from '@/api'
import { report } from '@/report/index.js'

const payapp = 'loveoffice'
const payStatus = {
  success: 'paid',
  wait: 'paying',
  refunding: 'refunding',
  refund: 'refund',
  fail: 'fail'
}

export default {
  name: 'VipActivityDialog',

  components: { IconClose, QrcodeVue },

  data () {
    return {
      buyList: [],
      activePrepareId: null, // 后台统一服务，获取套餐的预支付product_id
      activeIndex: 0,
      wechatQr: '',
      aliQr: '',
      countdownEndtime: 0,
      h: '',
      m: '',
      s: '',
      ms: '',
      serviceTime: 0,
      banner: '',
      confBanner: '', // 活动请求banner
      confPackage: '', // 活动请求套餐
      closePayExt: '', // 关闭时上报
      payResultExt: '', // 支付上报区分
      isOpen: false, // 活动支付窗控制开关
      openState: '', // 活动支付窗开关状态
      payShowExt: '' // 支付窗展示上报
    }
  },
  mounted () {
    this.initActivityType()
    this.reportShow()
    this.countdownEnd()
    this.getPackages()
    this.getBanner()
  },
  methods: {
    initActivityType () { // 根据活动状态调整活动参数
      switch (this.$store.state.buyDialogType) {
        case buyPackages.actDubaMember: // 毒霸会员活动
          this.confBanner = 'vipActivityBuyBanner'
          this.confPackage = buyPackages.actDubaMember
          this.closePayExt = 6
          this.payResultExt = 2
          this.payShowExt = 5
          this.openState = 'vipActivityBuyState'
          break

        case buyPackages.actDubaCoin: // 毒霸积分活动
          this.confBanner = 'miniDubaPointsBanner'
          this.confPackage = buyPackages.actDubaCoin
          this.closePayExt = 10
          this.payResultExt = 6
          this.payShowExt = 9
          break

        default: // 普通用户活动
          this.confBanner = 'activityBuyBanner'
          this.confPackage = 'client'
          this.closePayExt = 8
          this.payResultExt = 4
          this.payShowExt = 3
          this.openState = 'activityBuyState'
          break
      }
      this.showDialog()
    },

    reportShow () {
      report('pay', { actiontype: 1, ext: this.payShowExt })
    },

    showDialog () {
      api.common.config({ data: { conf_code: this.openState } }).then(res => { // 查询对应活动的支付窗开关
        const config = res?.data?.data?.content
        if (Number(config)) { // 支付窗打开
          this.isOpen = true
        }
      })
    },

    getPackages (defaultPackage = '') {
      const setPackage = defaultPackage === '' ? this.confPackage : defaultPackage
      api.member.packages({ data: { chan: setPackage } }).then(res => {
        const packages = res?.data?.pri_packages || []
        this.buyList = packages.slice(0, 1)

        this.startPay()
      })
    },

    computedPrice (price) {
      return floatMul(price, 0.01)
    },

    close () {
      report('pay', { ext: this.closePayExt })
      this.$store.commit('changeVipActivityDialogVisible', false)
      this.clearTimer()
    },

    getBanner () {
      api.common.config({ data: { conf_code: this.confBanner } }).then(res => {
        const config = res?.data?.data?.content
        if (config) {
          this.banner = config
        }
      }).catch(() => {
        this.$toast({ desc: '获取banner失败，请刷新重试' })
      })
    },

    // 获取countdown结束时间
    countdownEnd () {
      api.common.getServiceTime().then(res => {
        const nowTime = res?.data?.timestamp
        if (!nowTime) {
          this.$toast({ desc: '倒计时加载失败，请刷新重试' })
          return
        }
        this.serviceTime = nowTime * 1000
        const endDate = new Date(this.serviceTime).setHours(23, 59, 59, 999)
        this.countdownEndtime = endDate
        this.countTime()
      })
    },
    startPay () {
      this.getPrepare(1) // 微信
      this.getPrepare(2) // 支付宝
    },
    getPrepare (type) {
      const source = getSource()

      // 需要根据套餐获取产品id
      api.member.prepare({ data: { biztype: 1, paytype: type, id: this.buyList[0].id, source } }).then(res => {
        const id = res?.data?.product_id

        if (!id) {
          this.$toast({ desc: '获取套餐信息失败，请刷新重试或联系客服' })
          return
        }

        type === 1 ? this.getQrcode(id, type) : this.getAliQrcode(id, type)
      })
    },
    getQrcode (id, type) {
      api.member.wxPay({ data: { payapp, product_id: id } }).then(res => {
        const data = res.data

        if (!data || !data.order_id || !data.wx_resp || !data.wx_resp.code_url) {
          this.$toast({ desc: '获取支付二维码失败，请刷新重试或联系客服' })
          return
        }

        this.wechatQr = data.wx_resp.code_url

        // 执行结果轮询
        this.getPayResult(data.order_id, type)
      }).catch(() => {
        this.$toast({ desc: '获取微信二维码失败，请刷新重试或联系客服' })
      })
    },
    getAliQrcode (id, type) {
      api.member.aliPay({ data: { payapp, product_id: id } }).then(res => {
        const data = res.data

        this.aliQr = data.ali_resp.code_url

        // 执行结果轮询
        this.getPayResult(data.order_id, type)
      }).catch(() => {
        this.$toast({ desc: '获取支付宝二维码失败，请刷新重试或联系客服' })
      })
    },

    payFail () {
      this.$toast({ desc: '支付失败，请刷新重试或联系客服' })
      this.clearTimer()
    },

    clearTimer () {
      clearInterval(this.Timer1)
      clearInterval(this.Timer2)
    },

    getPayResult (id, type) {
      clearInterval(this[`Timer${type}`])
      this[`Timer${type}`] = setInterval(() => {
        api.member.payQuery({ data: { payapp, order_id: id } }).then(res => {
          const data = res.data

          if (!data || !data.order || !data.order.status) {
            this.payFail()
            this.clearTimer()
            return
          }

          if (data.order.status === payStatus.fail) {
            this.payFail()
            this.clearTimer()
            return
          }

          // 其他状态暂不处理
          if (data.order.status === payStatus.success) {
            const order = data.order
            report('payresult', { order_id: id, order_time: order.pay_time, order_num: order.fee, order_package: this.buyList[0].name, order_paytype: type === 1 ? 'weixin' : 'alipay', ext: this.payResultExt })
            reportServerId()
            this.resetUserInfo()
          }
        })
      }, 3000)
    },

    resetUserInfo () {
      api.user.privliege().then(res => {
        const data = res.data
        if (!data.name || !data.end_date) {
          this.$toast({ desc: '支付已经成功，请刷新页面' })
        }
        const lastUserInfo = { ...this.userInfo, isVip: true, privliegeName: data.name, privliegeEndTime: data.end_date }

        this.$store.commit('setUserInfo', lastUserInfo)
        CacheSetUserInfo(lastUserInfo)
        ClientSetUserInfo(lastUserInfo)
        this.$store.dispatch('initBuyBtn') // 支付后终身会员隐藏成为会员按钮
        this.close()
      }).catch(() => {
        this.$toast({ desc: '支付已经成功，请刷新页面或者退出重新登录' })
      }).finally(() => {
        this.clearTimer()
      })
    },
    // 时间倒计时处理
    countTime () {
      const now = this.serviceTime
      this.serviceTime += 50
      const leftTime = this.countdownEndtime - now
      if (leftTime >= 0) {
        let hour = ''
        let minute = ''
        let second = ''
        let millisecond = ''
        const a = leftTime / 1000
        hour = Math.floor(a / 60 / 60)
        minute = Math.floor(a / 60 % 60)
        second = Math.floor(a % 60)
        millisecond = Math.floor(leftTime % 1000)
        this.h = hour < 10 ? '0' + hour : hour
        this.m = minute < 10 ? '0' + minute : minute
        this.s = second < 10 ? '0' + second : second
        this.ms = millisecond < 100 ? '0' + millisecond : millisecond
        setTimeout(this.countTime, 50)
        return
      }
      // 倒计时截止重新获取结束时间
      this.$store.commit('changeVipActivityDialogVisible', false) // 隐藏弹窗
      this.clearTimer()
    }
  },
}
</script>
<style lang="scss" scoped>
$that_primary: #4a96e8;
.vip_activity_dialog_box { position: fixed; top: 0; z-index: 14; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.4);
  .dialog_box{ position: relative; top: 20%; width: 766px; height: 450px; margin: 0px auto; background-color: #fff; border-radius: 0px 0px 8px 8px; }
  .close_btn{ position: absolute; top: 10px; right: 10px; z-index: 15; }
  .vip_activity_top_img{ position: absolute; top: -101px; left: 0px; z-index: 14; width: inherit; }
  .vip_activity_top_word{ position: relative; top: 10px;  width: fit-content; height: 15px; margin: 0px auto; line-height: 15px; }
  .top_word{ margin-right: 20px; font-size: 14px; font-weight: bold; color: #5B0902; opacity: 0.6;
     &::before { position: relative; top: -3px; left: -5px; font-size: 25px; content: '.'; }
  }
  .vip_activity-countdown{ position: relative; top: 28px; width: 364px; height: 36px; margin: 0px auto; background: linear-gradient(90deg, #FFB86B, #FF0036, #FF1971); border-radius: 8px; }
  .vip-countdown{ float: left; margin-left: 8px; font-size: 12px; line-height: 36px; color:#fff; }
  .vip-activity-time{ float: left; margin-left: 3px; font-size: 12px; line-height: 36px; color: #FFF; }
  .hour,.m,.s,.ms{ width: 40px; height: 18px; margin-top: 8.5px; font-weight: bold; line-height: 18px;  color: rgba(255, 0, 63, 1); text-align: center; background: #fff; border-radius: 4px; }
  .vip-activity-pay-box{ position: relative; top: 40px; width: 362px; height: 152px; margin: 0px auto; overflow: hidden; background-color: RGBA(255, 247, 245, 1); border: 1px solid rgba(255, 54, 2, 1); border-radius: 8px; }
  .vip-activity-pay-img1 { position: absolute; top: 0px; right: 0px; width: 40px; height: 40px; background-image: url(~@/assets/images/icon_activity.png); }
  .vip-activity-pay-img2 { position: absolute; top: 3px; right: 3px; }
  .vip-activity-pay-price { position: relative; top: 21px; width: 138px; height: 14px; margin: 0px auto; font-size: 12px; line-height: 14px; color: rgba(91, 9, 2, 1); text-align: center; opacity: 0.6; }
  .vip-activity-pay-price-img { position: absolute; top: 6px; left: 0px; width: inherit; }
  .vip-activity-pay-realprice-box{ position: relative; top: 30px; width: 302px; height: 67px; margin: 0px auto; font-size: 12px; color: #ff003f; text-align: center; }
  .vip-activity-pay-realprice{ position: relative; width: 302px; height: 50px; margin: 0px auto; font-size: 18px; line-height: 25px; color: rgba(91, 9, 2, 1); text-align: center; }
  .vip-activity-realprice-words{ font-weight: bold; opacity: 0.6; }
  .vip-activity-realprice{ font-size: 38px; font-weight: bold; color: #FF003F; opacity: 1; }
  .vip-activity-pay-box-word{ display: block; margin-top: 3px; }
  .vip-activity-pay-payprice{ position: absolute; bottom: 0px; width: inherit; height: 22px; font-size: 12px; line-height: 22px; color: #5B0902; text-align: center; background: #FFE8E2; border-top:1px solid  rgba(255, 54, 2, 1); }
  .vip-activity-pay-payprice span{ color: #FF003F; }
  .vip-activity-qrcode-box { position: relative; top:55px; width: 364px; height: 171px; margin: 0px auto; border: 1px solid #EEE6E5; border-radius: 8px;  }
  .vip-activity-qrcode { width: 280px; height: 133px; margin: 0px auto; margin-top: 17px; }
  .vip-activity-info { position: relative; top: 5px; font-size: 12px; color: #FF003F; }
  .qrcode_item { position: relative; width: 113px; height: inherit; text-align: center; }
  .wechat { float: left;
    .qr { border: 2px solid #68c978; }
  }
  .zfb { float: right;
    .qr { border: 2px solid #00a0e9; }
  }
  .qr { box-sizing: border-box; width: 113px; height: 113px; padding: 4px; margin-bottom: 8px; background-repeat: no-repeat; background-position: center center; background-size: 100px 100px; border-radius: 4px; }
  .qrWord { position: absolute; bottom: 0px; font-size: 14px; color: #666; }
  .wechatWord { text-indent: 16px;
    &::before{ position: relative; top: 3px; left: -2px; display: inline-block; width: 20px; height: 17px; content: ''; background: url(~@/assets/images/icon_activity_wechat.png) no-repeat; background-size: 100%; }
  }
  .aliWord { text-indent: 15px;
    &::before{ position: relative; top: 4px; left: -4px; display: inline-block; width: 17px; height: 18px; content: ''; background: url(~@/assets/images/icon_activity_alipay.png) no-repeat; background-size: 100%; }
  }
  .duba_red_packet { margin-top: 5px; font-size: 14px; color: #5B0902;
    span { font-weight: bold; }
    .checkbox { width: 12px; height: 12px; background-image: none; border: 1px solid #5B0902; border-radius: 4px; opacity: 0.8; }
    .active { margin-top: -2px; background-image: url(~@/assets/images/icon_activity_checkbox_hover.png); background-size: 100%; }
  }
  .duba_red_packet_words { font-size: 14px; color: #FF003F; }
}
</style>
