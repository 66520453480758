import api from '@/api'
import { RetCode } from '@/api/bussinessCode.js'
import { ClientGetSvrId, ClientSetUserInfo } from '@/common/clientFunc.js'
import { CacheGetUserInfo, CacheSetUserInfo, CacheSetCoupon } from '@/common/cache.js'

/** 获取本地svrid并上报服务端 **/
export const reportServerId = () => {
  const userInfo = CacheGetUserInfo() || {}

  if (!userInfo.token) {
    console.error('上报svrid，本地缓存用户信息需要有token')
    return
  }

  ClientGetSvrId((res) => {
    const serverId = res?.svrid
    if (serverId) {
      const params = { svrid: serverId }
      api.user.serverIdReport({ data: params })
    }
  })
}

/** 设置新的优惠卷 **/
export const setNewCoupon = async ($store) => {
  const now = await api.common.getServiceTime().then(res => res.data.timestamp * 1000)
  const todayEnd = new Date(now).setHours(23, 59, 59, 999)

  const oneDay = 24 * 60 * 60 * 1000
  const endAfterThreeDay = todayEnd + oneDay * 3

  CacheSetCoupon(endAfterThreeDay)
  $store.dispatch('updateCouponState')
}

/** 从服务端更新用户权益 **/
export const updatePrivliege = ($store) => {
  const userInfo = $store.state.userInfo
  let privliegeName = ''
  let privliegeEndTime = ''
  let isVip = false

  return api.user.privliege({ data: { common: { token: userInfo.token, uid: userInfo.uid } } }).then(pRes => {
    privliegeName = pRes.data.name
    privliegeEndTime = pRes.data.end_date
    isVip = true
  }).catch(data => {
    if (data?.ret && data.ret.toString() in RetCode) {
      privliegeName = RetCode[data.ret]
    }
  }).finally(() => {
    const lastUserInfo = Object.assign({}, userInfo, { privliegeName, privliegeEndTime, isVip })
    $store.commit('setUserInfo', lastUserInfo)
    CacheSetUserInfo(lastUserInfo)
    ClientSetUserInfo(lastUserInfo)
  })
}
