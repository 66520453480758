/**
 * @description 计算浮点加法结果
 * @param args{...number}
 * @returns {number}
 */
export const floatAdd = function (...args) {
  if (!args.length) {
    return 0
  }
  const remainderArray = args.map((arg) => {
    try {
      return arg.toString().split('.')[1].length
    } catch (e) {
      return 0
    }
  })
  remainderArray.sort()
  if (remainderArray[remainderArray.length - 1] === 0) {
    return args.reduce((result, arg) => {
      return arg + result
    }, 0)
  }
  const powBase = 10
  const mulriple = Math.pow(powBase, remainderArray[remainderArray.length - 1])
  return args.reduce((result, arg) => {
    return arg * mulriple + result
  }, 0) / mulriple
}

/**
 * @description 计算浮点相减结果
 * @param args{...number}
 * @returns {number}
 */
export const floatSub = function (...args) {
  if (!args.length) {
    return 0
  }
  // eslint-disable-next-line sonarjs/no-identical-functions
  const remainderArray = args.map((arg) => {
    try {
      return arg.toString().split('.')[1].length
    } catch (e) {
      return 0
    }
  })
  remainderArray.sort()
  if (remainderArray[remainderArray.length - 1] === 0) {
    return args.reduce((result, arg, index) => {
      if (index === 0) {
        return arg
      }
      return result - arg
    }, 0)
  }
  const powBase = 10
  const mulriple = Math.pow(powBase, remainderArray[remainderArray.length - 1])
  const length = remainderArray[remainderArray.length - 1]
  const result = args.reduce((result, arg, index) => {
    if (index === 0) {
      return arg * mulriple
    }
    return result - arg * mulriple
  }, 0) / mulriple
  return Number.parseFloat(result.toFixed(length))
}

/**
 * @description 计算浮点相除结果
 * @param args{...number}
 * @returns {number}
 */
export const floatMul = function (...args) {
  if (!args.length) {
    return 0
  }
  // 小数点的总长度
  let length = 0
  args.forEach((arg) => {
    try {
      length += arg.toString().split('.')[1].length
    } catch (e) {
      // eslint-disable-next-line no-unused-vars
      length += 0
    }
  })
  if (length === 0) {
    return args.reduce((result, arg, index) => {
      if (index === 0) {
        return arg
      }
      return result * arg
    }, 0)
  }
  const powBase = 10
  const result = args.reduce((result, arg, index) => {
    if (index === 0) {
      return Number(arg.toString().replace('.', ''))
    }
    return result * Number(arg.toString().replace('.', ''))
  }, 0)
  return result / Math.pow(powBase, length)
}

/**
 * @author 张城
 * @description 数字格式化
 * @param {number} num  数字
 * @param {number} decimals  小数位
 * @returns {string}
 */
export const numberFormatter = (num, decimals = 0) => {
  if (typeof num !== 'number') throw new Error('please enter a number')

  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]

  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      // eslint-disable-next-line
      return (num / si[i].value).toFixed(decimals).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }

  return num.toString()
}
