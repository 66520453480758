<script>
const classMap = {
  base: 'cf-btn',
  link: '-link'
}

export default {
  name: 'CfButton',

  props: {
    baseClass: {
      type: String,
      default: classMap.base
    }
  },

  computed: {
    classList () {
      return {
        [this.baseClass]: true,
        [this.baseClass + classMap.link]: this.$attrs.href
      }
    }
  },

  render (h) {
    const { $attrs, classList, $listeners, $slots } = this
    return h($attrs.href ? 'a' : 'button', {
      attrs: $attrs,
      class: classList,
      on: $listeners
    }, $slots.default)
  }
}
</script>
