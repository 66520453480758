import Vue from 'vue'
import VueRouter from 'vue-router'

import MainLayout from '@/components/Layout/MainLayout.vue'
import Classify from '@/views/Classify/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/classify'
  },
  {
    path: '/classify',
    name: 'classify',
    component: MainLayout,
    children: [
      {
        path: '',
        component: Classify
      }
    ]
  },
  {
    path: '/search',
    name: 'search',
    component: MainLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "searchPage" */ '@/views/SearchPage/index.vue')
      }
    ]
  },
  {
    path: '/detail',
    name: 'detail',
    component: MainLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "detail" */ '@/views/Detail/index.vue')
      }
    ]
  },
  {
    path: '/customization',
    name: 'customization',
    component: MainLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "customMade" */ '@/views/Customization/index.vue')
      }
    ]
  },
  {
    path: '/my',
    name: 'my',
    component: MainLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "my" */ '@/views/My/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
