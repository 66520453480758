<template>
  <div class="search_box">
    <div class="search">
      <input
        v-model="val" class="input" maxlength="10" placeholder="请输入"
        @focus="showDialog" @blur="closeDialog" @input="closeDialogByInput" @keydown.enter="searchHandle">
      <span class="btn" @click="searchHandle" />
    </div>

    <!-- 下拉列表 -->
    <ul v-show="recommendVisible && recommendList.length" class="recommend_box">
      <li
        v-for="(item, index) in recommendList" :key="index" class="recommend_item"
        @click="pushTarget(item)">
        <h3>{{ index + 1 }}</h3>
        <p>{{ item }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import api from '@/api'
import { Keyword } from '@/common/const.js'
import { report } from '@/report/index.js'

const search = '/search'

export default {
  name: 'Search',

  data () {
    return {
      recommendVisible: false,
      val: ''
    }
  },

  computed: {
    recommendList () {
      return this.$store.state.search.recommendList
    }
  },

  created () {
    this.getConfig()
  },

  methods: {
    getConfig () {
      // 下拉热词
      api.common.config({ data: { conf_code: 'miniHotSearch' } }).then(res => {
        const dataStr = res?.data?.data?.content || ''
        this.$store.commit('changeSearch', { recommendList: dataStr.split(',').slice(0, 10) })
      })

      const keyword = this.getQueryKeyword()

      // 默认值
      if (!keyword || !this.isSearchPage()) { // 没有key
        api.common.config({ data: { conf_code: 'mini-defaultSearch' } }).then(res => {
          this.val = res?.data?.data?.content || ''
          this.$store.commit('changeSearch', { searchVal: this.val })
        })
        return
      }

      this.val = keyword
      this.$store.commit('changeSearch', { searchVal: keyword })
    },

    isSearchPage () {
      return this.$route.path === search
    },

    getQueryKeyword () {
      return decodeURIComponent(this.$route.query[Keyword] || '')
    },

    searchHandle () {
      if (this.isSearchPage() && this.getQueryKeyword() === this.val) { return }

      this.pushTarget()
    },

    pushTarget (val) {
      if (val === this.getQueryKeyword()) { return }

      const targetVal = val || this.val

      // 下拉热词要覆盖输入框内容
      val && (this.val = val)

      report('search', { posid: val ? 2 : 1, word: this.val, ext: '' })

      this.$store.commit('changeSearch', { searchVal: targetVal })
      this.$router.push(`/search?${Keyword}=${encodeURIComponent(targetVal)}`) // 提供给searchPage检测
    },

    showDialog () {
      this.recommendVisible = true
    },

    closeDialogByInput () {
      this.recommendVisible = false
    },

    closeDialog () {
      // a标签触发优先级低于blur
      setTimeout(() => {
        this.recommendVisible = false
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
.search_box { position: relative; width: 180px; margin-right: 10px;
  .search { position: relative;
    .input { box-sizing: border-box; width: 100%; height: 24px; padding: 0 34px 0 10px; font-size: 12px; font-weight: 500; color: #888; background-color: #fff; border: 1px solid #D9D9D9; border-radius: 12px; outline: none; }
    .btn { position: absolute; top: 5px; right: 10px; width: 14px; height: 14px; content: ''; background: url(~@/assets/images/icon_search.png) 14px 14px; }
  }

  .recommend_box { position: absolute; top: 30px; left: 10px; z-index: 1; width: 160px; background-color: #fff; border: 1px solid #D9D9D9; border-radius: 4px; box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.1);
    .recommend_item { display: flex; align-items: center; height: 28px; padding: 0 10px; font-size: 12px; font-weight: 400; line-height: 28px; color: #444;
      &:hover { color: #fff; background-color: #4A96E8; }
      &:nth-child(1) { h3 { background-color: #FA6363; } }
      &:nth-child(2) { h3 { background-color: #FF9336; } }
      &:nth-child(3) { h3 { background-color: #FCC957; } }

      h3 { display: flex; align-items: center; justify-content: center; width: 16px; height: 16px; margin-right: 10px; font-size: 12px; font-weight: 400; color: #fff; background-color: #D7D7D7; border-radius: 4px; }
    }
  }
}
</style>
