<template>
  <div :class="['user-info-box', { vip: isVip }]">
    <div class="content">
      <div class="head">
        <div>ID: {{ userInfo.uid }}</div>
        <div
          v-report:mainclick="{ area: 'account', pos:'1', ext: '' }" class="login-out"
          @click="loginOut">退出登录</div>
      </div>
      <div class="info">
        <img
          :src="userInfo.avatar_url"
          class="user_avatar">
        <div class="right">
          <div class="name_container">
            <span class="name">{{ userInfo.username }}</span>
            <i class="icon" />
          </div>
          <div class="privliege">
            <span>身份：{{ userInfo.privliegeName }}</span>
            <span v-if="isVip" class="end_time">{{ userInfo.privliegeEndTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { CacheRemoveUserInfo } from '@/common/cache.js'
import { ClientRemoveUserInfo } from '@/common/clientFunc.js'

export default {
  name: 'UserInfoBox',

  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },

    isVip () {
      return this.$store.state.userInfo.isVip
    }
  },

  methods: {
    loginOut () {
      api.user.logout().then(res => {
        this.$store.commit('setUserInfo', {})
        this.$store.commit('clearCollectId')
        CacheRemoveUserInfo()
        ClientRemoveUserInfo()
        this.$store.dispatch('initBuyBtn') // 成为会员
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info-box {
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 256px;
  font-family: Microsoft YaHei;
  font-size: 12px;
  font-weight: 400;
  color: #444444;
  &::before {
    position: relative;
    top: 5px;
    width: 7px;
    height: 7px;
    content: '';
    background-color: #FFFFFF;
    border-top: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    transform: rotate(-45deg);
  }
  .content {
    box-sizing: border-box;
    width: 100%;
    padding: 11px;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.1);
    .head {
      position: relative;
      display: flex;
      justify-content: space-between;
      .login-out {
        cursor: pointer;
      }
    }

    .info {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 12px 10px;
      margin-top: 10px;
      background-image: url("~@/assets/images/bg_user_info_no_vip.png");
      background-repeat: no-repeat;
      background-size: cover;
      .user_avatar {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
      }
      .name_container {
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        font-size: 16px;
        font-weight: bold;
        .name {
          display: block;
          min-width: 20px;
          max-width: 117px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .icon {
          width: 27px;
          height: 13px;
          margin-left: 6px;
          background-image: url("~@/assets/images/icon_no_vip.png");
        }
      }
      .privliege {
        font-size: 10px;
      }
    }
  }
}

.user-info-box.vip {
  .info {
    background-image: url("~@/assets/images/bg_user_info_vip.png");
    .name_container {
      .icon {
        background-image: url("~@/assets/images/icon_vip.png");
      }
    }
    .end_time {
      margin-left: 3px;
    }
  }
}
</style>
