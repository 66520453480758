// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icon_customization_entry.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".customization_item[data-v-0286778c]{position:relative;float:left;width:222px;height:316px;font-size:0}.customization_item:hover .am_box[data-v-0286778c]{width:222px;height:314.5px;cursor:pointer;box-shadow:0 0 12px 0 rgba(0,0,0,.4);transition:all .1s linear}.customization_item .am_box[data-v-0286778c]{position:absolute;top:0;right:0;bottom:0;left:0;display:flex;flex-direction:column;width:216px;height:306px;margin:auto;overflow:hidden;text-decoration:none;vertical-align:middle;border-radius:4px;box-shadow:0 0 12px 0 rgba(0,0,0,.2)}.customization_item .am_box .item_img[data-v-0286778c]{flex:1;cursor:pointer;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover}", ""]);
// Exports
module.exports = exports;
