// 该store是所有弹框的store
export default {
  namespaced: true,
  state: () => ({
    freevipMessage: { // 领取会员弹框类型
      freevipMessageDialog: false,
      freevipMessageState: 0, // 0：未点击领取
      freeVipPackage: [], // 领取套餐
    },

    vipActivityMsgVisible: false, // 终身会员活动提示框

    connectRes: { // 关联弹框类型
      connectResDialog: false,
      isBind: true
    },

    updateVipLevelDialogVisible: false, // 升级会员弹框
  }),
  mutations: {
    changeFreevipMessage (state, obj) { // 变更领取会员提示弹框状态
      state.freevipMessage = { ...state.freevipMessage, ...obj }
    },

    changeVipActivityMsgVisible (state, flag) { // 变更终身会员活动提示框状态
      state.vipActivityMsgVisible = flag
    },

    changeConnectRes (state, obj) {
      state.connectRes = { ...state.connectRes, ...obj }
    },

    changeUpdateVipLevelDialog (state, flag = true) {
      state.updateVipLevelDialogVisible = flag
    },
  }
}
