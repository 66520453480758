<template>
  <span class="icon_logo" />
</template>

<script>
export default {
  name: 'IconLogo'
}
</script>

<style lang="scss" scoped>
.icon_logo { display: inline-block; width: 19px; height: 14px; background-image: url(~@/assets/images/logo_mini.png); background-size: 100% 100%; }
</style>
