import { randomHexString } from '@shared/utils/base/src/random.js'
import { stringifyQuery } from '@shared/utils/querystring'

import { qqProxyUrl, APPIDQQ, APPIDQQ2 } from '@/common/resources.js'

export default class qqAuth {
  constructor (config) {
    const defaultConfig = {}
    this.config = Object.assign(defaultConfig, config)
    this.state = randomHexString(16)
  }

  getState () {
    return this.state
  }

  openAuthPage () {
    const queryObj = {
      client_id: this.config.isBind ? APPIDQQ2 : APPIDQQ,
      response_type: 'code',
      redirect_uri: qqProxyUrl,
      scope: 'get_user_info',
      // display: 'mobile',
      state: this.state
    }

    return `https://graph.qq.com/oauth2.0/authorize?${stringifyQuery(queryObj)}`
  }
}
