import Vue from 'vue'
import Vuex from 'vuex'

import operational from '@/store/operational.js'
import tipsDialog from '@/store/tipsDialog.js'

import api from '@/api'
import { ClientGetDownloadList } from '@/common/clientFunc.js'
import { Page, Scroll, myIndex } from '@/common/const.js'
import { CacheGetCoupon } from '@/common/cache'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    categoryActive: 0, // 左侧一级分类
    loginDialogVisible: false, // 登录弹框
    buyDialogVisible: false, // 支付弹框
    connectDialogVisible: false, // 关联爱办公弹框
    vipActivityDialogVisible: false, // 终身会员活动弹框
    isShowActivityBtn: true, // 终身会员活动顶部入口
    buyDialogType: false, // 支付框活动状态
    isShowBuyMember: true, // 成为会员按钮
    downedList: [], // 一下载的素材列表
    downingList: [], // 正在下载的素材列表
    collectIdList: [], // 个人所有收藏id列表
    userInfo: {},
    classify: { // 分类页的数据缓存，详情页返回的定位
      level2Index: 0,
      level3Index: 0,
      [Page]: 1,
      [Scroll]: 0
    },
    isShowCusEntry: false, // 是否显示ppt定制入口
    search: {
      recommendList: [], // 推荐内容
      searchVal: '',
      active: 0,
      [Page]: 1,
      [Scroll]: 0
    },
    my: { // 我的页面数据缓存，详情页返回的定位
      level1Index: 0,
      level2Index: 0,
      [Page]: 1,
      [Scroll]: 0
    },
    matList: [], // 分类页、搜索页、我的页面数据列表
    matTotalCount: 0, // 同上
    searchList: [], // 搜索一级分类列表

    commonParams: null, // 通用params，用于detail页的左右切换功能
    activityDialogVisible: false, // 终身活动红包框
    activityNeedShow: false, // 判断用户登录前是否点击活动红包或弹框
    activityClickType: '', // 活动图点击触发类型
    hasCoupon: false,
    dialogPayAbTestType: null
  },

  mutations: {
    changeCategoryActive (state, index) {
      if (state.categoryActive !== index) {
        state.categoryActive = index
        state.classify = { ...state.classify, level2Index: 0, level3Index: 0, page: 1 }
      }
    },

    changeClassify (state, obj) {
      state.classify = { ...state.classify, ...obj }
    },

    changeMy (state, obj) {
      state.my = { ...state.my, ...obj }
    },

    changeSearch (state, obj) {
      state.search = { ...state.search, ...obj }
    },

    changeLoginDialogVisible (state, flag) {
      state.loginDialogVisible = flag
    },

    changeConnectDialogVisible (state, flag) {
      state.connectDialogVisible = flag
    },

    changeBuyDialogVisible (state, flag) {
      state.buyDialogVisible = flag
    },

    changeVipActivityDialogVisible (state, flag) { // 终身活动弹窗
      state.vipActivityDialogVisible = flag
    },

    changeShowActivityBtn (state, flag) { // 顶部活动文字链
      state.isShowActivityBtn = flag
    },

    changeBuyMemberBtn (state, flag) { // 顶部成为会员按钮
      state.isShowBuyMember = flag
    },

    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },

    changeDownedList (state, listStr) {
      if (!listStr) { return }
      state.downedList = listStr.replace(/\s+/g, '').split(',') // 去空格，再截断
    },

    pushDownedList (state, str) { // 该方法只提供给全局下载回调，添加一个完成的id，就要移除正在下载的id
      state.downedList.push(str)
      state.downingList.splice(state.downingList.indexOf(str), 1)
    },

    clearDownedList (state) {
      state.downedList = []
    },

    pushDowningList (state, idStr) { // 存储为字符串，匹配也应该为字符串
      state.downingList.push(idStr)
    },

    delDowningListById (state, idStr) {
      const index = state.downingList.lastIndexOf(idStr)
      state.downingList.splice(index, 1)
    },

    pushCollectIdList (state, idOrList) {
      if (Array.isArray(idOrList)) {
        state.collectIdList = idOrList
        return
      }
      state.collectIdList.push(idOrList)
    },

    removeCollectId (state, id) {
      const index = state.collectIdList.lastIndexOf(id)
      state.collectIdList.splice(index, 1)
    },

    clearCollectId (state) {
      state.collectIdList = []
    },

    changeMatList (state, arr) {
      state.matList = arr
    },

    changeMatTotalCount (state, num) {
      state.matTotalCount = num
    },

    changeSearchList (state, list) {
      state.searchList = list
    },

    changeCommonParams (state, params) {
      state.commonParams = params
    },

    changeCacheParams (state, obj) {
      state[obj.from] = { ...state[obj.from], ...obj.params }
    },

    changeBuyDialogType (state, flag) { // 会员支付窗状态
      state.buyDialogType = flag
    },

    changeActivityDialogVisible (state, flag) { // 通用活动弹框状态
      state.activityDialogVisible = flag
    },

    changeActivityNeedShow (state, flag) {
      state.activityNeedShow = flag
    },

    changeActivityClickType (state, flag) {
      state.activityClickType = flag
    },

    changeShowCusEntry (state, isShow) {
      state.isShowCusEntry = isShow
    },

    changeHasCoupon (state, hasCoupon) {
      state.hasCoupon = hasCoupon
    },

    changeDialogPayAbTestType (state, dialogPayAbTestType) {
      state.dialogPayAbTestType = dialogPayAbTestType
    }
  },

  actions: {
    judgeOpenMember ({ commit, getters }) {
      if (getters.isLoginedIn) {
        commit('changeBuyDialogVisible', true)
        return
      }
      commit('changeLoginDialogVisible', true)
    },

    OpenActivityVipDialog ({ commit, getters }) {
      if (getters.isLoginedIn) {
        commit('changeVipActivityDialogVisible', true)
        return
      }
      commit('changeLoginDialogVisible', true)
    },

    getActivityWindow ({ commit, getters, state }) { // 活动弹窗
      const userDate = getters.initUserDate
      if (state.buyDialogType && getters.isLoginedIn && (!getters.isVip || userDate < getters.lifeTime)) { // 登录非会员或非终身会员展示
        commit('changeVipActivityDialogVisible', true) // 展示弹窗
        commit('changeActivityNeedShow', false) // 还原点击终身活动红包或弹窗状态
        return
      }
      commit('changeVipActivityDialogVisible', false) // 隐藏弹窗
    },

    initBuyBtn ({ commit, getters }) { // 成为会员按钮状态
      const userDate = getters.initUserDate
      if (getters.isVip && userDate > getters.lifeTime) { // 终身会员隐藏支付入口
        commit('changeShowActivityBtn', false) // 隐藏顶部活动入口
        commit('changeBuyMemberBtn', false) // 隐藏按钮
        return
      }
      commit('changeBuyMemberBtn', true) // 展示按钮
      commit('changeShowActivityBtn', true) // 展示顶部活动入口
    },

    resetDownedList ({ commit }) {
      ClientGetDownloadList((downedList) => {
        commit('changeDownedList', downedList?.id_list || '')
      })
    },

    async getMat ({ dispatch, commit }, params) {
      commit('changeCommonParams', params)

      await api.material.matListV2({ data: params }).then(res => {
        commit('changeMatList', res?.data?.list || [])
        commit('changeMatTotalCount', res?.data?.total_count || 0)

        dispatch('resetDownedList')
      })
    },

    async getSearchMat ({ dispatch, commit, state }, params) {
      commit('changeCommonParams', params)

      return await api.material.searchMatList({ data: params }).then(res => {
        commit('changeMatList', res?.data?.list || [])
        commit('changeSearchList', res?.data?.cat_results || [])
        commit('changeMatTotalCount', res?.data?.cat_results?.[state.search.active]?.total || 0)

        dispatch('resetDownedList')
      })
    },

    getMyMat ({ dispatch, commit, state }, params) {
      commit('changeCommonParams', params)

      if (state.my.level1Index === 0) {
        dispatch('getMyMatByDown', params)
        return
      }
      dispatch('getMyMatByCollect', params)
    },

    getMyMatByDown ({ dispatch, commit }, params) {
      api.user.downloads({ data: params }).then(res => {
        commit('changeMatList', res?.data?.download_infos)
        commit('changeMatTotalCount', res?.data?.total || 0)

        dispatch('resetDownedList')
      })
    },

    getMyMatByCollect ({ dispatch, commit }, params) {
      api.user.favoriteList({ data: params }).then(res => {
        commit('changeMatList', res?.data?.fav_infos)
        commit('changeMatTotalCount', res?.data?.total || 0)

        dispatch('resetDownedList')
      })
    },

    getUserCollectIdList ({ commit }) {
      api.user.favoriteIds().then(res => {
        commit('pushCollectIdList', res?.data?.list || [])
      })
    },
    /**
     * @description 删除添加接口不同，所以拆解
     */
    addCollectId ({ commit }, id) {
      api.user.favoriteAdd({ data: { mat_id: id } }).then(res => {
        commit('pushCollectIdList', id)
      })
    },

    removeCollectId ({ dispatch, commit, state }, id) {
      api.user.favoriteRemove({ data: { mat_id: id } }).then(res => {
        commit('removeCollectId', id)

        // 如果是我的收藏页，还要刷新收藏页数据
        if (state.categoryActive === myIndex && state.my.level1Index === 1) {
          // 如果是最后一个素材，还需要切换到上一页
          if (state.matList.length < 2 && state.my[Page] > 1) {
            const targetPage = state.my[Page] - 1

            commit('changeMy', { [Page]: targetPage })
            commit('changeCommonParams', { ...state.commonParams, [Page]: targetPage })
          }
          dispatch('getMyMatByCollect', state.commonParams)
        }
      })
    },

    async updateCouponState ({ commit }) {
      const coupon = CacheGetCoupon()
      if (coupon) {
        const { validate } = coupon
        const now = await api.common.getServiceTime().then(res => res?.data?.timestamp * 1000)
        // 优惠券过期
        if (now > validate) {
          commit('changeHasCoupon', false)
        } else {
          commit('changeHasCoupon', true)
        }
      }
    }
  },

  getters: {
    isLoginedIn (state) {
      return !!state.userInfo.token
    },

    isVip (state) {
      return state.userInfo.isVip
    },

    lifeTime () {
      return Date.parse('2099-12-30')
    },

    initUserDate (state) { // 格式化用户时间戳
      return state.userInfo.privliegeEndTime ? Date.parse(state.userInfo.privliegeEndTime) : 0
    }
  },

  modules: { operational, tipsDialog }
})
