import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueLazyload from 'vue-lazyload'
import vuescroll from 'vuescroll'

import '@/directives/index'

import { ClientHideCover } from '@/common/clientFunc.js'
// import { redirectKey } from '@/common/const.js'
import { Toast } from '@/plugins/Toast/index.js'

import { pluginsComp } from '@/plugins/components/index.js'

Vue.config.productionTip = false

Vue.use(Toast)
Vue.use(pluginsComp)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  dispatchEvent: true
})

Vue.use(vuescroll, {
  ops: {
    mode: 'native',
    bar: {
      background: '#E5E5E5',
      keepShow: true,
      size: '4px'
    }
  } // 在这里设置全局默认配置
})

// 参考：https://github.com/cztomczak/cefpython/blob/master/api/LoadHandler.md#onloadstart
// cef内核采用onloadstart控制loading层，但是onloadstart在vue-route下会触发，得手动关闭
router.afterEach(() => {
  ClientHideCover()
})

window.app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
