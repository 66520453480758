<template>
  <div :class="['price_box', size, { selected: isSelected }]">
    <p class="name">{{ name }}</p>
    <p class="prices">
      <span class="discount_price">
        <span class="sign">￥</span>
        {{ discountPrice }}
      </span>
      <span class="price">
        <span class="sign">￥</span>
        {{ price }}
      </span>
    </p>
    <p v-if="tipMiddle" class="tip_middle">
      <span class="text">{{ tipMiddle }}</span>
    </p>
    <p v-if="isShowTipsFooter" :class="['tips_footer', { mg: isSetTipsFooterMg } ]">
      <span v-for="(item, index) in tipsFooterWithLine" :key="index" class="tip">
        {{ item }}
      </span>
    </p>
    <i class="corner_right" />
    <i class="hook" />
  </div>
</template>

<script>
const boxSize = {
  big: 'size_big',
  small: 'size_small',
}

export default {
  name: 'PriceBox',

  props: {
    name: {
      type: String,
      default: ''
    },

    price: {
      type: Number,
      default: 0
    },

    discountPrice: {
      type: [String, Number],
      default: ''
    },

    tipMiddle: {
      type: String,
      default: ''
    },

    tipsFooter: {
      type: Array,
      default: () => []
    },

    isSelected: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      default: boxSize.big
    }
  },

  data () {
    return {
      boxSize,
    }
  },

  computed: {
    isShowTipsFooter () {
      return this.tipsFooter.length > 0
    },
    tipsFooterWithLine () {
      const result = []
      this.tipsFooter.forEach((tip, index) => {
        result.push(tip)
        if (index !== this.tipsFooter.length - 1) {
          result.push('|')
        }
      })
      return result
    },

    isSetTipsFooterMg () {
      if (this.size === boxSize.big) {
        return true
      }

      if (this.size === boxSize.small && this.tipsFooterWithLine.length === 3) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.price_box {
  position: relative;
  box-sizing: border-box;
  height: 143px;
  font-family: Microsoft YaHei;
  background: #FFFBE9;
  border: 1px solid #CB8000;
  border-radius: 8px;
  &.size_big {
    width: 338px;
  }

  &.size_small {
    width: 168px;
  }
}

.price_box.selected {
  background: linear-gradient(90deg, #FFF5E8, #FFCC81);

  .name {
    font-weight: 600;
  }

  .corner_right {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-image: url("~@/assets/images/icon_corner_right.png");
  }

  .hook {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 13px;
    background-image: url("~@/assets/images/icon_hook.png");
  }
}

.name {
  width: fit-content;
  margin: 14px auto 0 auto;
  font-size: 18px;
  font-weight: 400;
  color: #553E1C;
}

.prices {
  width: fit-content;
  height: 31px;
  margin: 5px auto 0 auto;
  .discount_price {
    font-size: 30px;
    font-weight: bold;
    color: #553E1C;
    .sign {
      font-size: 14px;
    }
  }

  .price {
    font-size: 14px;
    font-weight: 400;
    color: #553E1C;
    text-decoration: line-through;
  }
}

.tip_middle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 19px;
  margin: 12px auto 0 auto;
  border: 1px solid #553E1C;
  border-radius: 10px;
  .text {
    margin: 0 17px;
    font-size: 12px;
    font-weight: 400;
    color: #553E1C;
  }
}

.tips_footer {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  color: #553E1C;
  background: #FFE8C0;
  border-top: 1px solid #CB8000;
  border-radius: 0px 0px 8px 8px;
  &.mg {
    box-sizing: border-box;
    padding: 0 10px;
  }
}
</style>
