<template>
  <div class="header_box">
    <div class="logo_box">
      <p class="logo_font">{{ channelStr }}官方合作的办公内容平台</p>
    </div>

    <ul class="header_tools_box">
      <!-- 活动弹窗按钮, sys渠道不要该功能-->
      <li v-if="sysChannel && activityHeaderPic && showActivityBtn" class="item_box item_activity">
        <div class="activity_btn" @click="showVipActivityDialog">
          <img alt="activity img" :src="activityHeaderPic">
        </div>
      </li>
      <!-- 搜索框 -->
      <li class="item_box item_search">
        <Search />
      </li>
      <!-- 购买会员 -->
      <li v-if="sysChannel && getShowBuyMember" class="item_box item_member">
        <div
          v-report:mainclick="{area: 'vip', pos:'0', ext: ''}"
          class="buy_member" @click="judgeOpenMember">
          <div v-if="tip" class="tip">
            <p class="text">{{ tip }}</p>
          </div>
        </div>
      </li>
      <!-- 用户 -->
      <li
        v-if="userInfo.avatar_url"
        key="1" class="item_box has_logo"
        :class="{ active: toolsActive === zero || userAvatarVisible }">
        <div
          v-lazy:backgroundImage="userInfo.avatar_url" v-report:mainclick="{ area: 'account', pos:'0', ext: '' }"
          class="tools_item user_avatar" :class="{ 'user_memeber_border': userInfo.privliegeEndTime }"
          @mousedown="toolsActive = zero" @mouseup="toolsActive = null" @click="toolsHandle('user')">
          <span v-show="userInfo.privliegeEndTime" class="user_memeber" />
        </div>
        <UserInfoBox class="user-info-box" />
      </li>
      <li
        v-else key="2" class="item_box"
        :class="{ active: toolsActive === zero }">
        <div
          v-report:mainclick="{ area: 'account', pos: '0', ext: '' }"
          class="tools_item icon_tools_user" @mousedown="toolsActive = zero"
          @mouseup="toolsActive = null" @click="openLoginDialog" />
      </li>

      <!-- 桌面图标 -->
      <li class="item_box" :class="{ active: toolsActive === one }">
        <div
          v-report:mainclick="{ area: 'seticon', pos:'0', ext: '' }"
          class="tools_item icon_tools_desktop" @mousedown="toolsActive = one"
          @mouseup="toolsActive = null" @click="toolsHandle('desktop')" />
        <DialogBox color="blue" class="dialog msg_dialog"><p class="info">生成桌面图标</p></DialogBox>
      </li>

      <!-- 设置 -->
      <li class="item_box item_h24" :class="{ active: toolsActive === two || settingVisible }">
        <div
          class="tools_item icon_tools_setting" @mousedown="toolsActive = two"
          @mouseup="toolsActive = null" @click.self="toolsHandle('setting')" />
        <DialogBox class="setting_dialog" :class="{ 'active': settingVisible }">
          <ul class="setting_list">
            <li v-report:mainclick="{ area: 'set', pos: '0', ext: '' }" class="item" @click="$emit('setting')">设置</li>
            <li v-report:mainclick="{ area: 'set', pos: '1', ext: '' }" class="item" @click="customerHandle">客服</li>
            <li v-report:mainclick="{ area: 'set', pos: '2', ext: '' }" class="item" @click="feedbackHandle">反馈</li>
            <li v-report:mainclick="{ area: 'set', pos: '3', ext: '' }" class="item" @click="$emit('about')">关于</li>
          </ul>
        </DialogBox>
      </li>

      <!-- 最小化 -->
      <li class="item_box item_h24" :class="{ active: toolsActive === three }">
        <div
          v-report:mainclick="{ area: 'mini', pos: '0', ext: '' }"
          class="tools_item icon_tools_min" @mousedown="toolsActive = three"
          @mouseup="toolsActive = null" @click="toolsHandle('min')" />
      </li>

      <!-- 关闭 -->
      <li class="item_box item_h24" :class="{ active: toolsActive === four }">
        <div
          v-report:mainclick="{ area: 'close', pos: '0', ext: '' }"
          class="tools_item icon_tools_close" @mousedown="toolsActive = four"
          @mouseup="toolsActive = null" @click="toolsHandle('close')" />
      </li>
      <!-- 桌面快捷方式提醒 -->
      <DialogC v-model="dialogClose" v-reportView:back="{ poptype: 1, actiontype: 1, click: '', ext: '' }" size="mini">
        <div class="close_content">
          <div class="desc">
            <span class="icon" />
            <div>
              <p class="dialog_title">添加到桌面下次找模板更方便~</p>
              <div class="notice_box">
                <Checkbox @select="closeSelect" />
                <p class="notice">下次不再提醒</p>
              </div>
            </div>
          </div>
          <div class="btns">
            <ComButton
              class="btn" size="big" type="hover"
              @click="closeSubmit(true)">确定</ComButton>
            <ComButton class="btn" size="big" @click="closeSubmit">取消</ComButton>
          </div>
        </div>
      </DialogC>
      <!-- 关闭提示 -->
      <DialogC v-model="dialogCloseDownload" v-reportView:back="{ poptype: 2, actiontype: 1, click: '', ext: '' }" size="mini">
        <div class="dialog_content">
          <p class="title_down">您有文档正在下载，退出将导致下载失败</p>
          <div>
            <ComButton
              size="big" type="hover" class="first_btn"
              @click="closeDownload">继续下载</ComButton>
            <ComButton size="big" @click="closeHandle">仍然退出</ComButton>
          </div>
        </div>
      </DialogC>
    </ul>
  </div>
</template>

<script>
import DialogBox from '@/components/Header/DialogBox.vue'
import DialogC from '@/components/DialogC/index.vue'
import Checkbox from '@/components/Checkbox/index.vue'
import Search from '@/components/Search/index.vue'
import UserInfoBox from './UserInfoBox.vue'

import {
  ClientCreateLnk, ClientMinimizedWindow, ClientCloseWindow,
  ClientOpenWebsiteUrl, ClientFeedback, ClientIsLnkExists
} from '@/common/clientFunc.js'
import { channelType, abTestType } from '@/common/const.js'
import { getChannel, CacheGetUserHistory, CacheSetUserHistory } from '@/common/cache.js'
import { getChannelName } from '@/common/utils.js'
import { customerUrl } from '@/common/resources.js'
import api from '@/api'
import { report } from '@/report/index.js'
import { buyPackages } from '@/api/bussinessCode.js'

export default {
  name: 'HeaderContent',

  components: { DialogBox, DialogC, Checkbox, Search, UserInfoBox },

  data () {
    return {
      zero: 0,
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      toolsActive: null,
      userAvatarVisible: false,
      settingVisible: false,
      dialogClose: false,
      dialogCloseSelect: false,
      dialogCloseDownload: false,
      reportPos: 0, // 活动banner上报pos
      activityHeaderPic: '', // header活动图片
      channelStr: getChannelName(),
      channelType,
      channel: getChannel(),
      activityType: false,
      activityClick: '', // 活动图点击触发类型
      tip: ''
    }
  },

  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    isLoginedIn () {
      return this.$store.getters.isLoginedIn
    },
    downingList () {
      return this.$store.state.downingList
    },
    getShowBuyMember () {
      return this.$store.state.isShowBuyMember
    },
    buyDialogActivityType () {
      return this.$store.state.buyDialogType
    },
    buyPackages () { // 支付窗活动套餐状态
      return buyPackages
    },
    showActivityBtn () { // 顶部终身会员活动入口
      return this.$store.state.isShowActivityBtn
    },
    sysChannel () { // 目前渠道限制仅在这里，就展示不抽离到全局，如有新需求，应迁移到全局
      return this.channel !== this.channelType.sys
    },
    hasCoupon () {
      return this.$store.state.hasCoupon
    },
    dialogPayAbTestType () { // 支付窗abTest类型
      return this.$store.state.dialogPayAbTestType
    }
  },

  watch: {
    '$store.state.buyDialogType': { // 监听活动状态变化
      deep: true,
      handler () {
        this.initActivityType()
      },
    },
    hasCoupon () {
      this.setTip()
    }
  },

  async mounted () {
    document.addEventListener('click', this.settingHandle)
    await this.$store.dispatch('updateCouponState')
    this.setTip()
  },

  destroyed () {
    document.removeEventListener('click', this.settingHandle)
  },

  methods: {
    setTip () {
      if (this.dialogPayAbTestType === abTestType.a) {
        return
      }

      // 如果是vip，不显示
      if (this.userInfo.isVip) {
        return
      }
      if (this.hasCoupon) {
        this.tip = '劵后立减8.8元'
      } else {
        this.tip = '领劵立减'
      }
    },

    initActivityType () { // 根据活动状态调整活动参数
      switch (this.$store.state.buyDialogType) {
        case buyPackages.actDubaMember: // 毒霸会员活动
          this.activityType = 'vipHeaderPic'
          this.activityClick = 'vipHeaderBannerType'
          this.reportPos = 1
          break

        case buyPackages.actDubaCoin: // 毒霸积分会员活动
          this.dubaCoinVisible = true
          this.reportPos = 3
          break

        default: // 普通用户活动
          this.activityType = 'headerBanner'
          this.activityClick = 'headerBannerType'
          this.reportPos = 2
          break
      }
      this.getHeaderBannerPic() // 获取header图片
    },

    getHeaderBannerPic () {
      api.common.config({ data: { conf_code: this.activityType } }).then(res => { // 查询活动图片url
        const config = res?.data?.data?.content
        if (config) {
          this.activityHeaderPic = config
        }
      })
    },

    judgeOpenMember () {
      const activityType = this.$store.state.buyDialogType
      if (activityType) {
        this.$store.dispatch('OpenActivityVipDialog')
        this.tip = ''
        return
      }
      this.$store.dispatch('judgeOpenMember')
    },

    openLoginDialog () {
      this.$store.commit('changeLoginDialogVisible', true)
    },

    settingHandle (e) {
      const className = e.target.className

      if (this.userAvatarVisible && !className.includes('user_avatar')) {
        this.userAvatarVisible = false
      }

      if (className.includes('icon_tools_setting')) { return }
      this.settingVisible = false
    },

    customerHandle () {
      ClientOpenWebsiteUrl(customerUrl)
    },

    feedbackHandle () {
      ClientFeedback()
    },

    toolsHandle (tool) {
      switch (tool) {
        case 'user':
          this.userAvatarVisible = true
          break
        case 'desktop':
          ClientCreateLnk()
          break
        case 'setting':
          this.settingVisible = true
          break
        case 'min':
          this.minWindow()
          break
        case 'close':
          if (this.downingList.length) {
            this.dialogCloseDownload = true
            return
          }
          this.closeHandle()
      }
    },

    minWindow () {
      ClientMinimizedWindow()
    },

    closeDownload () {
      report('back', { poptype: 2, actiontype: 2, click: 5, ext: '' })
      this.dialogCloseDownload = false
    },

    closeHandle () {
      report('back', { poptype: 2, actiontype: 2, click: 6, ext: '' })
      const userHistory = CacheGetUserHistory()

      if (userHistory?.closeCreateLnk) {
        this.closeWindow()
        return
      }

      ClientIsLnkExists((ret) => {
        if (ret?.result) {
          this.closeWindow()
          return
        }

        this.dialogCloseDownload = false
        this.dialogClose = true
      })
    },

    closeWindow () {
      ClientCloseWindow()
    },

    closeSelect (flag) {
      flag && report('back', { poptype: 1, actiontype: 2, click: 2, ext: '' })
      this.dialogCloseSelect = flag
    },

    closeSubmit (flag = false) { // flag是否创建快捷方式
      if (this.dialogCloseSelect) {
        CacheSetUserHistory({ closeCreateLnk: true })
      }

      if (!flag) {
        report('back', { poptype: 1, actiontype: 2, click: 3, ext: '' })
        this.closeWindow()
        return
      }

      ClientCreateLnk(() => {
        report('back', { poptype: 1, actiontype: 2, click: 1, ext: '' })
        this.closeWindow()
      })
    },

    showVipActivityDialog () {
      report('mainclick', { area: 'banner', pos: this.reportPos, ext: '' })
      if (this.isLoginedIn) {
        // this.$store.commit('changeVipActivityDialogVisible', true)
        this.showDialog()
        return
      }
      this.$store.commit('changeActivityNeedShow', true) // 设置点击标记
      this.$store.commit('changeActivityClickType', this.activityClick) // 设置点击触发类型
      this.openLoginDialog()
    },

    showDialog () {
      api.common.config({ data: { conf_code: this.activityClick } }).then(res => { // 查询点击触发支付框类型
        const config = res?.data?.data?.content
        if (Number(config)) { // 活动支付
          this.$store.dispatch('getActivityWindow') // 查询是否弹出活动支付弹窗
          return
        }
        this.$store.commit('changeBuyDialogVisible', true) // 普通支付框
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header_box { display: flex; justify-content: space-between; padding: 0 14px; font-size: 0; background: $bg_2; -webkit-app-region: drag;
  .logo_box { height: 100%; padding-left: 113px; background-image: url(~@/assets/images/logo.png); background-repeat: no-repeat; background-position: left center; background-size: 106px 18px; }
  .logo_font { margin-top: 22px; font-size: 12px; line-height: 12px; color: $primary_color; }

  .header_tools_box { display: flex; align-items: center; -webkit-app-region: no-drag; }
  .item_box { position: relative; display: flex; align-items: center; height: 50px; cursor: pointer;
    .user-info-box { position: absolute; top: 40px; right: -115px; display: none; }
    &:hover {
      .tools_item { background-position: center center; }
      .dialog { display: block; }
      .user-info-box { display: flex }
    }
    &.active { .tools_item { background-position: right center; }}
    &.item_member { height: 27px; }
    &.item_h24 { height: 24px; }
    &.has_logo { position: relative; margin-right: 5px; }
    .user_avatar { border-radius: 50%; }
    .user_memeber_border { border: 1px solid #F6B340; }

    .user_memeber { position: absolute; top: 5px; right: -5px; display: inline-block; width: 13px; height: 13px; background: url(~@/assets/images/icon_avatar_member.png); background-size: cover; }
  }

  .buy_member { position: relative; width: 87px; height: 100%; margin-right: 10px; background: url(~@/assets/images/icon_member.png) left center no-repeat;
    .tip { position: absolute; top: 25px; right: 0; left: 0; z-index: 1; display: flex; flex-direction: column; align-items: center; width: 86px; height: 25px; font-size: 12px; text-align: center;
      &::before { width: 0; height: 0; content: ''; border: 5px solid transparent; border-bottom: 5px solid #7EBCFF; }
      .text { width: 100%; padding: 2px 0; font-size: 12px; color: #fff; background: linear-gradient(-55deg, #55A6FE, #7EBCFF); border-radius: 4px; box-shadow: 0px 3px 4px 0px rgba(43, 57, 71, 0.24); }
    }
    &:hover { background-position: center center; }
    &:active { background-position: right center; }
  }

  .dialog { position: absolute; top: 50px; z-index: 1; display: none; }
  .msg_dialog { right: -15px;
    &.active { display: block; }
    .info { padding: 0 10px; font-size: 12px; font-weight: 400; line-height: 30px; color: #fff; text-align: center; white-space: nowrap; }
  }

  .setting_dialog { position: absolute; top: 36px; right: -15px; z-index: 1; display: none;
    &.active { display: block; }
  }

  .setting_list { padding: 5px 0; font-size: 12px; font-weight: 400; line-height: 26px; text-align: center;
    .item { &:hover { color: #fff; background-color: $primary_color; } }
  }

  .tools_item { width: 24px; height: 24px; background-position: left center; background-size: auto 100%;
    &.icon_tools_user { background-image: url(~@/assets/images/icon_tools_user.png); }
    &.icon_tools_desktop { background-image: url(~@/assets/images/icon_tools_desktop.png); }
    &.icon_tools_setting { background-image: url(~@/assets/images/icon_tools_setting.png); }
    &.icon_tools_min { background-image: url(~@/assets/images/icon_tools_min.png); }
    &.icon_tools_close { background-image: url(~@/assets/images/icon_tools_close.png); }
  }

  .close_content { position: absolute; top: 0; bottom: 0; width: 100%; font-size: 14px; font-weight: 400;
    .desc { display: flex; align-items: center; justify-content: flex-start; height: 54px; margin: 28px 0 22px 0; margin-left: 28px; font-size: 14px; font-weight: 400; }
    .icon { display: inline-block; width: 54px; height: 54px; margin-right: 10px; background-image: url(~@/assets/images/icon_question.png); background-size: cover; }
    .dialog_title { padding-bottom: 10px; }

    .notice_box { display: flex; }
    .notice { color: rgba(68, 68, 68, 0.6);  }

    .btns { display: flex; justify-content: flex-end; margin-right: 16px; }
    .btn { width: 80px;
      &:first-child { margin-right: 20px; }
    }
  }

  .dialog_content { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; align-items: center; justify-content: center;
    .title_down { margin-bottom: 36px; font-size: 14px; font-weight: 400; }
    .first_btn { margin-right: 20px; }
  }
  .item_activity{ width: 150px; margin-right: 20px; }
  .activity_btn{ position: relative; top: 4px; width: 100%; height: 32px; font-size: 14px; line-height: 32px; color: #FF0026; text-decoration: underline; cursor: pointer; }
}
</style>
