// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icon_arrow.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/icon_arrow_active.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".common_pagination[data-v-39766a7c]{display:flex;align-items:center;justify-content:center}.common_pagination .arrow[data-v-39766a7c]{display:inline-block;width:22px;height:22px;cursor:pointer;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;border-radius:4px}.common_pagination .arrow.next[data-v-39766a7c]{transform:rotate(180deg)}.common_pagination .arrow[data-v-39766a7c]:hover{background-color:#4a96e8;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.common_pagination .page_num[data-v-39766a7c]{display:flex;padding:0 10px;font-size:12px}.common_pagination .page_num .page_item[data-v-39766a7c]{display:flex;align-items:center;justify-content:center;width:22px;height:22px;text-align:center;cursor:pointer;border-radius:4px}.common_pagination .page_num .page_item+.page_item[data-v-39766a7c]{margin-left:5px}.common_pagination .page_num .page_item.active[data-v-39766a7c],.common_pagination .page_num .page_item[data-v-39766a7c]:hover{color:#fff;background-color:#4a96e8}", ""]);
// Exports
module.exports = exports;
