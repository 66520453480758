<template>
  <div class="privilege">
    <div v-for="(text, index) in list" :key="index" class="icon_box">
      <i :class="`icon icon_${imgIdMap[text]}`" />
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
const imgIdMap = {
  精品PPT: 0,
  文档简历: 1,
  Excel报表: 2,
  广告设计: 3,
  素材元素: 4,
  终身免费: 5,
  无限下载: 6,
  一对一客服: 7
}

imgIdMap['下载30次/日'] = 8
imgIdMap['下载10次/日'] = 9

const privilegeMap = {
  终身VIP: ['精品PPT', '文档简历', 'Excel报表', '广告设计', '素材元素', '终身免费', '无限下载', '一对一客服'],
  包年VIP: ['精品PPT', '文档简历', 'Excel报表', '广告设计', '素材元素', '下载30次/日', '', ''],
  三个月VIP: ['精品PPT', '文档简历', 'Excel报表', '广告设计', '素材元素', '下载10次/日', '', ''],
  default: []
}

export default {
  name: 'Privilege',

  props: {
    name: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      imgIdMap
    }
  },

  computed: {
    list () {
      return privilegeMap[this.name] || privilegeMap.default
    }
  },
}
</script>

<style lang="scss" scoped>
  .privilege {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 182px;
    height: 335px;
    padding: 24px;
    background: #FFF;
    border-radius: 8px;
    .icon_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 66px;
      font-size: 10px;
      font-weight: 400;
      color: #333333;
    }
    .icon {
      width: 34px;
      height: 34px;
      margin-bottom: 7px;
      &.icon_0 {
        background: url(~@/assets/images/icons_privilege/精品PPT.png) no-repeat;
      }
      &.icon_1 {
        background: url(~@/assets/images/icons_privilege/文档简历.png) no-repeat;
      }
      &.icon_2 {
        background: url(~@/assets/images/icons_privilege/Excel报表.png) no-repeat;
      }
      &.icon_3 {
        background: url(~@/assets/images/icons_privilege/广告设计.png) no-repeat;
      }
      &.icon_4 {
        background: url(~@/assets/images/icons_privilege/素材元素.png) no-repeat;
      }
      &.icon_5 {
        background: url(~@/assets/images/icons_privilege/终身免费.png) no-repeat;
      }
      &.icon_6 {
        background: url(~@/assets/images/icons_privilege/无限下载.png) no-repeat;
      }
      &.icon_7 {
        background: url(~@/assets/images/icons_privilege/一对一客服.png) no-repeat;
      }
      &.icon_8 {
        background: url(~@/assets/images/icons_privilege/下载30次_日.png) no-repeat;
      }
      &.icon_9 {
        background: url(~@/assets/images/icons_privilege/下载10次_日.png) no-repeat;
      }
    }
  }
</style>
