<template>
  <div v-if="value" class="common_dialog_bg">
    <div class="common_dialog" :class="size">
      <div class="header">
        <div class="title">
          <IconLogo class="logo_mini" />
          <p>{{ title }}</p>
        </div>
        <IconClose class="close_btn" @click.native="close" />
      </div>
      <div class="dialog_content"><slot /></div>
    </div>
  </div>
</template>

<script>
import IconLogo from '@/components/Icons/IconLogo.vue'
import IconClose from '@/components/Icons/IconClose.vue'

export default {
  name: 'Dialog',

  components: { IconLogo, IconClose },

  props: {
    title: {
      type: String,
      default: '提示'
    },

    value: {
      type: Boolean
    },

    size: {
      type: String,
      default: 'middle'
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
      this.$emit('close', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.common_dialog_bg{ position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 100; background-color: rgba(0, 0, 0, 0.4);
  .common_dialog { position: absolute; top: 50%; left: 50%; z-index: 10; display: flex; flex-direction: column; width: 444px; background-color: #fff; box-shadow: $boxShadow; transform: translate(-222px, -90px);
    &.mini { height: 180px; }
    &.middle { height: 208px; transform: translate(-222px, -104px) }
    &.bigger { width: 500px; height: 350px; transform: translate(-250px, -150px); }
    &.max { width: 500px; height: 500px; transform: translate(-250px, -250px); }
    .header { display: flex; justify-content: space-between; height: 30px; padding: 0 10px; border-bottom: 1px solid rgba(222, 222, 222, 0.6); }
    .title { display: flex; align-items: center; font-size: 12px; line-height: 30px; color: $primary_color; }
    .logo_mini { margin-right: 6px; }
    .close_btn { line-height: 30px; }

    .dialog_content { position: relative; flex: 1; }
  }
}

</style>
