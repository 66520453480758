/**
 * 客户端异步调用接口
 * fnName: string 方法名
 * params: json 传给客户端的参数
 * onSuccess: function 成功时回调
 * onFailure: function 失败时回调
 */

/* eslint-disable */
const INVOKE_ERROR_CODE = 10500
export function callRequest ({ module, fnName, params }) {
  return new Promise((resolve, reject) => {
    try {
        if (!module || module === "undefined" || !fnName || fnName === 'undefined') { 
            reject({ msg: 'fnName,module必传' })
            return    
        }

        if (!params || params === "undefined") {
            params = '{}';  // 无值时，直接传'{}'，兼容毒霸用法
        } else {
            params = JSON.stringify(params)
        }

        const clientParams = {
          module: module,
          fnName: fnName,
          params: params,
          onSuccess: ret => {
            const retObj = ret ? JSON.parse(ret) : ''
            setTimeout(()=>{
                resolve(retObj)
            })
          },
          onFailure: (errcode, errmsg) => {
            console.error('调用失败: ', fnName, errcode, errmsg)
            reject({ errcode, errmsg })
          }
        }

        if (window.external.CallRequest) {
            window.external.CallRequest(clientParams)
        }else {
            resolve({})
        }
    } catch (e) {
      reject({ INVOKE_ERROR_CODE, e })
    }
  })
}

/**
 * 向客户端注册：：下载完成结果
 * 只要注册一次，结果返回是一个个返回，即使同时下载多个
 */
export const registerNotifyDownloadFinishFunc = (fn) => {
  return new Promise((resolve, reject)=> {
    try {
      window.external.RegisterRequest({
        fnName: 'notifyDownloadFinish',  // 此函数名是固定的
        registerFunc(ret) { // 下载完成的回调
            fn(ret)
        },
        onSuccess(ret) {  // 注册函数成功时，客户端会回调该函数
            resolve(ret)
        },
        onFailure(errcode, errmsg) {  // 注册函数失败时，客户端会回调该函数
            var msg = '错误代码: ' + errcode + '错误消息: ' + errmsg;
            reject({msg})
        }
      })
    } catch(e) {
      reject({INVOKE_ERROR_CODE, e})
    }
  })
}
/* eslint-enable */
