<template>
  <div class="customization_item">
    <div class="am_box" @click="toCustomization">
      <div class="item_img" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomizationItem',

  props: {
    url: {
      type: String,
      default: '/customization',
      require: true
    },
  },

  methods: {
    toCustomization () {
      this.$router.push({ path: this.url })
    }
  }
}
</script>

<style lang="scss" scoped>
.customization_item { position: relative; float: left; width: 222px; height: 316px; font-size: 0;
  &:hover {
    .am_box { width: 222px; height: 314.5px; cursor: pointer; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4); transition: all 0.1s linear; }
  }
  .am_box { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; width: 216px; height: 306px; margin: auto; overflow: hidden; text-decoration: none; vertical-align: middle; border-radius: 4px; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    .item_img { flex: 1; cursor: pointer; background-image: url("~@/assets/images/icon_customization_entry.png"); background-repeat: no-repeat; background-size: cover; }
  }
}

</style>
