import storage from '@/common/cacheHandle.js'

const storageKey = {
  UserInfo: 'userInfo',
  DownloadUrl: 'downloadUrl',
  UserHistory: 'userHistory',
  IsShowActivity: 'IsShowActivity',
  IsNewUser: 'IsNewUser',
  Coupon: 'Coupon',
  DialogPayAbTestType: 'DialogPayAbTestType'
}

/** 用户信息 处理 */
export const CacheSetUserInfo = (userInfo) => {
  // 服务器失效时间为180天，这里设置170，让用户重新登录，刷新token，用户本地时间问题暂不处理
  storage.set(storageKey.UserInfo, userInfo, { expires: 170 })
}
export const CacheGetUserInfo = () => storage.get(storageKey.UserInfo)
export const CacheRemoveUserInfo = () => storage.remove(storageKey.UserInfo)

/** 判断是否第一次使用用户，local否则，新建local并返回true，不处理清除缓存问题，用于上报 */
export const CacheIsNewUser = () => {
  const isNewUser = storage.get(storageKey.IsNewUser)

  if (isNewUser) { return false }

  storage.set(storageKey.IsNewUser, true)

  return true
}

/** 顶部setting下载路径缓存 */
export const CacheSetDownloadUrl = (url) => {
  storage.set(storageKey.DownloadUrl, url)
}
export const CacheGetDownloadUrl = () => storage.get(storageKey.DownloadUrl)

/** 记录用户操作缓存 -- 不清除 */
export const CacheGetUserHistory = () => storage.get(storageKey.UserHistory)
export const CacheSetUserHistory = (obj) => {
  const userHistory = CacheGetUserHistory() || {}
  storage.set(storageKey.UserHistory, { ...userHistory, ...obj })
}

// 活动框弹出记录 1天弹一次
export const CacheSetIsShowActivity = (IsShowActivity) => {
  storage.set(storageKey.IsShowActivity, IsShowActivity, { expires: 1 })
}
export const CacheGetIsShowActivity = () => storage.get(storageKey.IsShowActivity)

// ---------------------------- 随时改动的local缓存，用于abTest ---------
const abTestStorageKey = { // 不能删除，因为用户测可能还存在，避免新功能用到旧字段
  AbTestClassifyList: 'abTestClassifyList',
  AbTestLogin: 'abTestLogin'
}

export const abTestLoginDefaultValue = '2'

/** 获取登录页abTest值，type: String, '1':是默认登录, '2': 是新登录框，默认加载微信二维码 */
export const CacheGetAbTestLogin = () => {
  const value = storage.get(abTestStorageKey.AbTestLogin) // 无值返回null

  if (typeof value === 'string') { return value }

  const randomVal = Math.random() > 0.5 ? '1' : abTestLoginDefaultValue

  storage.set(abTestStorageKey.AbTestLogin, randomVal)

  return randomVal
}

export const CacheSetCoupon = (validate) => {
  storage.set(storageKey.Coupon, { validate })
}

export const CacheGetCoupon = () => {
  return storage.get(storageKey.Coupon)
}

export const CacheSetDialogPayAbTestType = (type) => {
  storage.set(storageKey.DialogPayAbTestType, { type })
}

export const CacheGetDialogPayAbTestType = () => {
  return storage.get(storageKey.DialogPayAbTestType)
}

// -----------------------------   session ----------------

const sessionKey = {
  Source: 'source',
  Channel: 'channel'
}

/** 存放source，用于上报，只存session，不记录上一次source */
export const getSource = () => {
  if (typeof sessionStorage === 'undefined') { return null }

  return sessionStorage.getItem(sessionKey.Source) || null
}
export const setSource = (source) => {
  if (typeof sessionStorage !== 'undefined' && source) {
    sessionStorage.setItem(sessionKey.Source, source)
  }
}
/** 存放channel，用于上报，只存session，不记录上一次channel */
export const getChannel = () => {
  if (typeof sessionStorage === 'undefined') { return null }

  return sessionStorage.getItem(sessionKey.Channel) || null
}
export const setChannel = (channel) => {
  if (typeof sessionStorage !== 'undefined' && channel) {
    sessionStorage.setItem(sessionKey.Channel, channel)
  }
}
