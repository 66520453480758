import axios from 'axios'
import isFunction from 'lodash/isFunction'
import axiosJsonpAdapter from './axios-jsonp'
import { extend, strictExtend } from '../base'

class NetworkError extends Error {
  constructor (message) {
    super(message)
    this.message = message // message单元测试用到
  }
}

class DataService {
  constructor (opt = {}) {
    this.baseConfig = strictExtend({
      retry: {
        times: 0,
        delay: 200
        // _retryTime // 内部使用，用于尝试次数的计数
      }
    }, opt.default)

    this.axiosInstance = axios.create()
  }

  /**
   * 预处理逻辑
   * @param {string|object} defaultOpt 接口默认的配置选项
   */
  preHandle (defaultOpt) {
    return (opt) => {
      let options = extend(true, {}, this.baseConfig, defaultOpt, opt)
      options.url = `${options.baseUrl || ''}${options.url || ''}`
      if (/get/i.test(options.method)) {
        options = extend(options, {
          params: options.data
        })
      }
      if (options.debugOpt) { // 用于单元测试
        return this.getAxiosOpt(options)
      }
      return this._getData(options)
    }
  }

  /**
   * @ignore
   * @return {object}
   */
  getAxiosOpt (intfcInfo) {
    let opt = intfcInfo

    if (opt.dataType === 'jsonp') {
      opt.adapter = axiosJsonpAdapter
    }

    if (isFunction(intfcInfo.requestDataFn)) {
      opt = intfcInfo.requestDataFn(opt)
      if (/get/i.test(opt.method)) {
        opt.params = opt.data
      }
    }
    return opt
  }

  /**
   * @ignore
   */
  _handleException (options, err) {
    const config = options.__cache || options

    // 设置重试次数
    config.retry._retryTime = config.retry._retryTime || 0

    if (config.retry._retryTime >= config.retry.times) {
      if (isFunction(config.responseFailFn)) {
        return config.responseFailFn(err)
      }

      if (config.debugRetry) {
        // 错误回调
        return Promise.reject(new NetworkError({ err, config }))
      }
      // 错误回调
      /* istanbul ignore next */
      return Promise.reject(new NetworkError(err))
    }

    config.retry._retryTime += 1

    // 创建重试延迟
    const delay = new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, config.retry.delay)
    })

    // 创建重试句柄
    return delay.then(() => {
      return this._getData(config, false)
    })
  }

  /**
   * @ignore
   */
  _getData (options, isFormat) {
    return this.__getData(options, isFormat).then((res) => {
      if (isFunction(options.responseSuccessFn)) {
        return options.responseSuccessFn(res)
      }
      return res
    }).catch(err => {
      return this._handleException(options, err)
    })
  }

  /**
   * 最底层调用axios发送的请求
   * @ignore
   * @return {any}
   */
  __getData (intfcInfo, isFormat = true) {
    let opt
    if (isFormat) {
      opt = this.getAxiosOpt(intfcInfo)
      intfcInfo.__cache = strictExtend(opt)
    } else {
      opt = intfcInfo
    }

    if (opt.debugSuccess) {
      return Promise.resolve({ ret: 0 })
    } else if (opt.debugFail) {
      return Promise.reject(new Error('request fail'))
    }
    /* istanbul ignore next */
    return this.axiosInstance(opt)
  }
}

export default DataService
