// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/bg_pay_stay.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/icon_use.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".pay_stay[data-v-631bc8c8]{position:relative;width:100%;height:100%;background-color:rgba(0,0,0,.5)}.pay_stay .box[data-v-631bc8c8]{top:0;width:443px;height:320px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.pay_stay .box[data-v-631bc8c8],.pay_stay .box .button[data-v-631bc8c8]{position:absolute;right:0;bottom:0;left:0;margin:auto}.pay_stay .box .button[data-v-631bc8c8]{top:131px;width:138px;height:37px;cursor:pointer;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");border-radius:25px;box-shadow:0 3px 10px #c3c3c3}.pay_stay .box .icon_close[data-v-631bc8c8]{position:absolute;top:45px;right:55px}", ""]);
// Exports
module.exports = exports;
