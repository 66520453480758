<template>
  <div class="login_dialog_box">
    <div class="dialog_box">
      <div class="close_box">
        <h5 class="title">关联爱办公会员</h5>
      </div>
      <p class="old_user_connect">检测到您是爱办公旧会员用户，请登录爱办公会员账号进行关联，关联后可继续享受会员权益</p>
      <FontLine :width="60">登入爱办公会员账号</FontLine>
      <ul class="login_box">
        <li class="login_item" @click="login(loginType.wechat)"><p class="item_name">微信登录</p></li>
        <li class="login_item" @click="login(loginType.qq)"><p class="item_name">QQ登录</p></li>
      </ul>
      <p class="remark">有任何疑问请联系【设置】-【客服】</p>
    </div>

    <!-- 登录二维码 -->
    <DialogC v-model="loginQrDialog" size="max" :title="'完美办公'">
      <QrDialog :height="loginType.wechat ? 400 : 469" :url="loginUrl" />
    </DialogC>
  </div>
</template>

<script>
import FontLine from '@/components/FontLine/index.vue'
import QrDialog from '@/views/common/QrDialog/index.vue'
import DialogC from '@/components/DialogC/index.vue'

import { loginType, postMessageType, queryLoginType } from '@/common/const.js'
import { proxyUrl } from '@/common/resources.js'
import { ClientSetUserInfo } from '@/common/clientFunc.js'
import { CacheSetUserInfo } from '@/common/cache.js'

import api from '@/api'
import { RetCode } from '@/api/bussinessCode.js'

import WeChatAuth from '@/plugins/auth/wechatAuth.js'
import QQAuth from '@/plugins/auth/qqAuth.js'

export default {
  name: 'LoginDialog',

  components: { FontLine, DialogC, QrDialog },

  data () {
    return {
      loginType: Object.freeze(loginType),
      selectType: '',
      loginUrl: '',
      loginQrDialog: false,
      isOldUser: true,
      wechatClass: new WeChatAuth({ isBind: true }),
      qqClass: new QQAuth({ isBind: true })
    }
  },

  mounted () {
    this.listenMessage()
  },

  destroyed () {
    window.removeEventListener('message', this.listenFunc)
  },

  methods: {
    login (type) {
      this.selectType = type

      if (type === loginType.wechat) {
        this.loginUrl = this.wechatClass.openAuthPage()
      } else if (type === loginType.qq) {
        this.loginUrl = this.qqClass.openAuthPage()
      }
      this.loginQrDialog = true
    },

    listenMessage () {
      window.addEventListener('message', this.listenFunc)
    },

    listenFunc (data) {
      if (data.data && typeof data.data === 'string') {
        const newData = JSON.parse(data.data)
        const search = newData.search

        if (!search) { return }

        const searchState = search.state
        const qqState = this.qqClass.getState()
        const wechatState = this.wechatClass.getState()

        if (!searchState || !(searchState === qqState || searchState === wechatState)) { return }

        if (!search.code) { return }

        this.userLogin(search.code, search[queryLoginType])
      }
    },

    userLogin (code, type) {
      const params = { login_type: 1, code }

      // qq登录参数，默认微信登录
      const qqLogin = type === postMessageType.qq
      if (qqLogin) {
        params.qq_redirect_url = encodeURIComponent(proxyUrl)
        params.login_type = 2
      }

      api.user.loginBind({ data: params }).then(res => {
        const data = res.data

        this.getPrivliege(data, qqLogin)
      }).catch(data => {
        this.loginQrDialog = false
        const msg = RetCode[data.ret || ''] || '获取信息失败，请联系客服'
        this.$toast({ desc: msg })
        this.$store.commit('tipsDialog/changeConnectRes', { connectResDialog: true, isBind: false })
      })
    },

    getPrivliege (data, qqLogin) {
      let privliegeName = ''
      let privliegeEndTime = ''
      let isVip = false

      api.user.privliege({ data: { common: { token: data.token, uid: data.uid } } }).then(pRes => {
        privliegeName = pRes.data.name
        privliegeEndTime = pRes.data.end_date
        isVip = true
      }).catch(data => {
        if (data?.ret && data.ret.toString() in RetCode) {
          privliegeName = RetCode[data.ret]
        }
      }).finally(() => {
        const userInfo = { token: data.token, uid: data.uid, isVip, ...data.user_info }
        const lastUserInfo = { privliegeName, privliegeEndTime, ...userInfo }

        this.$store.commit('tipsDialog/changeConnectRes', { connectResDialog: true, isBind: true })
        this.$store.commit('setUserInfo', lastUserInfo)
        CacheSetUserInfo(lastUserInfo)
        ClientSetUserInfo(lastUserInfo)
        this.loginQrDialog = false
        this.$store.commit('changeConnectDialogVisible', false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login_dialog_box { position: fixed; top: 0; z-index: 14; width: 100%; height: 100%; font-weight: 400; background-color: rgba(0, 0, 0, 0.4);
  .dialog_box { position: absolute; top: 50%; left: 50%; width: 434px; height: 454px; margin: -227px 0 0 -217px; background-color: #fff; }
  .close_box { display: flex; align-items: center; justify-content: space-between; height: 30px; padding: 0 10px; font-size: 0; text-align: right; border-bottom: 1px solid $border_color; }
  .title { font-size: 12px; color: $primary_color; }

  .old_user_connect { padding: 50px 68px; font-size: 14px  }
  .login_box { display: flex; justify-content: center; padding: 40px 80px 70px 80px; }
  .login_item { position: relative; display: inline-block; width: 90px; height: 122px; cursor: pointer; background-repeat: no-repeat; background-size: 100% auto;
    &:nth-child(1) { margin-right: 54px; background-image: url(~@/assets/images/icon_dialog_wechat.png);
      &:hover { background-image: url(~@/assets/images/icon_dialog_wechat_hover.png); }
    }
    &:nth-child(2) { background-image: url(~@/assets/images/icon_dialog_qq.png);
      &:hover { background-image: url(~@/assets/images/icon_dialog_qq_hover.png); }
    }
  }
  .item_name { position: absolute; bottom: 0px; width: 100%; font-size: 14px; color: #666; text-align: center; }

  .remark { font-size: 12px; color: #666; text-align: center; opacity: 0.5; }
}
</style>
