import { randomHexString } from '@shared/utils/base/src/random.js'
import { stringifyQuery } from '@shared/utils/querystring'

import { wechatProxyUrl, APPID, APPID2, proxyUrl, domainProxyUrl } from '@/common/resources.js'
import { postMessageType, queryLoginType } from '@/common/const.js'

// 微信登录跳转爱办公
const aibangongUrl = 'http://ppt.dasuancai.com/login_proxy'
// 微信登录 - 样式
const qrWechatStyle = '&href=data:text/css;base64,aHRtbCB7YmFja2dyb3VuZC1jb2xvcjogI2ZmZjt9Ci5pbXBvd2VyQm94IHt0ZXh0LWFsaWduOiBsZWZ0O30KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE0OHB4OyBtYXJnaW4tdG9wOiAwOyBib3JkZXI6IG5vbmU7fQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30KIA=='

export default class WeChatAuth {
  constructor (config) {
    const defaultConfig = {}
    this.config = Object.assign(defaultConfig, config)
    this.state = randomHexString(16)
  }

  getState () {
    return this.state
  }

  openAuthPage () {
    const wechatProxyUrl2 = `${aibangongUrl}?redirect_uri=${encodeURIComponent(`${proxyUrl}?postmessage_domain=${domainProxyUrl}&postmessage_type=parent&${queryLoginType}=${postMessageType.wechat}&state=${this.state}`)}`

    const queryObj = {
      appid: this.config.isBind ? APPID2 : APPID,
      redirect_uri: this.config.isBind ? wechatProxyUrl2 : wechatProxyUrl,
      self_redirect: true,
      response_type: 'code',
      scope: 'snsapi_login',
      state: this.state
    }

    return `https://open.weixin.qq.com/connect/qrconnect?${stringifyQuery(queryObj)}${this.config.isStyle ? qrWechatStyle : ''}#wechat_redirect`
  }
}
