// 上报dat
export const Dat = {
  back: 'duba_office_back:6835 actiontype:byte click:byte uid:int64 uidtype:byte productid:byte source:string ext:string poptype:byte',
  download: 'duba_office_download:6831 goodsid:int64 goodstype:byte free:byte classid:int64 uid:int64 uidtype:byte productid:byte source:string ext:string',
  goodsdetail: 'duba_office_goodsdetail:6832 actiontype:byte goodsid:int64 goodstype:byte free:byte classid:int64 showsource:byte clickid:byte uid:int64 uidtype:byte productid:byte source:string ext:string',
  listpage: 'duba_office_listpage:6826 actiontype:byte tabid:int64 showtype:byte clickid:byte goodsid:int64 goodstype:byte free:byte classid:int64 uid:int64 uidtype:byte productid:byte source:string ext:string',
  login: 'duba_office_login:7166 uid:int64 uidtype:byte productid:byte source:string ext:string step:string usertype:string',
  mainclick: 'duba_office_mainclick:6823 area:string pos:string uid:int64 uidtype:byte productid:byte source:string ext:string',
  open: 'duba_office_open:7185 page:byte loadtime:string uid:int64 uidtype:byte productid:byte source:string exy:string isnew:byte isdbvip:byte strategyid:string ver:string',
  pay: 'duba_office_pay:7476 uid:int64 uidtype:byte productid:byte source:string ext:string actiontype:byte clickid:byte',
  payresult: 'duba_office_payresult:7059 order_id:string order_time:string order_num:string order_package:string order_paytype:string uid:string uidtype:int64 productid:int64 source:string ext:string',
  search: 'duba_office_search:6834 posid:byte word:string uid:int64 uidtype:byte productid:byte source:string ext:string',
  searchpage: 'duba_office_searchpage:6833 actiontype:byte word:string defaulttab:byte amount:int clickid:byte goodesid:int64 goodstype:byte free:byte classid:int64 uid:int64 uidtype:byte productid:byte source:string ext:string',
  tips: 'duba_office_tips:7102 tipstype:byte actiontype:byte click:byte uid:int64 uidtype:byte productid:byte source:string ext:string',
  goodsshow: 'duba_office_goodsshow:7158 goodsid:int64 calssid:int64 goodstype:byte ver:byte uid:int64 uidtype:byte productid:byte source:string ext:string',
  toast: 'duba_office_toast:7167 actiontype:byte toastid:byte clickid:byte ext:string uid:int64 uidtype:int64 productid:int64 source:string'
}
