/**
 * @author 邓伟驰
 * @description 字节转大小
 * @param bytes{number} bytes 字节
 * @returns {{unit: string, size: number}|{size: number, unix: string}} 放回字节转化后的大小和单位
 */
export const bytesToSize = (bytes) => {
  if (bytes <= 0) {
    return {
      size: 0,
      unit: 'B'
    }
  }
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const index = Math.floor(Math.log(bytes) / Math.log(k))
  return {
    // eslint-disable-next-line no-magic-numbers,max-len
    size: Number.parseFloat((bytes / Math.pow(k, index)).toExponential(3)),
    unit: sizes[index]
  }
}
