<template>
  <div class="pay_box">
    <div class="left">
      <div :class="['prices', { can_no_get_coupon: !canGetCoupon }]">
        <span class="price">实付：￥ <span class="font_big">{{ price }}</span></span>
        <span class="price_minus"><span>已优惠：￥ {{ priceMinus }}</span></span>
      </div>
      <Carousel v-if="!canGetCoupon" />
      <template v-if="canGetCoupon">
        <Countdown :timeDiff="timeDiff" />
        <div v-if="hasCoupon" class="coupons">
          <div v-for="(priceCoupon, index) in pricesCoupon" :key="index" class="coupon">
            <span class="price">￥<span class="font_big">{{ priceCoupon }}</span></span>
            <span>直接抵扣</span>
          </div>
        </div>
        <span v-else class="coupon_receive" @click="clickCouponReceive" />
      </template>
    </div>
    <div class="right">
      <div v-if="wxQr" class="qrcode_item">
        <qrcode-vue
          v-show="wxQr" class="qr" :value="wxQr" level="H"
          :size="88"
        />
        <div class="btn">
          <i class="icon wx" />
          微信支付
        </div>
      </div>
      <div v-if="aliQr" class="qrcode_item">
        <qrcode-vue
          v-show="aliQr" class="qr" :value="aliQr" level="H"
          :size="88"
        />
        <div class="btn">
          <i class="icon alipay" />
          支付宝支付
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Countdown from './Countdown'
import Carousel from './Carousel'
import { CacheSetUserInfo, getSource } from '@/common/cache'
import api from '@/api'
import { report } from '@/report'
import { ClientSetUserInfo } from '@/common/clientFunc'
import { reportServerId, setNewCoupon } from '@/common/business.js'

const payapp = 'loveoffice'
const payStatus = {
  success: 'paid',
  wait: 'paying',
  refunding: 'refunding',
  refund: 'refund',
  fail: 'fail'
}

const payTypeMap = {
  wxPay: 1,
  aliPay: 2
}

export default {
  name: 'PayBox',

  components: { Countdown, QrcodeVue, Carousel },

  props: {
    price: {
      type: [String, Number],
      default: 152
    },
    priceMinus: {
      type: [String, Number],
      default: 299
    },
    packageId: {
      type: Number,
      default: 0
    },
    canGetCoupon: {
      type: Boolean,
      default: false
    },
    packageName: {
      type: String,
      default: ''
    },
    payResultExt: {
      type: Number,
      default: 0
    },
    extReport: {
      type: [Number, String],
      default: ''
    }
  },

  data () {
    return {
      interval: null,
      timeDiff: 0,
      wxQr: '',
      aliQr: ''
    }
  },

  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    pricesCoupon () {
      return [5.5, 3.3]
    },
    hasCoupon () {
      return this.$store.state.hasCoupon
    }
  },

  watch: {
    packageId (newValue) {
      if (newValue) {
        this.setWxQr()
        this.setAliQr()
      }
    }
  },

  created () {
    this.initInterval()
    this.setWxQr()
    this.setAliQr()
    this.$store.dispatch('updateCouponState')
  },

  beforeDestroy () {
    window.clearInterval(this.interval)
    clearInterval(this.Timer1)
    clearInterval(this.Timer2)
  },

  methods: {
    async clickCouponReceive () {
      // actiontype-1: 点击，clickid-1: 点击礼包
      report('pay', { actiontype: 2, clickid: 1, ext: this.extReport })
      await setNewCoupon(this.$store)
      this.$emit('reload')
    },

    async initInterval () {
      const now = await this.getServiceTime()
      const todayEnd = new Date(now).setHours(23, 59, 59, 999)
      this.timeDiff = todayEnd - now
      this.interval = setInterval(() => {
        this.timeDiff -= 1000
        if (this.timeDiff < 0) {
          this.timeDiff = 24 * 60 * 60 * 1000 + this.timeDiff
        }
      }, 1000)
    },

    getServiceTime () {
      return api.common.getServiceTime().then(res => res?.data?.timestamp * 1000)
    },

    async setWxQr () {
      this.wxQr = await this.getWxQrcodeByPackageId(this.packageId)
    },

    async setAliQr () {
      this.aliQr = await this.getAliPayQrcodeByPackageId(this.packageId)
    },

    async getWxQrcodeByPackageId (packageId) {
      const productIdWxPay = await this.getProductId(packageId, payTypeMap.wxPay)
      return await this.getWxQrcode(productIdWxPay)
    },

    async getAliPayQrcodeByPackageId (packageId) {
      const productIdAliPay = await this.getProductId(packageId, payTypeMap.aliPay)
      return await this.getAliQrcode(productIdAliPay)
    },

    // 需要根据套餐id和支付类型获取产品id
    getProductId (packageId, payType) {
      const source = getSource()
      const req = { data: { biztype: 1, paytype: payType, id: packageId, source } }
      return api.member.prepare(req).then(res => res?.data?.product_id)
    },

    getWxQrcode (productId) {
      return api.member.wxPay({ data: { payapp, product_id: productId } }).then(res => {
        const data = res.data
        if (!data || !data.order_id || !data.wx_resp || !data.wx_resp.code_url) {
          this.$toast({ desc: '获取支付二维码失败，请刷新重试或联系客服' })
        }
        // 执行结果轮询
        this.getPayResult(data.order_id, payTypeMap.wxPay)
        return data.wx_resp.code_url
      }).catch(() => {
        this.$toast({ desc: '获取微信二维码失败，请刷新重试或联系客服' })
      })
    },

    getAliQrcode (productId) {
      return api.member.aliPay({ data: { payapp, product_id: productId } }).then(res => {
        const data = res.data
        // 执行结果轮询
        this.getPayResult(data.order_id, payTypeMap.aliPay)
        return data.ali_resp.code_url
      }).catch(() => {
        this.$toast({ desc: '获取支付宝二维码失败，请刷新重试或联系客服' })
      })
    },

    payFail () {
      this.$toast({ desc: '支付失败，请刷新重试或联系客服' })
      this.clearTimer()
    },

    getPayResult (id, type) {
      clearInterval(this[`Timer${type}`])
      this[`Timer${type}`] = setInterval(() => {
        api.member.payQuery({ data: { payapp, order_id: id } }).then(res => {
          const data = res.data

          if (!data || !data.order || !data.order.status) {
            this.payFail()
            this.clearTimer()
            return
          }

          if (data.order.status === payStatus.fail) {
            this.payFail()
            this.clearTimer()
            return
          }

          // 其他状态暂不处理
          if (data.order.status === payStatus.success) {
            const order = data.order
            report('payresult', { order_id: id, order_time: order.pay_time, order_num: order.fee, order_package: this.packageName, order_paytype: type === 1 ? 'weixin' : 'alipay', ext: this.payResultExt })
            reportServerId()
            this.resetUserInfo()
          }
        })
      }, 3000)
    },

    resetUserInfo () {
      api.user.privliege().then(res => {
        const data = res.data
        if (!data.name || !data.end_date) {
          this.$toast({ desc: '支付已经成功，请刷新页面' })
        }
        const lastUserInfo = { ...this.userInfo, isVip: true, privliegeName: data.name, privliegeEndTime: data.end_date }

        this.$store.commit('setUserInfo', lastUserInfo)
        CacheSetUserInfo(lastUserInfo)
        ClientSetUserInfo(lastUserInfo)
        this.$store.dispatch('initBuyBtn') // 支付后终身会员隐藏成为会员按钮
        this.$emit('close')
      }).catch(() => {
        this.$toast({ desc: '支付已经成功，请刷新页面或者退出重新登录' })
      }).finally(() => {
        this.clearTimer()
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.pay_box {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 160px;
  padding: 13px 17px;
  background: #FFFFFF;
  border-radius: 8px;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 233px;
    height: 100%;
    font-size: 12px;
    font-weight: 400;
    .prices {
      position: relative;
      height: 23px;
      margin-top: 8px;
      .price {
        font-weight: bold;
        color: #FF003F;
        .font_big {
          font-size: 23px;
        }
      }
      .price_minus {
        position: absolute;
        right: 0;
        bottom: 0;
        color: #FFA201;
      }
      &.can_no_get_coupon {
        display: flex;
        flex-direction: column;
        .price_minus {
          position: static;
          margin-top: 10px;
        }
      }
    }

    .coupons {
      display: flex;
      justify-content: space-between;
      .coupon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 108px;
        height: 54px;
        font-size: 10px;
        color: #B28339;
        background-image: url("~@/assets/images/bg_coupon.png");
        .price {
          font-weight: bold;
          .font_big {
            font-size: 18px;
          }
        }
      }
    }

    .coupon_receive {
      display: block;
      width: 233px;
      height: 54px;
      cursor: pointer;
      background-image: url("~@/assets/images/bg_coupon_receive.gif");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .right {
    display: flex;
    justify-content: space-between;
    width: 234px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    .qrcode_item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .qr { box-sizing: border-box; width: 100px; height: 100px; padding: 4px; margin-bottom: 5px; background-repeat: no-repeat; background-position: center center; background-size: 88px 88px; }
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .icon {
      display: inline-block;
      width: 16px;
      height: 14px;
      margin-right: 5px;
      &.wx {
        background: url(~@/assets/images/icon_activity_wechat.png) no-repeat;
        background-size: 100%;
      }
      &.alipay {
        background: url(~@/assets/images/icon_activity_alipay.png) no-repeat;
        background-size: 100%;
      }
    }
  }
}
</style>
