<template>
  <ShareButton :baseClass="`common_btn ${ size } ${ type || '' } ${ theme || '' }`" @click.native="btnHandle">
    <slot />
  </ShareButton>
</template>

<script>
import ShareButton from '@shared/components/Button/Button.vue'

export default {
  name: 'ComButton',

  components: { ShareButton },

  props: {
    size: {
      type: String,
      default: 'middle'
    },

    type: {
      type: String,
      default: ''
    },

    theme: {
      type: String,
      default: '' // 颜色
    }
  },

  methods: {
    btnHandle () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.common_btn { font-size: 12px; font-weight: 400; color: $primary_color; cursor: pointer; user-select: none; background-color: #fff; border: 1px solid $primary_color; border-radius: 4px; outline: none;
  &:active { background-color: $primary_select !important; border-color: $primary_select !important; }
  &:hover { color: #fff; background-color: $primary_color; }
  &.mini { height: 18px; padding: 0 6px; }
  &.middle { height: 22px; padding: 0 12px; }
  &.big { height: 30px; padding: 0 16px; }
  &.largest { height: 48px; padding: 0 23px; }

  // 其他颜色
  &.yellow { color: $primary_yellow; border-color: $primary_yellow;
    &:hover { color: #fff; background-color: $primary_yellow; }
    &:active { background-color: $primary_yellow_select !important; border-color: $primary_yellow_select !important; }
  }
  &.green { color: $primary_green; border-color: $primary_green;
    &:hover { color: #fff; background-color: $primary_green; }
    &:active { background-color: $primary_green_select !important; border-color: $primary_green_select !important; }
  }

  // type === hover
  &.hover { color: #fff; background-color: $primary_color; border-color: $primary_color;
    &:hover { background-color: #73b6ff; border-color: #73b6ff; }
  }
}
</style>
