/** 权限会跳页面字段 */
export const redirectKey = 'loginfrom'

/** 登录类型 */
export const loginType = {
  wechat: 'wechat',
  qq: 'qq'
}

/** 三方登录类型判断参数的key */
export const queryLoginType = 'loginType'

/** 下载状态 */
export const modelStatus = {
  down: 'down',
  downing: 'downing',
  downed: 'downed'
}

/** 页面url携带关键词，与接口保持一致 */
export const Keyword = 'keyword'
export const Cat1 = 'cat1'
export const Cat2 = 'cat2'
export const Cat3 = 'cat3'
export const Sort = 'sort'
export const Page = 'page'
export const MatId = 'mat_id'
export const Scroll = 'scroll'

/** 自定义字段 -- 是否收藏 */
export const isCollect = 'isCollect'

/** 我的页面的下标 */
export const myIndex = 5

/** scroll组件的默认滚动速度 */
export const scrollRateFast = 100
export const scrollRateAm = 200

/** postMessage类型 */
export const postMessageType = {
  wechat: 'wechat_login_proxy',
  qq: 'qq_login_proxy',
}

/** 渠道关键字 */
export const channelType = {
  duba: 'duba',
  dg: 'dg',
  sys: 'sys',
  wall: 'wall',
  pdf: 'pdf',
  pic: 'pic'
}

/** 渠道对用的中文名称：duba:毒霸 */
export const channelMap = {
  [channelType.duba]: '毒霸',
  [channelType.dg]: '精灵',
  [channelType.sys]: '金牌电脑管家',
  [channelType.wall]: '元气壁纸',
  [channelType.pdf]: '极光PDF',
  [channelType.pic]: '金山看图'
}

/** 渠道对应的统一上报类型： duba:3 */
export const channelReportMap = {
  [channelType.duba]: 3,
  [channelType.dg]: 4,
  [channelType.sys]: 5,
  [channelType.wall]: 6,
  [channelType.pdf]: 7,
  [channelType.pic]: 8
}

/** 所有能进入详情页的列表页码，公用参数，页码不能更改，更改前需深拷贝 */
export const pageSize = 20

/** 列表item的计算高度，包含margin等 */
export const modelItemHeight = 332

/**
 * 设置接口的键值
 */
export const switchKey = {
  full: 'close_startup_full',
  sidebar: 'close_sidebar_forever',
  addin: 'close_addin_forever'
}

/**
 * 支付窗类型
 */
export const payDialogType = {
  activity: 0,
  pay: 1
}

/**
 * ab测试类型 a: 对照组，b：实验组
 */
export const abTestType = {
  a: 0,
  b: 1
}
