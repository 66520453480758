<template>
  <DialogC
    v-model="vipActivityMsgVisible" size="mini" class="activity_message">
    <div class="activity_message_dialog">
      <h5 class="activity_message_title">您已是终身会员，不可参与该活动</h5>
      <ComButton
        size="big" type="hover" class="activity_message_btn"
        @click="activityMessageClose">好的</ComButton>
    </div>
  </DialogC>
</template>

<script>
import DialogC from '@/components/DialogC/index.vue'

export default {
  name: 'AllLifeTipDialog',

  components: { DialogC },

  computed: {
    vipActivityMsgVisible: { // 终身会员活动提示框
      get: function () {
        return this.$store.state.tipsDialog.vipActivityMsgVisible
      },
      set: function () {
        this.$store.commit('tipsDialog/changeVipActivityMsgVisible', false)
      }
    }
  },

  methods: {
    activityMessageClose () { // 关闭终身活动消息窗口
      this.$store.commit('tipsDialog/changeVipActivityMsgVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.activity_message_dialog { position: absolute; top: 0; right: 0; bottom: 0; left: 0; padding-top: 35px; text-align: center;
  .activity_message_title { position: relative; top: 0px; font-size: 12px; color: $primary_color; text-align: center; }
  .activity_message_btn { position: relative; top: 30px; }
}
</style>
