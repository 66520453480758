import { postMessageType, queryLoginType } from '@/common/const.js'
import { stringifyQuery } from '@shared/utils/querystring'

/** 官网地址 */
export const officialWebsite = 'https://www.6666ppt.com'

/** 用户协议 */
export const userProtocol = 'https://www.6666ppt.com/protocol.html'

/** 客服链接 */
export const customerUrl = 'http://wpa.qq.com/msgrd?v=3&uin=1528519058&site=qq&menu=yes'

/** 阿里图片压缩规则，不要随意更改，有预热功能 */
export const smallImgRule = '?x-oss-process=image/resize,w_216,h_247,m_lfit/quality,q_80/interlace,1/format,webp'
export const detailImgRule = '?x-oss-process=image/resize,w_1920,m_lfit/quality,q_80/interlace,1/format,webp'

/** 微信登录相关appid */
export const APPID = 'wxefe7ef379ab5a288'

/** qq登录相关appid */
export const APPIDQQ = '101920689'

/** 微信登录 - 大酸菜appid */
export const APPID2 = 'wx0da1b7e25efd2c50'

/** qq登录 - 大酸菜appid */
export const APPIDQQ2 = '101895640'

/** 支付主页当前页面url --- 微信支付绑定 */
const payWindowUrl = 'https://www.6666ppt.com/vip_login_proxy.html'

/** 环境判定 */
const isDev = process.env.NODE_ENV === 'development'
const isTest = process.env.STAGE === 'test'
/** 当前中转页 */
export const proxyUrl = `${isDev || isTest ? 'http' : 'https'}://minisite.6666ppt.com/vip_login_proxy.html`
export const domainProxyUrl = isDev ? 'http://10.12.129.157:8080/' : `${isDev || isTest ? 'http' : 'https'}://minisite.6666ppt.com/` // 客户端要http://10***，浏览器要**minisite.6666ppt.com**

/** 当前页面中转页参数; 微信支付登录链接 */
const wechatCurObj = {
  postmessage_domain: domainProxyUrl,
  postmessage_type: 'parent',
  [queryLoginType]: postMessageType.wechat
}
export const wechatProxyUrl = `${payWindowUrl}?origin=${proxyUrl}&${stringifyQuery(wechatCurObj)}` // 不能拆解为对象，stringifyQuery会encode，导致双？出现，解析失败

/** QQ登录跳转中转页 */
export const qqProxyUrl = `${proxyUrl}?postmessage_domain=${domainProxyUrl}&postmessage_type=parent&${queryLoginType}=${postMessageType.qq}`
