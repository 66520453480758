<template>
  <div class="pay_stay">
    <div class="box">
      <IconClose class="icon_close" color="#fff" @click.native="clickClose" />
      <div class="button" @click="clickUse" />
    </div>
  </div>
</template>

<script>
import IconClose from '@/components/Icons/IconClose.vue'
import { setNewCoupon } from '@/common/business.js'

export default {
  name: 'PayStay',

  components: { IconClose },

  methods: {
    clickClose () {
      this.$emit('click-close')
    },

    clickUse () {
      this.$emit('click-use')
      setNewCoupon(this.$store)
    }
  },
}
</script>

<style lang="scss" scoped>
.pay_stay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 443px;
    height: 320px;
    margin: auto;
    background-image: url("~@/assets/images/bg_pay_stay.png");
    .button {
      position: absolute;
      top: 131px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 138px;
      height: 37px;
      margin: auto;
      cursor: pointer;
      background-image: url("~@/assets/images/icon_use.png");
      border-radius: 25px;
      box-shadow: 0px 3px 10px #c3c3c3;
    }
    .icon_close {
      position: absolute;
      top: 45px;
      right: 55px;
    }
  }

}
</style>
