// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icon_corner_right.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/icon_hook.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".price_box[data-v-4be80fa1]{position:relative;box-sizing:border-box;height:143px;font-family:Microsoft YaHei;background:#fffbe9;border:1px solid #cb8000;border-radius:8px}.price_box.size_big[data-v-4be80fa1]{width:338px}.price_box.size_small[data-v-4be80fa1]{width:168px}.price_box.selected[data-v-4be80fa1]{background:linear-gradient(90deg,#fff5e8,#ffcc81)}.price_box.selected .name[data-v-4be80fa1]{font-weight:600}.price_box.selected .corner_right[data-v-4be80fa1]{position:absolute;top:0;right:0;width:40px;height:40px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.price_box.selected .hook[data-v-4be80fa1]{position:absolute;top:5px;right:5px;width:15px;height:13px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.name[data-v-4be80fa1]{margin:14px auto 0 auto;font-size:18px;font-weight:400;color:#553e1c}.name[data-v-4be80fa1],.prices[data-v-4be80fa1]{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.prices[data-v-4be80fa1]{height:31px;margin:5px auto 0 auto}.prices .discount_price[data-v-4be80fa1]{font-size:30px;font-weight:700;color:#553e1c}.prices .discount_price .sign[data-v-4be80fa1]{font-size:14px}.prices .price[data-v-4be80fa1]{font-size:14px;font-weight:400;color:#553e1c;text-decoration:line-through}.tip_middle[data-v-4be80fa1]{display:flex;align-items:center;justify-content:center;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:19px;margin:12px auto 0 auto;border:1px solid #553e1c;border-radius:10px}.tip_middle .text[data-v-4be80fa1]{margin:0 17px}.tip_middle .text[data-v-4be80fa1],.tips_footer[data-v-4be80fa1]{font-size:12px;font-weight:400;color:#553e1c}.tips_footer[data-v-4be80fa1]{position:absolute;bottom:0;display:flex;align-items:center;justify-content:space-around;width:100%;height:24px;background:#ffe8c0;border-top:1px solid #cb8000;border-radius:0 0 8px 8px}.tips_footer.mg[data-v-4be80fa1]{box-sizing:border-box;padding:0 10px}", ""]);
// Exports
module.exports = exports;
