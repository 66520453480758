import { callRequest } from '@/api/clientApi.js'

/** 隐藏自带loading */
export const ClientHideCover = () => {
  callRequest({ module: 'base', fnName: 'hideCover', params: '' })
}

/**
 * 获取svrid
 * @param 回调函数fn(res)
 */
export const ClientGetSvrId = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'getUserInfo', params: '' }).then(res => {
    fn(res)
  })
}

/**
 * 判断快捷方式是否存在
 * @param 回调函数fn(res)
 */
export const ClientIsLnkExists = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'isLnkExists', params: '' }).then(ret => {
    fn(ret)
  })
}

/**
 * 创建桌面链接
 * 1、判断是否存在
 * 2、否，则创建
 * @param 回调函数fn()
 */
export const ClientCreateLnk = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'isLnkExists', params: '' }).then(ret => {
    if (!ret.result) {
      callRequest({ module: 'template', fnName: 'createLnk', params: '' }).then(() => {
        fn()
      })
    }
  })
}

/** 最小化窗口 */
export const ClientMinimizedWindow = () => {
  callRequest({ module: 'base', fnName: 'minimizedWindow', params: '' })
}

/** 关闭窗口 */
export const ClientCloseWindow = () => {
  callRequest({ module: 'base', fnName: 'closeWindow', params: '' })
}

/**
 * 获取下载链接
 * @param 回调函数fn(res)
 */
export const ClientGetDownloadDir = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'getDownloadDir', params: '' }).then(res => {
    fn(res)
  })
}

/** 打开下载文件 */
export const ClientOpenDownloadDir = () => {
  callRequest({ module: 'template', fnName: 'openDownloadDir', params: '' })
}

/**
 * 更改下载文件地址
 * @param 回调函数fn()
 */
export const ClientChangeDownloadDir = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'changeDownloadDir', params: '' }).then(() => {
    fn()
  })
}

/**
 * 获取右键新建菜单开关状态
 * @param 回调函数fn(res)
 */
export const ClientGetNewMenuState = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'getNewMenuState', params: '' }).then(res => {
    fn(res)
  })
}

/**
 * 设置右键新建菜单开关状态, 无返回值
 * @param 开关值，true：1，false：0
 */
export const ClientSetNewMenuState = (value) => {
  callRequest({ module: 'template', fnName: 'setNewMenuState', params: { switch: value } })
}

/**
 * 获取插件配置
 * @param 回调函数fn(res)
 */
export const ClientGetOfficeAddIn = (fn = () => {}, fn1 = () => {}) => {
  callRequest({ module: 'template', fnName: 'getStatus', params: '' })
    .then(res => {
      fn(res)
    })
    .catch(res => {
      fn1(res)
    })
}

/**
 * 设置插件配置
 * @param {String} type 类型
 * @param {Boolean} status 状态
 *
 * 1、close_startup_full： 设置是否自动展开侧边栏（前端处理，这里做个记录，防止缓存丢失问题）
 * 2、close_sidebar_forever： 永久隐藏插件模板入口（通过注册表打开 计算机\HKEY_CURRENT_USER\CLSID\{B75A7A80-FFFD-4b89-ABCD-B7F249312F3C}\NoSidebar_P 改为0会显示）
 * 3、close_addin_forever： 永久关闭办公office插件（包括顶部的推广, 注册表的：HideOfficeAddIn）
 */
export const ClientSetOfficeAddIn = (type, status) => {
  callRequest({ module: 'template', fnName: 'setOfficeAddIn', params: { type, status } })
}

/**
 * 获取缓存大小, 单位：kb
 * @param 回调函数fn(res)
 */
export const ClientGetCacheSize = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'getCacheSize', params: '' }).then(res => {
    fn(res)
  })
}

/**
 * 清除缓存大小
 * @param 回调函数fn()
 */
export const ClientCleanCache = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'cleanCache', params: '' }).then(() => {
    fn()
  })
}

/**
 * 打开网页
 * @param url
 */
export const ClientOpenWebsiteUrl = (url) => {
  callRequest({ module: 'template', fnName: 'openUrl', params: { url: url } })
}

/** 毒霸反馈 */
export const ClientFeedback = () => {
  callRequest({ module: 'template', fnName: 'feedback', params: '' })
}

/**
 * 下载文件
 * @param { id, filename: 1.excel, url: '' }
 */
export const ClientDownFile = (params)　=> {
  callRequest({ module: 'template', fnName: 'downFile', params })
}

/**
 * 获取下载文件
 * @param 回调函数fn(res)
 */
export const ClientGetDownloadList = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'getDownloadList', params: '' }).then(res => {
    fn(res)
  })
}

/**
 * 打开文件
 * @param 回调函数fn(res)
 */
export const ClientOpenFile = (params, fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'openFile', params }).then(res => {
    fn(res)
  })
}

/** 上报 */
export const ClientReport = (params) => {
  callRequest({ module: 'template', fnName: 'reportNoFormat', params })
}

export const ClientGetUserInfoBase = () => {
  return callRequest({ module: 'template', fnName: 'getLoginMsg', params: '' })
}

/** 获取客户端用户信息 */
export const ClientGetUserInfo = () => {
  return ClientGetUserInfoBase().then(res => {
    return res?.userInfo?.token ? res.userInfo : undefined
  })
}

/** 设置客户端用户信息 */
export const ClientSetUserInfo = (userInfo) => {
  ClientGetUserInfoBase().then(res => {
    const params = Object.assign({}, res, { userInfo })
    callRequest({ module: 'template', fnName: 'setLoginMsg', params }).catch(() => {
      window.app.$toast({ desc: '设置用户信息失败，请联系管理员' })
    })
  })
}

/** 移除客户端用户信息 */
export const ClientRemoveUserInfo = () => {
  ClientGetUserInfoBase().then(res => {
    const params = Object.assign({}, res, { userInfo: {} })
    callRequest({ module: 'template', fnName: 'setLoginMsg', params })
  })
}

// 是否是毒霸VIP
export const ClientIsVip = (fn = () => {}) => {
  callRequest({ module: 'template', fnName: 'isVip', params: '' }).then(ret => {
    fn(ret)
  })
}

// 毒霸用户信息
export const ClientUserInfo = (fn = () => {}) => {
  callRequest({ module: 'kvip', fnName: 'getUserInfo', params: '' }).then(ret => {
    fn(ret)
  })
}
