
import { stringifyQuery } from '@shared/utils/querystring'

import { Dat } from '@/report/const.js'
import { ClientReport } from '@/common/clientFunc.js'
import { getSource, getChannel } from '@/common/cache.js'
import { channelReportMap } from '@/common/const.js'

// 公共数据拼接
const concatQuery = (data) => {
  const userInfo = window.app.$store.state.userInfo

  // eslint-disable-next-line no-nested-ternary
  const uidtype = userInfo?.token ? (userInfo?.isVip ? 2 : 1) : 0

  const channel = getChannel()
  const source = getSource()

  return {
    ...data,
    uid: userInfo?.uid || 0,
    uidtype,
    productid: channel ? channelReportMap[channel] : 0,
    source
  }
}

export function report (name, data) {
  ClientReport({ name: Dat[name], data: stringifyQuery(concatQuery(data)) })
}
